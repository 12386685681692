<script setup>
import {reactive, ref} from 'vue'
import {Auth, Feedback, Log, Progress, State, can, clone, navigate} from '@/paks/vu-app'
import {Account} from '@/models'

const page = reactive({
    account: clone(State.auth.account),
    closing: false,
    rules: [],
    saving: false,
})

//  Component refs
const confirm = ref(null)

async function save() {
    let account = await Account.updateIdentity({
        id: page.account.id,
        email: page.account.email.trim(),
        name: page.account.name.trim(),
    })
    if (account) {
        State.auth.setAccount(account)
    }
    State.ref.chat.identify()
}

async function closeAccount() {
    let confirmPrompt = `Delete ${page.account.name}`
    let approved = await confirm.value.ask(
        `<h2 class="closing">WARNING: Closing your account is permanent and cannot be reversed.</h2>
            <p>Closing your account will remove all products, software, users and device clouds.</p>
            <p>Your license to embed device agents in your devices will be terminated.</p>
            <label>To confirm the deletion of your account, type <i>${confirmPrompt}</i> in the text box.</label>`,
        {color: 'error', confirmPrompt, width: 780}
    )
    if (!approved) {
        return
    }
    try {
        Progress.start('long')
        await State.app.closeAccount()
        State.ref.chat.cancelAccount()
        Feedback.info('Account Closed')
        await Auth.logout({redirect: true})
        page.closing = true
        navigate('/login')
    } catch (err) {
        Log.error('Cannot close account', {err})
    } finally {
        Progress.stop()
        page.closing = false
    }
}
</script>

<template>
    <vu-form :data="page" :save="save" help="/doc/ui/account/settings.html" title="Account Settings" class="page-form">
        <vu-input v-model="page.account.name" label="Account Name" type="text" cols="12" />
        <vu-input
            v-model="page.account.email"
            cols="12"
            label="Account Owner Email"
            type="text"
            :rules="page.rules.email" />
        <vu-input v-model="page.account.id" label="Account ID" :disabled="true" type="text" cols="12" />

        <div class="actions">
            <v-btn size="small" color="accent" class="mr-2" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn size="small" color="error" @click="closeAccount" :loading="page.closing" v-if="can('owner')">
                Close Account
            </v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
h2.closing {
    margin-bottom: 20px;
    color: #484848;
    font-weight: normal;
    font-size: 1.25rem;
}
</style>
