<script setup>
import {onBeforeMount, reactive, ref, watch} from 'vue'
import {Feedback, Progress, State, can, clone, money, titlecase} from '@/paks/vu-app'
import {Plan, Product, Token} from '@/models'
import Planner from '@/common/planner'
import Dates from '@/paks/js-dates'

/*
    Licensed software
*/
const Agents = [
    {title: 'Ioto Device Agent', value: 'Ioto'},
    {title: 'Appweb Web Server', value: 'Appweb'},
    {title: 'GoAhead Web Server', value: 'GoAhead'},
    {title: 'Other Device Agent', value: 'Other'},
]

const Schemes = [
    {title: 'Evaluation', value: 'eval'},
    {title: 'Project Maker', value: 'project'},
    {title: 'Commercial Device Builder', value: 'commercial'},
]

const Licenses = [
    {title: 'OpenSource GPLv2', value: 'opensource'},
    {title: 'Commercial License', value: 'commercial'},
]

const Scopes = [
    {title: 'By Device Volume', value: 'volume'},
    {title: 'Single Product', value: 'product'},
    {title: 'Family of Products', value: 'family'},
    {title: 'Business Line -- Multiple Product Families', value: 'business'},
]
const IotoInterest = ['Cloud-Based Management', 'Local Management']
const LegacyInterest = ['Existing Device', 'New Device', 'Research']

const props = defineProps({id: String})
const emit = defineEmits(['input'])

const page = reactive({
    apply: 'next',
    askApply: null,
    billing: {
        agreement: false,
    },
    canDelete: false,
    cc: null,
    currentRange: null,
    deleting: false,
    enable: false,
    highVolume: false,
    iotoInterest: IotoInterest,
    legacyInterest: LegacyInterest,
    mock: false,
    monthlyPrice: 0,
    nextPrice: 0,
    notification: {},
    notifications: State.cache.notifications || [],
    partial: null,
    plan: {
        agent: null,
        count: 0,
        current: {count: 0, units: 0},
        period: null,
        scope: null,
        type: null,
        units: 0,
    },
    plans: [],
    product: {},
    price: 0,
    prior: {},
    range: '',
    rules: [],
    saving: false,
    showEditNotification: false,
    start: null,
    tooMany: null,
    token: {},
    total: 0,
    upfrontPrice: 0,
    yearlyPrice: 0,
})

//  Component refs
const form = ref(null)

let planner = null

defineExpose({page})

onBeforeMount(async () => {
    if (!can('admin')) {
        return
    }
    planner = new Planner(State.config.billing)
    Object.assign(page.billing, State.auth.account.billing)
    page.mock = State.app.mock

    let plan

    if (props.id) {
        page.product = clone(State.get('Product', props.id))
        plan = Object.assign(page.plan, clone(State.get('Plan', page.product.planId)))
        page.cc = plan.notify && plan.notify.length ? plan.notify.join(', ') : null
        if (plan.units == 0 && plan.type != 'Volume') {
            plan.units = 1
        }
    } else {
        plan = page.plan = makeNewPlan('Ioto')
        if (plan.type == 'Volume') {
            plan.units = Math.max(plan.count, plan.units)
            plan.advance = true
            plan.period = 'year'
        } else if (plan.type == 'Subscription') {
            plan.align = true
        } else if (plan.type == 'Buyout') {
            plan.align = false
        }
    }
    if (plan.period == 'year' && plan.end - plan.start < 3 * 28 * 86400 * 1000) {
        planner.setPlanRange(plan, {reset: true})
    }
    page.enable = plan.units ? true : false
    page.prior = clone(plan)
    page.canDelete = can('admin') && (plan.type != 'Buyout' || can('support'))
    setScheme()

    //  waiting section
    page.plans = await Plan.find()
    if (props.id) {
        page.token = await Token.get({
            type: 'ProductID',
            owner: `product:${page.product.id}`,
            enable: true,
        })
    }

    watch(() => page.apply, changeApply)
    watch(() => page.billing.agreement, changeAgreement)
    watch(() => page.plan.align, changeAlign)
    watch(() => page.plan.agent, changeAgent)
    watch(() => page.enable, changeEnable)
    watch(() => page.plan.period, changePeriod)
    watch(() => page.scheme, changeScheme)
    watch(() => page.plan.scope, changeScope)
    watch(() => page.plan.units, changeUnits)

    updatePrice()
    warnTooMany()
})

function setScheme() {
    let {plan} = page
    let type = plan.type
    if (type == 'Free') {
        page.scheme = 'eval'
    } else {
        if (plan.scope == 'eval') {
            page.scheme = 'eval'
            page.type = 'Free'
        } else {
            page.scheme = 'commercial'
        }
    }
    if (plan.type == 'Free') {
        plan.scope = 'none'
    }
}

function warnTooMany() {
    let {plan, product} = page
    if (!product.id) return
    let clouds = State.cache.clouds.filter((c) => c.type == 'hosted' || c.type == 'dedicated')
    page.tooMany = null
    if (page.token.id == State.config.evalProductToken) {
        if (can('support')) {
            page.tooMany = `This is the Master Eval Product with ${plan.count} devices registered.`
        }
    } else if (plan.type == 'Free' && plan.units > 10) {
        page.tooMany = `The Eval plan is restricted to only 10 devices. You have ${plan.count} devices registered.`
    } else if (plan.units < plan.count) {
        page.tooMany = `You have ${plan.count} devices registered but only ${plan.units} licensed.`
    } else if (clouds.length == 1) {
        let cloud = clouds[0]
        let cloudPlan = State.cache.plans.find((p) => p.id == cloud.id)
        if (plan.units < cloudPlan.count) {
            page.tooMany = `You have ${cloudPlan.count} connecting devices but only ${plan.units} licensed.`
        }
    }
}

function makeNewPlan(agent) {
    let plan
    if (agent == 'Ioto') {
        plan = {
            agent,
            count: 0,
            license: 'eval',
            period: 'year',
            type: 'Free',
            scope: 'eval',
            units: 0,
            current: {count: 0, units: 0},
        }
    } else if (agent == 'Other') {
        plan = {
            agent,
            count: 0,
            license: 'none',
            period: 'year',
            type: 'Free',
            scope: 'none',
            units: 0,
            current: {count: 0, units: 0},
        }
    } else if (page.billing.agreement) {
        plan = {
            agent,
            count: 0,
            license: 'contract',
            period: 'year',
            type: 'Buyout',
            scope: 'product',
            current: {count: 0, units: 1},
            units: 1,
        }
    } else {
        plan = {
            agent,
            count: 0,
            license: 'commercial',
            period: 'year',
            type: 'Subscription',
            scope: 'eval',
            current: {count: 0, units: 1},
            units: 1,
        }
    }
    page.cc = State.auth.account.email
    page.enable = true
    return planner.initPlan(plan)
}

function changeScheme(scheme) {
    let {plan} = page
    if (scheme == 'eval') {
        plan.type = 'Free'
        plan.scope = 'eval'
    } else if (scheme == 'project') {
        plan.type = 'Volume'
        plan.scope = 'volume'
        plan.license = 'commercial'
    } else if (scheme == 'commercial' || scheme == 'contract') {
        plan.license = 'commercial'
        if (plan.scope == 'eval' || plan.scope == 'none') {
            plan.scope = 'volume'
        }
    }
}

function changeScope(scope) {
    let {plan} = page
    if (scope == 'volume') {
        plan.type = 'Volume'
        plan.align = true
        plan.advance = true
        plan.period = 'year'
        plan.units = Math.max(plan.units, plan.count)
    } else {
        if (page.billing.agreement) {
            plan.type = 'Buyout'
            plan.period = 'year'
            plan.advance = true
            plan.align = false
        } else {
            plan.type = 'Subscription'
        }
        plan.units = 1
    }
    page.enable = plan.units ? true : false
    plan.pending = true
    setApply()
    updatePrice()
    warnTooMany()
}

function changeAgreement() {
    changeScope(page.plan.scope)
}

function changeAlign() {
    let plan = page.plan
    plan.end = planner.getEnd(plan)
    updatePrice()
}

function changeEnable() {
    let plan = page.plan
    if (plan.type == 'Buyout' || plan.type == 'Subscription') {
        plan.units = page.enable ? 1 : 0
    }
    updatePrice()
}

/*
    Ask to apply to the current or next period iff: Scope is changing or Volume and increasing units
 */
function setApply() {
    let {plan, prior} = page
    let current = plan.current
    if (!page.plan.id) return
    page.askApply =
        (plan.scope != prior.scope && plan.scope != 'eval' && plan.license != 'opensource') ||
        (plan.type == 'Volume' &&
            current.start &&
            current.end >= Date.now() &&
            plan.units > current.units)
            ? true
            : false
}

function changePeriod() {
    let {plan, prior} = page
    plan.pending = true
    if (plan.period != prior.period) {
        if (plan.period == 'year') {
            plan.units = plan.units * 12
        } else if ((plan.period = 'month')) {
            plan.units = Math.ceil(plan.units / 12) || 0
            if (plan.agent != 'Ioto' && plan.units == 0) {
                plan.units = 1
            }
        }
    }
    plan.start = plan.end = null
    changeApply()
    setApply()
}

function changeAgent() {
    let plan = page.plan
    if (!page.product.id) {
        plan = makeNewPlan(plan.agent)
    }
    page.product.plan = page.plan = planner.initPlan(plan)
    plan.pending = true
    updatePrice()
}

function changeUnits() {
    let plan = page.plan
    page.highVolume = plan.units >= 50000 ? true : false
    plan.pending = true
    updatePrice()
    setApply()
    warnTooMany()
}

function changeApply() {
    let plan = page.plan
    if (page.apply == 'current') {
        plan.start = new Date()
    } else {
        plan.start = plan.current.end || new Date()
    }
    plan.end = planner.getEnd(plan)
    updatePrice()
}

function updatePrice() {
    let account = State.auth.account
    let plan = page.plan
    getPlanRange()
    if (plan.units != '') {
        plan.units = parseInt(plan.units) || 0
    }
    let upfront = true
    if (page.billing.agreement) {
        let existing = page.plans.find((p) => p.agent == plan.agent)
        if (existing && existing.current.price) {
            //  Already have a license for this product
        }
        if (State.auth.account.id == '01GKFJKQNHTHHE4T7PTBB7WGXG' && plan.agent == 'GoAhead') {
            upfront = false
        }
    }
    page.total = planner.getPrice(account, plan, {period: true, upfront})
    if (upfront && (plan.type != 'Volume' || plan.units > 1)) {
        page.upfrontPrice = planner.getUpfront(plan)
    } else {
        page.upfrontPrice = 0
    }
    page.price = page.total - page.upfrontPrice
    page.yearlyPrice = planner.getPrice(account, plan, {stock: true})
    page.monthlyPrice = page.yearlyPrice / 12
    page.nextPrice = page.yearlyPrice / (plan.period == 'month' ? 12 : 1)
}

function getPlanRange() {
    let plan = page.plan
    let partial = ''

    if (plan.align) {
        let aligned = planner.alignDate(plan.start)
        let span = plan.period == 'year' ? 365 : 28
        let days = (plan.end - plan.start) / (86400 * 1000)
        if (
            Math.abs(plan.start.getTime() - aligned.getTime()) > 86400 * 1000 ||
            Math.abs(days - span) > 3
        ) {
            partial = ` (partial ${plan.period})`
        }
    }
    page.range = formatRange(plan.start, plan.end, partial)
    page.partial = partial

    let current = plan.current
    if (current.start && current.units) {
        page.currentRange = formatRange(current.start, current.end)
    }
}

function formatRange(start, end, partial = '') {
    start = Dates.format(start, 'mediumDate')
    let displayEnd = planner.getDisplayEnd(end)
    end = Dates.format(displayEnd, 'mediumDate')
    return `${start} to ${end}` + partial
}

async function save() {
    let {plan, product} = page
    if (plan.type == 'Volume') {
        plan.units = +plan.units
        if (plan.units < 0 || parseInt(plan.units) != plan.units) {
            throw new Error(`Bad units`)
        }
    } else if (plan.type == 'Subscription') {
        plan.units = page.enable ? 1 : 0
    }
    if (plan.start != plan.current.start) {
        plan.pending = true
    }
    let params = {
        advance: plan.advance,
        align: plan.align,
        agent: plan.agent,
        description: product.description?.trim(),
        end: plan.end,
        id: product.id,
        interest: plan.interest,
        name: product.name?.trim(),
        notify: page.cc ? page.cc.split(',').map((e) => e.trim()) : null,
        period: plan.period,
        pending: plan.pending,
        planId: product.planId,
        scope: plan.scope,
        start: plan.start,
        type: plan.type,
        units: plan.units,
        url: product.url?.trim(),
    }
    let end = planner.getEnd(plan)
    let result
    if (product.id) {
        result = await Product.update(params)
    } else {
        result = await Product.create(params)
    }
    //  Cache the plan via get
    page.plan = await Plan.get({id: result.planId}, {refresh: true})
    emit('input')
}

async function confirmSave(confirm) {
    let {plan, product} = page
    if (plan.type == 'Free') {
        return true
    }
    let billing = State.auth.account.billing
    let cardMsg = ''
    let msg
    if (plan.scope != 'eval') {
        if (billing.error) {
            cardMsg = `<p>Your billing currently has an error. Please correct and then your software download will be enabled.</p>`
        }
    }
    if (product.id) {
        msg = `Do you want to modify your "${plan.agent}" plan? `
    } else {
        if (plan.scope == 'eval') {
            msg = `Do you want to evaluate "${plan.agent}" and accept the
                <a href="https://www.embedthis.com/licensing/eval.html" target="_blank">${plan.agent}</a> evaluation license agreement? `
        } else {
            let renew =
                plan.type == 'Volume'
                    ? `<p>The subscription will automatically renew each ${plan.period}.</p>`
                    : ''
            msg =
                `<p>Do you want to purchase "${plan.agent}" and accept the
                    <a href="https://www.embedthis.com/about/terms.html" target="_blank">${plan.agent}</a> license agreement?</p>` +
                renew
        }
    }
    msg += cardMsg
    return await confirm.value.ask(msg, {width: 600})
}

async function deleteProduct() {
    if (!page.product.name) {
        return
    }
    if (page.product.name == 'Eval') {
        Feedback.error('Must NEVER delete the eval product')
        return
    }
    let confirmPrompt = `Delete ${page.product.name}`
    let iotoWarn =
        page.plan.type == 'Volume'
            ? 'Deleting a product will disconnect devices from your cloud and stop management by the Ioto cloud service.'
            : ''
    if (
        !(await form.value.confirm.ask(
            `
            <h2 class="mb-3">WARNING: Deleting a product is permanent and cannot be reversed.</h2>
            <p>Your license to the product will be terminated for new products and your ability to apply updates and upgrades for all devices will cease.</p>
            <p>${iotoWarn}</p>
            <label>To confirm the deletion of this product, type <i>${confirmPrompt}</i> in the text box.</label>
            `,
            {
                confirmPrompt,
                width: 840,
            }
        ))
    ) {
        return
    }
    page.deleting = true
    Progress.start()
    await Product.remove({id: page.product.id}, {refresh: true})

    //  Workaround
    await State.cache.update()

    Feedback.info('Product Deleted')
    emit('input')
    Progress.stop()
    page.deleting = true
}

function adjYear(d, adj) {
    d = new Date(d)
    return new Date(d.setFullYear(d.getFullYear() + adj))
}

function editNotification(notification) {
    page.showEditNotification = notification ? true : false
}
</script>

<template>
    <vu-form
        class="product-edit"
        help="/doc/ui/products/edit.html"
        ref="form"
        title="Product"
        :data="page"
        :save="save"
        :confirm="confirmSave"
        :title="`${id ? 'Modify' : 'Add'} Product`">
        <v-alert v-if="page.tooMany" class="mb-5" type="error">
            <p>{{ page.tooMany }}</p>
            <p>Change your plan or contact support for assistance.</p>
        </v-alert>
        <vu-sign
            v-if="page.plan.type != 'Free'"
            name="product-edit-sign-1"
            class="v-col12"
            :title="`${titlecase(page.plan.agent || 'Embedthis')} Licensing`"
            color="accent">
            <div v-if="page.plan.type == 'Volume' && page.plan.agent != 'other'">
                <p>
                    A device volume subscription is based on the number of devices you make per year
                    that embed the Agent.
                </p>
            </div>
            <div v-else-if="page.plan.type == 'Subscription'">
                <p>
                    {{ page.plan.agent }} subscriptions are based on a license scope. There are
                    three unit volume limited and three unlimited volume scopes. The unlimited
                    offerings are scoped to the number of products that include the device agent
                    software.
                </p>
                <p>
                    While your subscription is active, you can include the agent software in your
                    products and access and apply security updates and upgrades.
                </p>
                <p>You are billed either monthly or yearly in advance.</p>
            </div>
            <div v-else-if="page.plan.type == 'Buyout'">
                <p>
                    {{ page.plan.agent }} licenses are based on a license scope. There are three
                    volume limited scopes and three unlimited volume scopes. The unlimited volume
                    offerings are scoped to the number of products that include the licensed
                    software. While you have an active maintenance subscription, you can access and
                    apply security updates and upgrades.
                </p>
                <p>Maintenance has a one year commmitment and is paid yearly in advance.</p>
            </div>
            <div v-else>
                <p>
                    Product definitions specify a product name, description and select the device
                    agent software to embed.
                </p>
                <p>
                    For all new devices, we recommend the Ioto Agent as the fastest and smallest
                    device agent available.
                </p>
                <p>
                    If you are an existing GoAhead or Appweb customer, select that device agent from
                    the list.
                </p>
            </div>
            <p>
                See
                <span v-if="page.plan.agent">
                    <a
                        :href="`https://www.embedthis.com/builder/doc/plans/overview/`"
                        target="_blank">
                        {{ page.plan.agent }} Pricing
                    </a>
                    for full pricing details and
                </span>
                <span>
                    the
                    <a href="https://www.embedthis.com/about/terms.html" target="_blank">
                        Licensing Terms
                    </a>
                    .
                </span>
            </p>
        </vu-sign>

        <div class="vrow">
            <vu-input
                v-model="page.product.name"
                label="Product Name"
                placeholder="Full product and model name"
                :rules="page.rules.simpleName"
                cols="6"
                type="text" />

            <vu-input
                v-model="page.product.url"
                cols="6"
                label="Product Web Site"
                :rules="page.rules.url"
                placeholder="https://www.example.com/product/" />
        </div>

        <vu-input
            v-model="page.product.description"
            cols="12"
            label="Product Description"
            type="textarea"
            rows="2" />

        <div class="vrow">
            <vu-input
                v-model="page.plan.agent"
                label="Device Agent"
                :disabled="page.product.id ? true : false"
                :items="Agents"
                type="select"
                cols="6" />

            <vu-input
                v-if="page.product.id && page.token && page.plan.agent == 'Ioto'"
                v-model="page.token.id"
                :disabled="true"
                label="Product ID for device.json5"
                type="text"
                cols="6" />
        </div>

        <div v-if="page.plan.agent != 'Other'" class="vrow">
            <vu-input
                v-model="page.scheme"
                cols="12"
                label="Plan"
                type="select"
                :items="Schemes"
                @input="changeScheme" />
            <vu-input
                v-if="page.scheme == 'commercial' || page.scheme == 'contract'"
                v-model="page.plan.scope"
                cols="12"
                label="License Scope"
                type="select"
                :items="Scopes"
                @input="changeScope" />
            <vu-input
                v-if="page.scheme == 'project'"
                v-model="page.plan.license"
                cols="12"
                label="License"
                type="select"
                :items="Licenses"
            />
            <div v-if="page.plan.license == 'opensource'">
                <v-alert class="vrow mb-5" :icon="false" type="info"
                    text="The GPL License does not generally permit incorporating this software into non-open source programs. Use this license only for non-commercial, private or full open source firmware projects.">
                </v-alert>
            </div>
            <div
                v-if="
                    (page.plan.type == 'Subscription' || page.plan.type == 'Buyout') &&
                    page.plan.id &&
                    page.plan.current.units &&
                    page.plan.scope != 'eval'
                "
                class="vrow">
                <label class="enable-subscription">
                    An active subscription grants you the license to embed the device agent in new
                    devices and apply software and security updates to your installed base. Without
                    an active subscription, you cannot download release software, ship new devices,
                    receive security notifications, or apply software security fixes or updates to
                    new or existing devices.
                </label>
                <vu-input
                    v-model="page.enable"
                    label="Enable Subscription"
                    type="checkbox"
                    cols="6"
                    class="mt-2"
                    :disabled="!page.product.id || !page.plan.current.units" />
                <vu-input
                    v-if="page.askApply"
                    label="Apply Change"
                    v-model="page.apply"
                    class="mb-3"
                    cols="6"
                    type="radio"
                    :items="{
                        Today: 'current',
                        [Dates.format(page.plan.current.end, 'mediumDate')]: 'next',
                    }" />
            </div>
        </div>

        <div v-if="page.plan.scope == 'volume' && page.plan.license != 'opensource'" class="vrow">
            <vu-input
                v-model="page.plan.units"
                cols="6"
                :label="`Total Devices (current ${page.plan.count || 0})`"
                type="text"
                class="mt-2 mb-0"
                :rules="page.rules.positive" />
            <vu-input
                v-if="page.askApply"
                label="Apply Change"
                v-model="page.apply"
                class="ml-3 mb-0"
                cols="6"
                type="radio"
                :items="{
                    Today: 'current',
                    [Dates.format(page.plan.current.end, 'mediumDate')]: 'next',
                }" />
        </div>

        <div class="vrow">
            <vu-input
                v-if="page.plan.license == 'commercial' && page.total &&
                    (page.plan.type == 'Volume' || page.plan.type == 'Subscription') &&
                    page.plan.scope != 'eval'
                "
                type="switch"
                density="compact"
                name="Align Billing"
                v-model="page.plan.align"
                color="primary"
                :suffix="page.plan.align ? 'Aligned to end of year' : 'Not aligned'"
                cols="6" />

            <vu-input
                v-if="can('support') && !page.mock"
                type="switch"
                density="compact"
                v-model="page.billing.agreement"
                color="primary"
                name="Agreement"
                :suffix="page.billing.agreement ? 'Buyout' : 'Subscription'"
                cols="6" />
        </div>
        <vu-input
            v-if="page.plan.agent != 'Other'"
            cols="12"
            type="text"
            v-model="page.cc"
            label="Product Alert Recipients"
            placeholder="Emails separated by commas" />

        <vu-input
            v-if="page.plan.agent == 'Ioto'"
            type="select"
            v-model="page.plan.interest"
            label="Select Interest"
            :items="page.iotoInterest"
            :multiple="true"
            :rules="page.rules.checked"
            cols="6" />

        <vu-input
            v-if="page.plan.agent == 'Appweb' || page.plan.agent == 'GoAhead'"
            type="select"
            v-model="page.plan.interest"
            label="Select Intended Purposes"
            :items="page.legacyInterest"
            :multiple="true"
            :rules="page.rules.checked"
            cols="12" />

        <div class="actions mt-0">
            <v-btn color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn color="none" @click="emit('input')">Cancel</v-btn>
            <v-btn
                color="error"
                v-if="page.product.id && page.canDelete"
                @click="deleteProduct"
                :loading="page.deleting">
                Delete
            </v-btn>
        </div>

        <vu-sign
            name="product-summary"
            v-if="page.plan.agent != 'Other'"
            :required="true"
            title="Subscription Estimate"
            color="accent"
            class="summary vcol-12">
            <v-row>
                <v-col cols="12">
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Period</th>
                                <th>Scope</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="page.plan.current.units && page.plan.current.price">
                                <td>Current</td>
                                <td>{{ page.currentRange }}</td>
                                <td v-if="page.plan.type == 'Volume'">
                                    {{ titlecase(page.plan.current.units) }} devices
                                </td>
                                <td v-else>{{ titlecase(page.plan.current.scope) }} license</td>
                                <td>
                                    ${{
                                        money(
                                            page.plan.current.price - page.plan.current.upfront,
                                            0
                                        )
                                    }}
                                    paid
                                </td>
                            </tr>
                            <tr v-if="page.upfrontPrice">
                                <td>Initial license fee</td>
                                <td>One time</td>
                                <td>-</td>
                                <td>${{ money(page.upfrontPrice, 0) }}</td>
                            </tr>
                            <tr v-if="page.plan.type == 'Volume'">
                                <td>Pending Subscription</td>
                                <td>{{ page.range }}</td>
                                <td>
                                    {{ page.plan.units }} device{{ page.plan.units > 1 ? 's' : '' }}
                                </td>
                                <td>${{ money(page.price, 0) }}</td>
                            </tr>
                            <tr v-else-if="page.plan.type == 'Subscription' && page.enable">
                                <td>Pending Subscription</td>
                                <td>{{ page.range }}</td>
                                <td>{{ titlecase(page.plan.scope) }} license</td>
                                <td>${{ money(page.price, 0) }}</td>
                            </tr>
                            <tr v-else-if="page.plan.type == 'Buyout' && page.enable">
                                <td>Maintenance for updates and upgrades</td>
                                <td>{{ page.range }}</td>
                                <td>{{ titlecase(page.plan.scope) }} license</td>
                                <td>${{ money(page.price, 0) }}</td>
                            </tr>

                            <tr>
                                <td><b>Total</b></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <b>${{ money(page.total, 0) }}</b>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-col>
            </v-row>

            <v-row
                class="mt-0"
                v-if="
                    page.monthlyPrice && (page.plan.period == 'month' || page.plan.period == 'year')
                ">
                <v-col>
                    <p>
                        <span>
                            {{
                                page.plan.advance
                                    ? `Billed in advance for the ${page.plan.period}`
                                    : `Billed at the end of the ${page.plan.period}`
                            }}.
                        </span>
                        <span
                            v-if="page.plan.advance && page.plan.period == 'month' && page.partial">
                            Next month: ${{ money(page.monthlyPrice, 0) }},
                        </span>
                        <span v-if="page.plan.period == 'month' || page.partial">
                            Full year price: ${{ money(page.yearlyPrice, 0) }}.
                        </span>
                    </p>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col>
                    <p>
                        See
                        <a href="/account/subscription">Subscription</a>
                        for details about your current plan and the
                        <a
                            :href="`https://www.embedthis.com/builder/doc/plans/overview/`"
                            target="_blank">
                            {{ page.plan.agent }} Pricing Plan.
                        </a>
                    </p>
                </v-col>
            </v-row>
        </vu-sign>
    </vu-form>
</template>

<style lang="scss">
.product-edit {
    .summary {
        width: 100%;
        margin: 12px 0 10px 0;
        p {
            margin-bottom: 12px;
        }
        th,
        td {
            padding: 0 8px 0 8px !important;
        }
    }
    h3 {
        font-weight: normal;
    }
    .hint {
        color: rgb(var(--v-theme-text-lighten-1));
    }
    .highVolume {
        color: red;
        font-weight: bold;
    }
    .enable-subscription {
        font-size: 1rem;
    }
    .clear {
        clear: both;
    }
    label.heading {
        margin-top: 8px;
        font-size: 1rem;
        color: rgb(var(--v-theme-text));
        flex: 1 1 100%;
    }
    .v-selection-control-group {
        margin-left: -4px;
        padding-inline-start: 0;
    }
    .units {
        .v-field__input {
            text-align: right;
        }
    }
}
</style>
