/*
    AppState.js -- Global state
 */

import {State, allow} from '@/paks/vu-app'
import Planner from '@/common/planner'
import blend from '@/paks/js-blend'
import {Account} from '@/models'
import {Schema} from '@/data'

const PersistState = [
    'bright',
    'dark',
    'initialized',
    'notices',
    'prefers',
    'signs',
    'upgradeAttempt',
    'wizardsCompleted',
]

export default class AppState {
    bright = 'auto'
    closing = null
    cognito = null
    context = {}
    dark = null
    display = {}
    doc = {}
    help = null
    initialized = false //  Defaults have been created
    lastAccess = new Date()
    location = {
        hash: null,
        prior: null,
        query: {},
        url: null,
    }
    logFormat = 'plain' //  Default log format
    logging = null //  Logging configuration
    maintenance = null
    mobile = false
    mock = false
    needs = {}
    nextTable = 0
    notices = {}
    prefers = {}
    rendered = false
    region = null
    refresh = null
    schema = null
    signs = {}
    social = null
    started = new Date()
    support = null
    unsaved = null
    upgrading = false
    upgradeAttempt = 0
    useMap = {}
    versions = {}
    widgetSets = []
    wizardActive = null
    wizardLaunch = null
    wizardsCompleted = {}

    constructor() {
        this.lastCheck = new Date(0)
    }

    saveState(state) {
        return allow(state, PersistState)
    }

    loadState(state) {
        state = allow(state, PersistState)
        if (state.notices) {
            for (let [key, value] of Object.entries(state.notices)) {
                if (typeof value != 'object') {
                    state.notices[key] = {show: value, dismiss: !value}
                }
            }
        }
        state.location = state.location || {}
        state.location.query = {}
        state.schema = Schema
        return state
    }

    activateWizard(active) {
        this.wizardActive = active
    }

    async closeAccount() {
        try {
            this.closing = true
            await Account.close()
        } catch (err) {
            Log.error('Cannot close account', {err})
        } finally {
            this.closing = false
        }
    }

    completeWizard(name, complete = new Date()) {
        this.wizardsCompleted[name] = complete
    }

    dismissSign(name) {
        this.signs[name] = this.signs[name] || {}
        this.signs[name].dismiss = true
    }

    dispatched() {
        this.dispatcher = new Date()
    }

    launchWizard() {
        this.wizardLaunch = new Date()
    }

    getBillTotal() {
        //  Make bill with pristine objects
        let {clouds, products, plans} = State.cache
        if (!State.auth.account) {
            return 0
        }
        let planner = new Planner(State.config.billing)
        //  Ignore invoices
        let bill = planner.makeBill(State.auth.account, clouds, products, plans, [], {
            pending: true,
        })
        return bill.total
    }

    getCart() {
        let now = Date.now()
        let plans = State.cache.plans
        let planner = new Planner(State.config.billing)
        let cart = plans.filter(
            (p) =>
                p.id != State.config.evalPlan &&
                p.pending &&
                p.start <= now &&
                planner.getPrice(State.auth.account, p, {period: true, upfront: true})
        )
        return cart
    }

    getNextTable() {
        return this.nextTable++
    }

    getSign(name) {
        return (this.signs[name] = this.signs[name] || {})
    }

    get suspended() {
        return State.auth?.account?.suspended
    }

    get isSocial() {
        let loc = this.location || '/'
        return loc.url.indexOf('/login/social') == 0 ? true : false
    }

    prefer(key, defaultValue) {
        let value = this.prefers[key]
        if (!value) {
            value = defaultValue
        }
        if (!value) {
            if (key == 'cloudId') {
                value = State.cache.clouds.filter(c => c.type != 'host')[0]?.id
            } else if (key == 'productId') {
                value = State.cache.products[0]?.id
            }
        }
        if (value) {
            if (key == 'cloudId') {
                if (!State.cache.clouds.find(c => c.id == value)) {
                    value = State.cache.clouds[0]?.id
                }
            } else if (key == 'productId') {
                if (!State.cache.products.find(p => p.id == value)) {
                    value = State.cache.products[0]?.id
                }
            }
        }
        return value
    }

    resetNotices() {
        for (let [key, value] of Object.entries(this.notices)) {
            value.show = null
            value.dismiss = null
        }
        for (let [key, value] of Object.entries(this.signs)) {
            value.show = null
            value.dismiss = null
        }
    }

    setActivity() {
        this.lastAccess = new Date()
    }

    setApi(api) {
        if (!api) {
            api = `${window.location.host}/api`
        }
        this.api = api
        // State.config.api = api
    }

    setCognito(cognito) {
        this.cognito = cognito
    }

    async setDisplay(display) {
        this.display = display
        if (display.theme) {
            this.setTheme(display.theme)
        }
        if (display.features) {
            this.setFeatures(display.features)
        }
        if (display.title) {
            this.setTitle(display.title)
        }
        this.fixViews(display)
        this.mobile = window.innerWidth <= 640
    }

    fixViews(parent) {
        let views = (parent.views || []).concat(parent.children || [])
        for (let view of views) {
            view.path = this.rebase(view.path, parent)
            if (view.redirect) {
                view.redirect = this.rebase(view.redirect, view)
            }
            if (view.views || view.children) {
                this.fixViews(view)
            }
        }
    }
    
    rebase(path, parent) {
        if (!path || path[0] == '/' || !parent) {
            return path
        }
        if (parent.path == '/') {
            return `/${path}`
        } else {
            return `${parent.path}/${path}`
        }
    }

    setFeatures(features) {
        State.config.features = blend(State.config.features || {}, features)
        if (State.config.features?.auth?.social) {
            this.setSocial(State.config.features.auth.social)
        }
    }

    /*
        Set the url for context sensitive help
     */
    setHelp(url) {
        this.help = url
    }

    setLogo(logo) {
        this.logo = logo
    }

    need(key) {
        return this.needs[key]
    }

    noNeed(key) {
        delete this.needs[key]
    }

    setNeed(key, value) {
        if (value == undefined) {
            value = Date.now()
        }
        if (this.need[key] == value) {
            this.need[key] = null
        }
        this.needs[key] = value
    }

    setPrefer(key, value) {
        this.prefers[key] = value
    }

    setRendered() {
        this.rendered = true
    }

    setSchema(schema) {
        this.schema = schema
    }

    setSocial(social) {
        this.social = social
    }

    setTheme(theme) {
        if (!theme.formats) {
            theme.formats = State.config.formats
        }
        if (!theme.login) {
            theme.login = State.config.login
        }
        State.config.theme = theme
    }

    setTitle(title) {
        this.title = title
    }

    //  Versions of various components. Currently only using versions.cloud
    setVersions(versions) {
        this.versions = versions || {}
    }

    setContext(key, value) {
        this.context[key] = value
    }

    /*
        Test a value against a patter with a default value if not defined
        Return true if the value matches the pattern
        Properties evaluated as:
            Number
            "string"
            /RegExp/
            "OP string" where OP is < <= >= > == !=
        }
        Group expression elements with:
            [ ] -- Return true if all are true
            { } -- Return true if any are true
        Returns true if any (or) of the values are true
        Return true if expression is not defined
     */
    testContext(value, pattern, defaultValue = true) {
        if (value == null) {
            return defaultValue
        }
        if (value === true) {
            return true
        }
        if (value === false) {
            return false
        }
        if (value === pattern) {
            return true
        }
        if (typeof pattern == 'string') {
            if (pattern[0] == '/' && pattern[pattern.length - 1] == '/') {
                pattern = pattern.slice(1, -1)
                return new RegExp(pattern).test(value) ? true : false
            } else {
                let [op, pat] = pattern.split(' ')
                if (op) {
                    switch (op) {
                        case '<':
                            return value < pat
                        case '<=':
                            return value <= pat
                        case '>':
                            return value > pat
                        case '>=':
                            return value >= pat
                        case '==':
                            return value == pat
                        case '!=':
                            return value != pat
                    }
                }
            }
        }
        if (Array.isArray(value)) {
            //  Implements "AND" of all items. One false and we're out.
            for (let item of value) {
                if (!testContext(value, item, defaultValue)) {
                    return false
                }
            }
            return true
        } else if (typeof value == 'object' && Object.keys(value).length > 0) {
            //  Implements "OR" of values. One true and we're in.
            for (let [key, pat] of Object.entries(value)) {
                if (this.testContext(this.context[key], pat, defaultValue)) {
                    return true
                }
            }
            return false
        }
        return defaultValue
    }
}
