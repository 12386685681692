<script setup>
import {onBeforeMount, reactive, ref, watch} from 'vue'
import {onBeforeRouteLeave} from 'vue-router'
import {Feedback, State, can, clone, navigate, getRoute, query, toTitle} from '@/paks/vu-app'
import {Token} from '@/models'
import Planner from '@/common/planner'
import ProductCenter from './ProductCenter.vue'
import ProductDownload from './ProductDownload.vue'
import ProductEdit from './ProductEdit.vue'

const Fields = [
    {name: 'edit', icon: (v, rec) => (!rec.planId ? '' : '$edit')},
    {
        name: 'download',
        icon: (v, rec) =>
            rec.agent != 'Other' && (rec.eval || rec.planId != null) ? '$download' : '',
    },
    {name: 'name'},
    {name: 'description', style: 'max-width: 400px; text-overflow: ellipsis;'},
    {name: 'scope', title: 'License Scope', format: (v) => toTitle(v), condition: 'Appweb|GoAhead'},
    {name: 'agent', title: 'Device Agent', format: (v) => toTitle(v)},
    {name: 'volume', title: 'Device Volume', condition: 'Ioto'},
    {name: 'test', title: 'Test Units', condition: 'Ioto'},
    {name: 'token', title: 'Product ID', align: 'left', slot: true},
]

const AppwebEvalProduct = {
    agent: 'Appweb',
    description: 'Appweb Evaluation Product',
    eval: true,
    name: 'Eval Appweb',
    plan: {count: 0, units: 0},
    scope: 'eval',
    id: '0',
    token: 'N/A',
}
const GoAheadEvalProduct = {
    agent: 'GoAhead',
    description: 'GoAhead Evaluation Product',
    eval: true,
    name: 'Eval GoAhead',
    plan: {count: 0, units: 0},
    scope: 'eval',
    id: '0',
    token: 'N/A',
}
const IotoEvalProduct = {
    agent: 'Ioto',
    description: 'Ioto Evaluation Product',
    eval: true,
    name: 'Eval Ioto',
    plan: {count: 0, units: 0},
    scope: 'eval',
    token: State.config.evalProductToken,
    id: '0',
}
const BuilderProduct = {
    agent: 'Builder',
    description: 'Builder Portal',
    name: 'Builder',
    plan: {count: 0, units: 0},
    scope: 'Internal Service',
    id: '1',
    token: 'N/A',
}
const ManagerProduct = {
    agent: 'Manager',
    description: 'Device Manager',
    name: 'Manager',
    plan: {count: 0, units: 0},
    scope: 'Internal Service',
    id: '2',
    token: 'N/A',
}

const page = reactive({
    canAdd: can('admin') && !State.app.suspended,
    confirmProduct: null,
    focus: {},
    eval: State.config.evalProductToken,
    fields: Fields,
    product: {plan: {count: 0, units: 0}},
    showEdit: false,
    select: null,
    showDownload: false,
    showServices: false,
    suspended: State.app.suspended ? true : false,
    tokens: [],
})

//  Component references
const confirm = ref(null)
const edit = ref(null)
const table = ref(null)
const route = getRoute()

let planner = new Planner(State.config.billing)

watch(
    () => State.app.mock,
    async () => {
        if (table.value) {
            await table.value.update()
        }
    }
)

onBeforeMount(async () => {
    if (can('admin')) {
        page.select = {actions: {Add: 0, Edit: 1}, multi: true, property: 'select'}
    } else {
        page.fields = page.fields.filter((f) => f.name != 'edit')
    }
    let products = clone(State.cache.products)
    if (route.params.id) {
        editProduct(products.find((p) => p.id == route.params.id))
    } else if (query('add')) {
        editProduct({})
    }
    //  Get Tokens
    await reload()
})

onBeforeRouteLeave(() => closeEditAsk(true))

async function toggleServices() {
    page.showServices = !page.showServices
    await reload()
}

async function reload() {
    await getTokens()
    if (table.value) {
        //  TODO - would be better if the table was reactive
        await table.value.update()
        await table.value.highlightRow('name', page.product.name)
    }
}

function getData() {
    let products = clone(State.cache.products)
    if (State.app.mock) {
        products = products.filter((p) => p.id != State.config.evalProduct)
    }
    for (let product of products) {
        applyPlan(product)
    }
    page.confirmProduct = null
    let soon = new Date(Date.now() + 86400 * 7 * 1000)
    for (let product of products) {
        if (product.id == State.config.evalProduct) {
            continue
        }
        let plan = product.plan
        let price = planner.getPrice(State.auth.account, plan, {upfront: true})
        if (!State.app.mock && plan.pending && plan.start < soon && price > 0) {
            page.confirmProduct = `${product.name} ${product.agent}`
        }
        let token = page.tokens.find((t) => t.owner == `product:${product.id}`)
        product.token = token?.id
    }
    if (page.showServices) {
        products.unshift(BuilderProduct, ManagerProduct)
    }
    products.unshift(IotoEvalProduct)
    products.unshift(AppwebEvalProduct)
    products.unshift(GoAheadEvalProduct)
    if (!page.product?.id) {
        setProduct(products[0])
    }
    return products
}

function setProduct(product) {
    page.product = product
    applyPlan(product)
}

function applyPlan(product) {
    if (!product) return
    let plan = (product.plan = State.get('Plan', product.planId))
    if (plan) {
        product.scope = plan.scope
        if (plan.type == 'Volume') {
            product.volume = Math.max(plan.count, plan.units)
            product.test = plan.test
        } else {
            product.volume = plan.units
        }
        product.agent = plan.agent
    } else {
        product.plan = {count: 0, units: 0, eval: product.eval}
    }
}

async function getTokens() {
    if (can('admin')) {
        page.tokens = await Token.find({type: 'ProductID', enable: true})
    }
}

async function clicked({action, item, items, column, row}) {
    if (action == 'add') {
        if (!can('admin')) {
            return
        }
        editProduct({})
        await reload()
    } else if (action == 'edit') {
        if (can('admin') && !item.eval) {
            editProduct(item)
            await reload()
        }
    } else if (action == 'cell') {
        if (column.name == 'token') {
            navigator.clipboard.writeText(item.token)
            Feedback.info(`Product ID token copied to clipboard`)
        } else if (column.name == 'download') {
            editDownload(item)
        } else if (column.name == 'edit') {
            if (can('admin') && !item.eval) {
                editProduct(item)
                await reload()
            }
        } else {
            await table.value.highlightRow(null, row)
            setProduct(item)
        }
    }
}

async function closeEditAsk(discard) {
    let product = edit.value?.page?.product
    if (!product) {
        return true
    }
    if ((discard && product.id) || (product.id == null && product.name)) {
        if (await confirm.value.ask(`Do you want to discard changes? `)) {
            return true
        }
        return false
    }
    return true
}

async function editDownload(product) {
    page.showDownload = product ? true : false
    if (product) {
        setProduct(product)
    }
}

async function editProduct(product) {
    if (product) {
        setProduct(product)
        page.showEdit = true
    } else {
        page.showEdit = false
        await reload()
        if (!page.product.agent) {
            setProduct(State.cache.products[0])
            await table.value.highlightRow('name', page.product.name)
        }
    }
}
</script>
<template>
    <div class="page product-list">
        <vu-help url="/doc/ui/products/list.html" v-if="!page.showEdit" />

        <vu-sign
            v-if="page.confirmProduct"
            color="error"
            name="product-list-sign-0"
            title="Confirm Product"
            subtitle=""
            :required="true">
            <p>
                Please
                <a @click="navigate('/account/subscription')">Confirm</a>
                your "{{ page.confirmProduct }}" product purchase to activate and to download
                release software.
            </p>
            <p>
                <v-btn color="accent" @click="navigate('/account/subscription')">
                    Confirm Product
                </v-btn>
            </p>
        </vu-sign>

        <vu-sign
            name="notice-billing"
            :show="page.suspended"
            title="Account Suspended"
            color="error"
            :required="true">
            <p>
                Your account has been suspended due to an outstanding billing issue and only
                evaluation software can be downloaded.
            </p>
            <p>
                Please correct at
                <a @click="navigate('/account/billing')">Account Billing</a>
                or contact support for help.
            </p>
        </vu-sign>

        <vu-sign name="product-list-sign-1" title="Product Management" subtitle="" color="accent">
            <p>
                To evaluate Ioto and cloud-based management, use the
                <b>Eval</b>
                product definition to download Ioto and connect your device to the eval cloud
                (eval.ioto.me).
                <br />
                Copy the
                <b>Product ID</b>
                for the "product" property in the
                <b>device.json5</b>
                file of the downloaded Ioto agent.
            </p>
            <p>
                To evaluate GoAhead or Appweb, click
                <b>Add Product</b>
                to create a product definition, then click download. For other device agents, click
                <b>Add Product</b>
                and select
                <b>Other Device Agent.</b>
            </p>

            <p>
                For purchases, go to the
                <a @click="navigate('/account/subscription')">Account Subscription</a>
                page to customize the subscription for your products.
            </p>
        </vu-sign>

        <vu-table
            title="Product Center"
            name="products"
            nodata=" "
            options="dynamic,refilter,toolbar"
            ref="table"
            sort="name:asc"
            subtitle="Click Edit to modify the product or click on a product for the product's security center"
            width="100%"
            :data="getData"
            :pageSize="7"
            :fields="page.fields"
            :select="page.select"
            @click="clicked">
            <template v-slot:table-col-token="props">
                {{ props.field.value }}
                <v-icon icon="$copy" class="copy" size="14" />
            </template>
            <!--
                <span>{{ '...' + (State.app.mock ? 'XXXXXX' : props.field.value?.slice(-6)) }}</span>
                -->
            <template v-slot:more="props">
                <v-btn class="action" @click="toggleServices">Show Services</v-btn>
                <v-btn v-if="page.canAdd" class="action" @click="navigate('/account/subscription')">
                    Subscription
                </v-btn>
                <v-btn color="accent" :disabled="!page.canAdd" @click="editProduct({})">
                    Add Product
                </v-btn>
            </template>
        </vu-table>

        <ProductCenter
            v-if="page.product.agent && page.product.agent != 'Other' && !page.suspended"
            :product="page.product" />

        <vu-panel
            v-model="page.showEdit"
            @close="editProduct"
            :widths="['700px']"
            :confirm="closeEditAsk">
            <ProductEdit :id="page.product.id" @input="editProduct" ref="edit" />
        </vu-panel>

        <vu-panel v-model="page.showDownload" @close="editDownload" :widths="['500px']">
            <ProductDownload :product="page.product" @input="editDownload" ref="download" />
        </vu-panel>

        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.product-list {
    .v-input--switch {
        .v-input__slot {
            width: auto;
        }
    }
    .action {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border-lighten-1));
        background: none !important;
        box-shadow: none !important;
    }
    .copy {
        float: right;
        margin-top: 4px;
    }
    td.selected {
        color: white !important;
        background-color: rgba(var(--v-theme-accent), 0.85) !important;
    }
}
</style>
