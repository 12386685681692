/*
    Utils.js -- App utilities
 */

import {getCurrentInstance, nextTick} from 'vue'
import {Net} from '@/paks/js-net'
import {Auth} from '@/paks/vu-auth/Auth.js'
import {State} from '@/paks/vu-state'
import {icons, mdi} from '@/paks/vu-theme'

export {arrayBufferToBase64} from '@/paks/js-base64'
export {titlecase, toTitle} from '@/paks/js-polyfill'

export function can(role) {
    return Auth.can(role)
}

export function canUser(user, role) {
    return Auth.canUser(user, role)
}

export async function delay(time) {
    return new Promise(function (resolve, reject) {
        setTimeout(() => resolve(true), time)
    })
}

export async function disableAutoComplete() {
    await delay(1)
    let elements = document.querySelectorAll('[autocomplete="off"]')
    if (elements) {
        elements.forEach(async (element) => {
            element.setAttribute('readonly', 'readonly')
            element.style.backgroundColor = 'inherit'
            await delay(500)
            element.removeAttribute('readonly')
        })
    }
}

/*
    Test if two objects are equal
*/
export function equal(a, b) {
    if (!a && !b) {
        return true
    }
    if (!a || !b) {
        return false
    }
    const ak = Object.keys(a)
    const bk = Object.keys(b)
    if (ak.length !== bk.length) {
        return false
    }
    for (let key of ak) {
        if (typeof a[key] == 'object' && typeof b[key] == 'object') {
            if (a[key] instanceof Date && b[key] instanceof Date) {
                if (a[key].getTime() != b[key].getTime()) {
                    return false
                }
            } else if (!equal(a[key], b[key])) {
                return false
            }
        } else {
            if (a[key] !== b[key]) {
                return false
            }
        }
    }
    return true
}

export async function fetch(url, options) {
    let net = new Net()
    return await net.fetch(url, options)
}

/*
    Workaround for v-dialog failing to remove overlay. If we don't remove the overlay, then
    users cannot click cancel on the progress dialog.
*/
export function fixShadow() {
    let elements = document.getElementsByClassName('v-overlay theme--dark')
    if (elements) {
        for (let i = 0; i < elements.length; i++) {
            let overlay = elements[i]
            overlay.parentNode.removeChild(overlay)
            i--
        }
    }
}

export function getVue() {
    return State.ref.vue
}

export function getVuetify() {
    return State.ref.vuetify
}

export function getModel(model) {
    return State.app.schema.models[model]
}

export function getModels() {
    return State.ref.models
}

export function getRoutePath() {
    return State.ref.router?.currentRoute?.value?.path
}

export function getRoute() {
    return State.ref?.router?.currentRoute?.value
}

export function getRouter() {
    return State.ref?.router
}

//  MOB - rename
export function getRoutes() {
    return State.ref?.routes
}

export function getSelf() {
    return getCurrentInstance().proxy
}

export function getValue(n) {
    n = n.toString().toLowerCase()
    if (n.indexOf('sec') > 0) {
        n = parseInt(n)
    } else if (n.indexOf('min') > 0) {
        n = parseInt(n) * 60
    } else if (n.indexOf('hr') > 0 || n.indexOf('hour') > 0) {
        n = parseInt(n) * 60 * 60
    } else if (n.indexOf('day') > 0) {
        n = parseInt(n) * 60 * 60 * 24
    } else if (n.indexOf('wk') > 0 || n.indexOf('week') > 0) {
        n = parseInt(n) * 60 * 60 * 24 * 7
    } else if (n.indexOf('mth') > 0 || n.indexOf('month') > 0) {
        n = parseInt(n) * 60 * 60 * 24 * 28
    } else if (n.indexOf('yr') > 0 || n.indexOf('year') > 0) {
        n = parseInt(n) * 60 * 60 * 24 * 365
    } else if (n == 'unlimited' || n == 'infinite' || n == 'never') {
        n = Number.MAX_SAFE_INTEGER
    } else if (n.indexOf('kb') > 0 || n.indexOf('k') > 0) {
        n = parseInt(n) * 1024
    } else if (n.indexOf('mb') > 0 || n.indexOf('m') > 0) {
        n = parseInt(n) * 1024 * 1024
    } else if (n.indexOf('gb') > 0 || n.indexOf('g') > 0) {
        n = parseInt(n) * 1024 * 1024 * 1024
    } else {
        n = parseInt(n)
    }
    return +n
}

export function hidden() {
    return document.hidden || document.msHidden || document.webkitHidden || document.mozHidden
}

/*
    Navigate to a client page. This method is mixed into all Vue components
*/
export function navigate(url, query) {
    try {
        let path = window.location.pathname + window.location.hash.replace(/^#\//, '')
        query = query || {}
        if (url == null) {
            State.ref.router.go(-1)
        } else if (url != path || JSON.stringify(query) != window.location.search) {
            if (query) {
                State.ref.router.push({path: url, query}).catch(() => {})
            } else {
                State.ref.router.push({path: url, query: null}).catch(() => {})
            }
        }
    } catch (err) {}
}

export async function renderDom() {
    return new Promise(function (resolve, reject) {
        window.requestAnimationFrame(() => {
            window.requestAnimationFrame(() => {
                resolve(true)
            })
        })
    })
}

export function vuetifyProps(props) {
    return {
        components: props.components,
        directives: props.directives,
        icons: {
            defaultSet: 'mdi',
            aliases: {
                ...icons,
                ...props.icons,
            },
            sets: {
                ...props.sets,
                mdi,
            },
        },
        theme: {
            defaultTheme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
            themes: props.themes,
            variations: {
                colors: ['primary', 'secondary', 'text', 'border', 'background', 'none', 'accent'],
                lighten: 2,
                darken: 2,
            },
        },
        defaults: {
            VTextField: {variant: 'underlined'},
        },
    }
}

export async function waitRender() {
    await nextTick()
    await renderDom()
}
