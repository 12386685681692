<script setup>
import {onBeforeMount, onMounted, reactive, watch} from 'vue'
import {State, can, clone, getModels, titlecase, unique} from '@/paks/vu-app'
import DashRange from './DashRange.vue'

const Statistics = ['avg', 'current', 'min', 'max', 'sum']

const props = defineProps({
    widget: Object,
})

const page = reactive({
    ask: {
        dashboard: false,
        dimensions: false,
        cloud: false,
        metric: false,
        model: false,
        namespace: false,
        sets: false,
        text: false,
    },
    clouds: State.cache.clouds.sort(sortByName),
    dashboards: State.cache.dashboards.map((d) => d.name),
    database: false,
    dimensions: null,
    dimensionsList: [],
    fetching: null,
    fields: [],
    hasMetrics: null,
    models: [],
    multicloud: true,
    namespaces: [],
    prior: null,
    ready: null,
    rules: [],
    metrics: [],
    schema: null,
    sets: null,
    types: [],
    widget: null,
    widgetSet: null,
})

//  MOB - remove
const combo = {contentClass: 'widget-data-combo'}
const select = {contentClass: 'widget-data-select'}

const {Metric} = getModels()

var metricPromise = null

const WidgetTypes = [
    {value: 'button', label: 'Button'},
    {value: 'event', label: 'Event'},
    {value: 'form', label: 'Form'},
    {value: 'gauge', label: 'Gauge'},
    {value: 'graph', label: 'Graph'},
    {value: 'image', label: 'Image'},
    {value: 'input', label: 'Input'},
    {value: 'label', label: 'Label'},
    {value: 'led', label: 'Led'},
    {value: 'metric', label: 'Metric Table'},
    {value: 'numeric', label: 'Numeric'},
    {value: 'progress', label: 'Progress'},
    {value: 'set', label: 'Service Overview'},
    {value: 'sign', label: 'Sign'},
    {value: 'shape', label: 'Shape'},
    {value: 'table', label: 'Table'},
    {value: 'toolbar', label: 'Toolbar'},
]

onBeforeMount(() => {
    page.widget = props.widget
    page.types = WidgetTypes.sort(sortByLabel)
})

onMounted(async () => {
    page.database =
        State.config.name == 'builder' ? can('support') : State.config.features.dash?.database

    let features = State.config.features
    page.multicloud = features.widget?.multiCloud === false ? false : true

    let clouds = clone(State.cache.clouds)
    if (page.multicloud && State.config.name == 'builder') {
        clouds.push({id: 'builder', name: 'Builder', type: 'service'})
    }
    clouds.forEach((c) => (c.name = `${titlecase(c.type)}: ${c.name}`))
    page.clouds = clouds.sort(sortByName)
    page.hasMetrics = page.clouds.length ? true : false

    page.schema = State.app.schema
    page.models = Object.keys(page.schema.models)
        .filter((m) => m != '_Schema' && m != '_Migrations')
        .sort()
    page.dimensions = dimensionsToString(page.widget.dimensions)

    if (State.app.widgetSets) {
        let dedicated = page.clouds.find((c) => c.type == 'dedicated')
        page.sets = State.app.widgetSets
            .filter((s) => s.enable !== false && (s.name == 'Default' || dedicated))
            .map((s) => s.name)
        if (page.sets.length == 1) {
            page.widgetSet = page.sets[0]
        }
    }
    watch(() => page.widget.cloudId, changeCloud, {immediate: true})
    watch(() => page.widget.model, changeModel, {immediate: true})
    watch(() => page.widget.namespace, changeNamespace, {immediate: true})
    watch(() => page.widget.metric, changeMetric, {immediate: true})
    watch(() => page.widget.text, changeText)
    watch(() => page.dimensions, changeDimensions, {immediate: true})
})

async function changeCloud(value, prior) {
    State.app.setPrefer('cloudId', value)
    await change('cloudId', value, prior)
}

async function changeNamespace(value, prior) {
    let widget = page.widget
    if (value == 'None') {
        value = null
        widget.metric = widget.model = null
    }
    await change('namespace', value, prior)
}

async function changeMetric(value, prior) {
    await change('metric', value, prior)
}

async function changeDimensions(value, prior) {
    page.widget.dimensions = dimensionsToObject(page.dimensions)
}

async function changeModel(value, prior) {
    let widget = page.widget
    let model = page.schema.models[widget.model]
    if (model) {
        page.fields.length = 0
        for (let [name, field] of Object.entries(model)) {
            if (name == 'pk' || name == 'sk' || name == '_type' || name == '_source') {
                continue
            }
            if (widget.type == 'graph' || widget.type == 'gauge' || widget.type == 'numeric') {
                if (field.type != 'number') {
                    if (name != 'Store' && field.type != 'object') {
                        continue
                    }
                }
            }
            page.fields.push(name)
        }
        page.fields = page.fields.sort()
        setDefaults('model', value, prior)
        setAsk()
    }
}

async function changeText() {
    let widget = page.widget
    if (widget.text) {
        widget.namespace = 'None'
    }
}

async function change(tag, value, prior) {
    let {widget} = page
    if (value && value === prior) return

    //  Wait for other changes to complete
    while (page.fetching) {
        await metricPromise
    }
    page.fetching = true

    //  Clear downstream properties
    if (prior) {
        if (tag == 'type') {
            // widget.namespace = null
        }
        if (tag == 'cloudId') {
            widget.namespace = null
        }
        if (tag == 'namespace' || tag == 'cloudId') {
            widget.metric = null
        }
        if (tag == 'metric' || tag == 'namespace' || tag == 'cloudId') {
            page.dimensions = widget.model = widget.field = null
        }
    }
    metricPromise = new Promise(async (resolve, reject) => {
        try {
            //  Fetch metrics and pick out results
            let cloudId = widget.cloudId || State.cache.clouds[0]?.id
            if (cloudId) {
                widget.cloudId = cloudId
                let results = await getMetricList(tag, widget)
                page.hasMetrics = results.namespaces.length
                if (results.namespaces) {
                    if (tag == 'cloudId') {
                        page.namespaces = sortNamespaces(results.namespaces)
                        if (page.database) {
                            page.namespaces.unshift('Database')
                        }
                        if (State.app.mock) {
                            let cloud = State.get('Cloud', widget.cloudId)
                            if (!cloud || cloud.type == 'hosted') {
                                page.namespaces = page.namespaces.filter(
                                    (n) => n[0] != '@' && n != 'Embedthis/Builder'
                                )
                            }
                        }
                        page.namespaces.push('None')
                    }
                }
                if (tag == 'cloudId' || tag == 'namespace') {
                    page.metrics = results.metrics || []
                }
                if (tag == 'cloudId' || tag == 'namespace' || tag == 'metric') {
                    page.dimensionsList = makeDimensionsList(results.dimensions)
                }
            } else {
                if (tag == 'cloudId') {
                    if (page.database) {
                        page.namespaces.unshift('Database')
                        page.namespaces.push('None')
                    }
                }
            }
            setDefaults(tag, value, prior)
            setAsk()
            resolve(true)
        } catch (err) {
            reject(err)
        } finally {
            page.ready = true
        }
    })
    //  Wait for completion
    await metricPromise
    page.fetching = false
}

async function getMetricList(tag, widget) {
    let params = {}
    for (let key of ['cloudId', 'namespace', 'metric']) {
        if (widget[key] != null) {
            params[key] = widget[key]
        }
        if (key == tag) break
    }
    return await Metric.getMetricList(params)
}

function setDefaults(tag, value, prior) {
    let {dimensions, dimensionsList, metrics, namespaces, widget} = page
    if (widget.id == null && !widget.cloudId && State.cache.clouds.length == 1) {
        widget.cloudId = State.cache.clouds[0].id
    }
    if (tag == 'cloudId' && namespaces.length && widget.cloudId) {
        if (!widget.namespace || (!prior && namespaces.indexOf(widget.namespace) < 0)) {
            if (
                widget.type == 'text' ||
                widget.type == 'button' ||
                widget.type == 'image' ||
                widget.type == 'input'
            ) {
                widget.namespace = 'None'
            } else {
                widget.namespace = namespaces[0]
            }
        }
    }
    if ((tag == 'cloudId' || tag == 'namespace') && metrics.length) {
        if (!widget.metric || (!prior && metrics.indexOf(widget.metric) < 0)) {
            if (widget.namespace != 'Database') {
                widget.metric = metrics[0]
            }
        }
    }
    if ((tag == 'cloudId' || tag == 'namespace' || tag == 'metric') && dimensionsList.length) {
        if (widget.namespace != 'Database') {
            if (dimensions) {
                if (tag == 'metric' && dimensionsList.indexOf(dimensions) < 0) {
                    page.dimensions = dimensionsList[0]
                }
            } else {
                //  This assumes that the dimensionsList[0] is ALL to match dimensions of ""
                page.dimensions = dimensionsList[0]
            }
        }
    }
    if (tag == 'namespace' && widget.namespace == 'Database' && page.models.length == 1) {
        widget.model = page.models[0]
    }
    if (tag == 'model' && page.fields.length) {
        if (page.fields.length == 1) {
            if (widget.type == 'table') {
                widget.fields = page.fields
            } else {
                widget.field = page.fields[0]
            }
        }
    }
    widget.statistic = widget.statistic || 'sum'
}

function setAsk() {
    let {ask, widget} = page
    ask.dashboard = false
    ask.dimensions = false
    ask.cloud = false
    ask.model = false
    ask.metric = false
    ask.namespace = false
    ask.sets = false

    if (widget.type != 'image') {
        if (page.clouds.length > 0) {
            ask.cloud = true
            ask.sets = widget.type == 'set' ? true : false
        }
        if (!ask.sets) {
            ask.namespace = true
            if (page.namespaces.length > 0) {
                ask.namespace = true
            }
            if (widget.namespace == 'Database') {
                if (page.models.length > 0) {
                    ask.model = true
                }
            } else if (widget.namespace && widget.namespace != 'None') {
                if (page.metrics.length > 0) {
                    ask.metric = true
                    if (page.dimensionsList.length && widget.type != 'metric') {
                        ask.dimensions = true
                    }
                }
            }
        }
    }
    if (!page.multicloud) {
        ask.cloud = false
    }
    if (!page.hasMetrics) {
        ask.metric = false
    }
}

function sortByName(a, b) {
    if (a.name < b.name) {
        return -1
    } else if (b.name < a.name) {
        return 1
    }
    return 0
}

function sortNamespaces(list) {
    return unique(
        list
            .map((i) => i.replace('@', '|'))
            .sort()
            .map((i) => i.replace('|', '@'))
    )
}

/*
    Convert the CustomMetrics dimensions list to a key=value,... list suitable for a select input
 */
function makeDimensionsList(list) {
    let dimensions = []
    if (!list || list.length == 0) {
        list = [{}]
    }
    for (let dimension of list) {
        let set = []
        for (let [key, value] of Object.entries(dimension)) {
            if (key == 'Hub' || key == 'Cloud') {
                let cloud = State.get('Cloud', value)
                if (cloud) {
                    value = cloud.name
                } else {
                    continue
                }
            } else if (key == 'Product') {
                let product = State.get('Product', value)
                if (product) {
                    value = product.name
                } else {
                    continue
                }
            }
            if (key == 'Url' && value.indexOf('%') >= 0) continue
            set.push(`${key}=${value}`)
        }
        if (set.length) {
            dimensions.push(set.sort().join(', '))
        } else {
            dimensions.push('All')
        }
    }
    return unique(dimensions.sort())
}

function dimensionsToString(dimensions) {
    let set = []
    if (dimensions) {
        for (let [key, value] of Object.entries(dimensions)) {
            if (key == 'Hub' || key == 'Cloud') {
                let cloud = State.get('Cloud', value)
                if (cloud) {
                    value = cloud.name
                } else {
                    continue
                }
            } else if (key == 'Product') {
                let product = State.get('Product', value)
                if (product) {
                    value = product.name
                } else {
                    continue
                }
            } else if (!key) {
                continue
            }
            set.push(`${key}=${value}`)
        }
    }
    return set.sort().join(', ')
}

/*
    Dimensions are an object of multiple properties. If {}, that means "All".
 */
function dimensionsToObject(dimensions) {
    let result = {}
    if (!dimensions) {
        dimensions = ''
    }
    for (let dimension of dimensions.split(',').map((d) => d.trim())) {
        let [key, value] = dimension.split('=').map((p) => p.trim())
        if (key == 'All' || key == '*' || !key) {
            return {}
        } else {
            if (key == 'Hub' || key == 'Cloud') {
                let cloud = State.cache.clouds.find((c) => c.name == value)
                if (cloud) {
                    value = cloud.id
                }
            } else if (key == 'Product') {
                let product = State.get('Product', value)
                if (product) {
                    value = product.name
                }
            }
            result[key] = value
        }
    }
    return result
}

function sortByLabel(a, b) {
    if (a.label < b.label) {
        return -1
    } else if (b.label < a.label) {
        return 1
    }
    return 0
}
</script>

<template>
    <v-container class="widget-data pa-0">
        <div _v-if="!page.widget.text" class="vrow">
            <vu-input
                v-if="page.ask.cloud && page.clouds.length > 1"
                v-model="page.widget.cloudId"
                item-value="id"
                item-title="name"
                label="Source"
                type="select"
                :items="page.clouds"
                :menu-props="select"
                :rules="page.rules.required" />

            <vu-input
                v-if="page.ask.sets"
                v-model="page.widgetSet"
                item-value="name"
                item-title="description"
                label="Service Widgets"
                type="select"
                :items="page.sets"
                :menu-props="select" />

            <vu-input
                v-if="page.ask.namespace"
                v-model="page.widget.namespace"
                label="Namespace"
                type="combo"
                :items="page.namespaces"
                :rules="page.rules.required"
                :menu-props="combo" />

            <vu-input
                v-if="page.ask.model"
                v-model="page.widget.model"
                label="Database Table"
                type="combo"
                :items="page.models"
                :rules="page.rules.required"
                :menu-props="combo" />

            <v-progress-linear v-if="!page.ready" class="progress" indeterminate color="accent" />

            <vu-input
                v-if="page.widget.type != 'table' && page.ask.model"
                v-model="page.widget.field"
                label="Item Field"
                type="combo"
                :items="page.fields"
                :rules="page.rules.required"
                :menu-props="combo" />

            <vu-input
                v-if="page.widget.type == 'table' && page.ask.model"
                v-model="page.widget.fields"
                label="Table Fields"
                placeholder="Blank for all fields"
                type="combo"
                :items="page.fields"
                :multiple="true"
                :rules="page.rules.required"
                :menu-props="combo" />

            <vu-input
                v-if="page.ask.model"
                v-model="page.dimensions"
                label="Filter Expression"
                placeholder="field=value, field=value, ..."
                type="text" />

            <vu-input
                v-if="page.ask.metric"
                v-model="page.widget.metric"
                label="Metric"
                type="combo"
                :items="page.metrics"
                :rules="page.rules.required"
                :menu-props="combo" />

            <vu-input
                v-if="page.ask.metric && page.dimensionsList.length && page.ask.dimensions"
                v-model="page.dimensions"
                flat
                label="Resource Dimensions"
                type="combo"
                :items="page.dimensionsList"
                :menu-props="combo"
                :rules="page.rules.required" />

            <vu-input
                v-if="page.ask.metric"
                v-model="page.widget.statistic"
                flat
                label="Statistic"
                placeholder="Average"
                type="select"
                :items="Statistics"
                :rules="page.rules.required"
                :menu-props="select" />

            <vu-input
                v-if="page.ask.text"
                v-model="page.widget.text"
                label="Widget Text"
                rows="4"
                type="text"
                :rules="page.rules.text" />

            <vu-input
                v-if="page.ask.dashboard"
                v-model="page.widget.dashboard"
                item-value="name"
                item-title="name"
                label="Dashboards"
                type="select"
                :items="page.dashboards"
                :menu-props="select" />
        </div>

        <vu-input
            v-if="page.ready && page.widget.namespace == 'None' && (page.widget.type == 'button' || page.widget.type == 'text')"
            v-model="page.widget.text"
            label="Literal Widget Data"
            type="textarea"
            class="mt-4"
            rows="2"
            :clearable="true" />

        <vu-input
            v-if="page.ready && page.ask.metric"
            type="checkbox"
            label="Override Date"
            v-model="page.widget.range.override"
            hide-details />

        <DashRange v-if="page.widget.range.override" v-model="page.widget.range" mode="date" />
    </v-container>
</template>

<style lang="scss">
.widget-data {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    h2 {
        font-size: 1.4rem;
        font-weight: normal;
    }
    .parameters {
        width: 100%;
    }
    .v-alert {
        width: 100%;
    }
    .custom-input {
        width: 95px !important;
    }
    .date-range-picker {
        padding-right: 14px;
        display: inline-flex;
        align-items: flex-end;
    }
    .progress {
        margin-top: -24px;
        margin-bottom: 8px;
    }
}
.widget-data-combo {
    border-radius: 0px !important;
    min-height: 32px !important;
    .v-list {
        padding: 0;
    }
    .v-list-item {
        border-bottom: solid 1px rgb(var(--v-theme-border-lighten-1));
        .v-list-item__content {
            padding: 0 !important;
            margin: 0 !important;
        }
        .v-list-item__action {
            margin: 4px 8px !important;
        }
    }
}
.widget-data-select {
    border-radius: 0px !important;
    margin-top: 40px;
    .v-list {
        padding: 0 !important;
        .v-list-item {
            border-bottom: solid 1px rgb(var(--v-theme-border-lighten-1));
            min-height: 40px !important;
        }
    }
}
</style>
