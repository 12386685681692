<script setup>
import {getCurrentInstance, onBeforeMount, reactive, ref, watch} from 'vue'
import {Feedback, Progress, State, clone, money, navigate, query, toTitle, waitRender} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'
import Planner from '@/common/planner'
import CardEdit from './CardEdit.vue'
import InvoiceDetails from './InvoiceDetails.vue'
import {Invoice, Plan} from '@/models'
import {runBilling} from '@/compose/Billing'

const {proxy: self} = getCurrentInstance()

const Active = 0
const Cart = 1
const TabNames = ['Subscriptions', 'Cart Items']
const Fields = [
    {name: 'edit', icon: '$edit'},
    {name: 'date', format: (v) => formatDate(v)},
    {name: 'type'},
    {name: 'description', width: '25%'},
    {name: 'quantity', align: 'right'},
    {name: 'range', title: 'Period', align: 'right'},
    {name: 'status', align: 'center', css: (v) => (v == 'Expired' ? 'expired' : '')},
    {name: 'amount', title: 'Amount', align: 'right', format: (v) => `\$${money(v, 2)}`, width: '10%'},
]
let account = clone(State.auth.account)

const page = reactive({
    account: account,
    billing: account.billing,
    agreement: false && account.billing.agreement,
    card: {},
    counts: [0, 0],
    demo: State.app.mock,
    downloading: false,
    items: [[], []],
    purchasing: false,
    ready: false,
    removing: false,
    showCardEdit: false,
    showDetails: false,
    tab: 0,
    tabs: [0, 1],
    total: 0,
})

//  Component Refs
const confirm = ref(null)
const table = ref(null)

const planner = new Planner(State.config.billing)

let products, clouds, plans

watch(() => page.tab, changeTab)

onBeforeMount(async () => {
    prepItems()

    page.tab = hasItems() ? Cart : Active
    calcTotal()
    page.ready = true

    await updateTables()
    if (query('invoice')) {
        editDetails({})
    }
})

function prepItems() {
    products = clone(State.cache.products)
    clouds = clone(State.cache.clouds).filter((c) => c.type != 'host')
    plans = clone(State.cache.plans)

    for (let i = 0; i < 2; i++) {
        prepTab(i)
    }
    calcTotal()
}

function prepFields(tab) {
    let fields = clone(Fields)
    if (tab == Active) {
        fields = fields.filter(
            (f) => f.name != 'approved' && f.name != 'edit' && f.name != 'amount'
        )
    } else {
        fields = fields.filter((f) => f.name != 'date' && f.name != 'status')
    }
    return fields
}

function prepTab(tab) {
    let now = new Date()
    let list = []

    /*
        Products
     */
    for (let product of products) {
        if (product.id == State.config.evalProduct) {
            continue
        }
        let plan = (product.plan = plans.find((p) => p.id == product.planId))
        if (!plan) continue
        let {date, price, range, scope, status} = getDetails(tab, page.account, plan)
        let now = new Date()

        if (!range) {
            continue
        }
        let quantity = tab == Active ? plan.current.units : plan.units
        if (plan.type == 'Subscription' || plan.type == 'Buyout') {
            if (plan.scope == 'product') {
                quantity = `single ${plan.scope}`
            } else {
                quantity = `${plan.scope} of devices`
            }
            scope = `${scope} subscription`
        } else {
            if (quantity == 1) {
                quantity = `${quantity} device`
            } else {
                quantity = `${quantity} devices`
            }
            scope = `${scope} subscription`
        }

        if (price) {
            list.push({
                amount: price,
                date: date,
                description: `${toTitle(planner.getTerm(plan))} ${scope} for ${plan.agent} in ${
                    product.name
                } devices`,
                due: planner.getDue(plan) <= now,
                id: product.id,
                quantity,
                plan: plan,
                range: range,
                scope: scope,
                status: status,
                type: 'Product',
            })
        }
    }

    /*
        Clouds
    */
    for (let cloud of clouds) {
        if (cloud.id == State.config.evalCloud) {
            continue
        }
        let plan = (cloud.plan = plans.find((p) => p.id == cloud.planId))
        if (!plan) {
            continue
        }
        let {date, price, range, status} = getDetails(tab, page.account, plan)

        if (price == 0 || !range) continue
        if (tab == Active && plan.current.end < now) continue
        if (tab == Cart) {
            if (
                plan.period == 'year' &&
                plan.start.getTime() > now.getTime() + 366 * 86400 * 1000
            ) {
                continue
            }
            if (
                plan.period == 'month' &&
                plan.start.getTime() > now.getTime() + 31 * 86400 * 1000
            ) {
                continue
            }
        }

        let quantity = tab == Active ? plan.current.units : plan.units

        list.push({
            amount: price,
            date: date,
            description: `${cloud.name} in ${cloud.region}`,
            due: planner.getDue(plan) <= now,
            id: cloud.id,
            period: plan.period,
            plan: plan,
            quantity: `${quantity} device${quantity == 1 ? '' : 's'}`,
            range: range,
            status: status,
            type: 'Cloud',
        })
    }

    /*
        Support
    */
    for (let plan of plans.filter((p) => p.type == 'Support' || p.type == 'Developer')) {
        let {date, price, range, status} = getDetails(tab, page.account, plan)
        if (price == 0 || !range) continue
        if (tab == Active) {
            if (plan.current.end < now || plan.current.units == 0) {
                continue
            }
        } else {
            if (plan.type == 'Support') {
                if (
                    plan.period == 'year' &&
                    plan.start.getTime() > now.getTime() + 366 * 86400 * 1000
                ) {
                    continue
                }
                if (
                    plan.period == 'month' &&
                    plan.start.getTime() > now.getTime() + 31 * 86400 * 1000
                ) {
                    continue
                }
            }
        }
        let description, quantity
        if (plan.type == 'Support') {
            description = 'Basic Support'
        } else if (plan.type == 'Developer') {
            description = 'Developer Support'
            if (tab == Active) {
                quantity = `${plan.current.units} ${plan.current.units == 1 ? 'hour' : 'hours'}`
                //  Better to show amount which is the cost of the remaining support
                price = plan.current.units * State.config.billing.plans.Developer.pricing[0].price
            } else {
                quantity = `${plan.units} ${plan.units == 1 ? 'hour' : 'hours'}`
            }
        }
        list.push({
            amount: price,
            date: date,
            description: description,
            due: planner.getDue(plan) <= now,
            id: plan.id,
            plan: plan,
            quantity: quantity,
            range: range,
            status: status,
            type: 'Support',
        })
    }
    if (page.items[tab]) {
        for (let [key, item] of Object.entries(page.items[tab])) {
            if (list[key]) {
                list[key].select = item.select
            }
        }
    }
    list = list.sort((a, b) => a.type.localeCompare(b.type))
    page.items[tab] = list
    page.counts[tab] = list.length
    self.$forceUpdate()
}

function calcTotal() {
    let list = page.items[page.tab]
    if (page.tab == Active) {
        page.total = sum(list)
    } else {
        page.total = sum(list.filter((i) => i.select))
    }
}

function getDetails(tab, account, plan) {
    let price = 0,
        range = null,
        scope = null,
        status = 'Active',
        units = 0
    let priceOptions = {period: true, upfront: true}

    if (plan.scope == 'eval') {
        planner.setPlanRange(plan, {reset: true})
    }
    let date = planner.getDue(plan)

    if (tab == Active) {
        let current = plan.current
        let start = current ? current.start : plan.start
        let end = current ? current.end : plan.end
        //  For buyout products licenses - want to show even if not selecting maintenance
        //  Show item if product current or expired in last year
        if (start && end >= Date.now() - 365 * 86400 * 1000) {
            price = current.price
            range = `${formatDate(start)} to ${formatDate(planner.getDisplayEnd(end))}`
            date = start
            scope = current.scope
            units = current.units
        }
        status = end < Date.now() ? 'Expired' : 'Active'
    } else {
        price = planner.getPrice(account, plan, priceOptions)
        range = `${formatDate(plan.start)} to ${formatDate(planner.getDisplayEnd(plan.end))}`
        scope = plan.scope
        units = plan.scope
    }
    return {date, price, range, scope, status, units}
}

async function purchase() {
    let auth = page.billing.order ? ` authorized by your purchase order ${page.billing.order}` : ''
    let msg
    if (page.billing.tax) {
        //  Total doesn't include tax here so don't confuse users with a sans-tax number
        msg = `Do you want to purchase these items${auth}?`
    } else {
        msg = `Do you want to purchase these items${auth} with a total of \$${money(page.total, 0)}?`
    }
    let approved = await confirm.value.ask(msg, {title: 'Approve Purchase', width: 650})
    if (!approved) {
        return false
    }
    page.purchasing = true
    Progress.start('dialog', `Processing Transaction...`)

    for (let item of page.items[page.tab]) {
        if (!item.select) continue
        let plan = item.plan
        if (plan) {
            plan = await Plan.update({id: plan.id, pending: false, prepay: true})
        }
    }
    if (await runBilling(page.account.id)) {
        await State.cache.update()
        prepItems()
        await updateTables()
    }
    Progress.stop()
    page.purchasing = false
    page.tab = 0
}

async function updateTables() {
    if (Array.isArray(table.value)) {
        if (table.value[0]) {
            await table.value[0].update()
        }
        if (table.value[1]) {
            await table.value[1].update()
        }
    }
}

async function removeItems() {
    if (
        !(await confirm.value.ask(`Do you want to remove the selected items?`, {
            title: 'Remove Items',
        }))
    ) {
        return false
    }
    for (let item of page.items[page.tab]) {
        if (!item.select) continue
        if (item.units == 0) continue
        let plan = item.plan
        if (plan) {
            if (plan.type == 'Cloud' || plan.type == 'Dedicated' || plan.type == 'Hosted') {
                Feedback.error('Go to Clouds to remove the device cloud')
                return
            }
            if (plan.type == 'Volume' || plan.type == 'Subscription' || plan.type == 'Buyout') {
                Feedback.error('Go to Products to remove the product')
                return
            }
            if (plan.current.units == 0) {
                await Plan.remove({id: plan.id}, {refresh: true})
            } else {
                plan = await Plan.update({id: plan.id, units: 0, pending: false})
            }
        }
    }
    Progress.start('short')
    page.removing = true
    prepItems()
    await updateTables()
    Progress.stop()
    page.removing = false
}

async function downloadQuote() {
    let {clouds, products, plans} = State.cache

    /*
        Filter the selected items and enable prepay to force inclusion in the bill
    */
    plans = clone(
        plans.filter((p) =>
            page.items[page.tab].find((i) => i.plan && i.plan.id == p.id && i.select)
        )
    )
    plans.forEach((p) => (p.prepay = true))
    let bill = planner.makeBill(page.account, clouds, products, plans, [], {
        pending: true,
        prepay: true,
    })

    bill.items.forEach((i) => delete i.plan)

    let a = document.createElement('a')
    a.download = `embedthis-quote.pdf`
    let blob
    try {
        page.downloading = true
        Progress.start('dialog', `Downloading ${a.download}`)
        let response = await Invoice.createQuote({bill})
        let arr = new Uint8Array(response.data)
        blob = new Blob([arr], {type: 'application/pdf'})
        a.href = window.URL.createObjectURL(blob)
        document.body.appendChild(a)
    } catch (err) {
        Feedback.error('Cannot download', {err})
    } finally {
        Progress.stop()
        page.downloading = false
    }
    await waitRender()
    a.click()
    a.remove()
    window.URL.revokeObjectURL(blob)
}

function cancel() {
    navigate('/account')
}

function changeTab() {
    calcTotal()
}

async function editCard(card) {
    page.showCardEdit = card ? true : false
    page.card = card
    if (!card) {
        prepItems()
        await updateTables()
        updateRefs()
        if (page.billing.cardId && !page.billing.error) {
            await purchase()
        }
    }
}

async function editDetails(invoice) {
    page.showDetails = invoice ? true : false
    if (!invoice) {
        updateRefs()
    }
}

function updateRefs() {
    page.account = account = State.auth.account
    page.billing = account.billing
    page.agreement = account.billing.agreement
}

async function clicked({action, column, field, item, items}) {
    if (action == 'select') {
        calcTotal()
    } else if (action == 'cell' && field.name == 'edit') {
        let plan = item.plan
        if (plan.type == 'Support' || plan.type == 'Developer') {
            navigate(`/support/?modify`)
        } else if (plan.type == 'Cloud' || plan.type == 'Dedicated' || plan.type == 'Hosted') {
            navigate(`/clouds/${item.id}`)
        } else {
            navigate(`/products/${item.id}`)
        }
    }
    self.$forceUpdate()
}

function formatDate(date) {
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours())
    return Dates.format(date, State.config.formats.date)
}

function sum(a) {
    return a.reduce((t, i) => t + i.amount, 0)
}

function hasItems(options = {}) {
    let now = new Date()
    //  Default to cart if there are items due in the next 4 months
    if (page.items[Cart].find((i) => i.plan.pending && i.plan.start.getTime() < now.getTime() + 4 * 28 * 86400 * 1000) == null) {
        return false
    }
    if (options.quoteable) {
        if (!page.total) {
            return false
        }
    }
    if (options.billable) {
        if (!page.billing.cardId && !page.billing.order) {
            return false
        }
    }
    if (options.selected) {
        if (page.items[Cart].find((i) => i.select) == null) {
            return false
        }
    }
    return true
}
</script>

<template>
    <div class="sheet subscription">
        <vu-help url="/doc/ui/account/billing/subscription.html" />
        <v-sheet class="pb-4" max-width="1200px" width="100%">
            <h2 class="banner">Subscription</h2>

            <vu-sign
                v-if="plans && plans.length == 0"
                name="subscription-empty"
                title="Subscriptions"
                color="accent"
                :required="true">
                <p>
                    Define your
                    <a @click="navigate('/products')">products</a>
                    add
                    <a @click="navigate('/support')">support</a>
                    and create a
                    <a @click="navigate('/clouds')">device cloud.</a>
                    Your subscription will be summarized here.
                </p>
            </vu-sign>

            <vu-sign
                name="billing-agreement"
                v-if="hasItems() && !page.demo"
                title="Approve Subscriptions"
                color="accent"
                :required="true">
                <p v-if="page.billing.wire">
                    <span v-if="!page.billing.order">
                        Please select the desired items and click
                        <a @click="downloadQuote">Download Quote</a>
                        to generate a purchase order. Then enter the purchase order number by
                        clicking the
                        <a @click="editDetails({})">Order Details</a>
                        button, then select the items and purchase.
                    </span>
                    <span v-else>Please review your cart items then select and purchase.</span>
                </p>

                <p v-else-if="!page.billing.cardId">
                    Please click
                    <a @click="editCard({})">Add Card</a>
                    to add a billing card, then select the items and complete your purchase.
                </p>

                <p v-else>
                    Please review and select items then click the
                    <b>Purchase</b>
                    button to purchase.
                </p>
            </vu-sign>

            <vu-sign
                name="subscription-issue-sign-1"
                title="Subscription Status"
                v-if="page.billing.error"
                :required="true"
                :color="page.billing.error ? 'error' : 'accent'">
                <!-- Card Supplied -->
                <v-alert type="error">Card Error: {{ page.billing.error }}</v-alert>
                <p class="description">
                    Please correct the
                    <a @click="navigate('/account/billing')">billing error.</a>
                </p>
            </vu-sign>

            <v-tabs v-model="page.tab" centered grow icons-and-text hide-slider>
                <v-tab>Current</v-tab>
                <v-tab>Cart</v-tab>
            </v-tabs>

            <v-window v-model="page.tab">
                <v-window-item v-for="t in page.tabs" :key="t">
                    <vu-sign
                        name="subscriptions"
                        class="pb-4"
                        color="none"
                        :title="TabNames[t]"
                        :required="true">
                        <label class="empty" v-if="page.counts[t] == 0 && page.ready">
                            No {{ TabNames[t] }}
                        </label>

                        <vu-table
                            v-if="page.items[t].length"
                            class="mt-0"
                            options="-"
                            ref="table"
                            width="100%"
                            sort="type:asc"
                            :data="page.items[t]"
                            :fields="prepFields(t)"
                            :header="true"
                            :required="true"
                            :pageSize="200"
                            :subtitle="
                                t == Cart ? 'Select items and click the required action below' : ''
                            "
                            :select="t == Cart ? {multi: true, property: 'select'} : null"
                            @click="clicked" />

                        <div v-if="page.items[t].length">
                            <h3 v-if="t > 0 && page.total" class="total">
                                Total: ${{ money(page.total, 2) }}
                            </h3>
                            <div v-else class="pb-5" />

                            <div v-if="t != 0">
                                <div class="approve" v-if="page.billing.wire">
                                    <label v-if="!page.billing.order" class="po">
                                        Select the desired items, enter a purchase order via Order
                                        Details, then click purchase to buy and generate an invoice.
                                    </label>
                                    <v-btn
                                        color="accent"
                                        class="mr-2"
                                        size="small"
                                        :loading="page.purchasing"
                                        :disabled="!hasItems({billable: true, selected: true})"
                                        @click="purchase">
                                        Purchase
                                    </v-btn>

                                    <v-btn
                                        class="mr-2"
                                        color="accent"
                                        size="small"
                                        :disabled="!hasItems({quoteable: true, selected: true})"
                                        :loading="page.downloading"
                                        @click="downloadQuote">
                                        Download Quote
                                    </v-btn>

                                    <v-btn
                                        size="small"
                                        class="mr-2"
                                        color="error"
                                        :loading="page.removing"
                                        :disabled="!hasItems({selected: true})"
                                        @click="removeItems">
                                        Remove Items
                                    </v-btn>

                                    <v-btn
                                        size="small"
                                        class="mr-2"
                                        :color="page.billing.order ? 'white' : 'accent'"
                                        @click="editDetails({})">
                                        Order Details
                                    </v-btn>
                                </div>
                                <div class="approve" v-else>
                                    <!-- card -->
                                    <v-btn
                                        v-if="!page.billing.cardId"
                                        size="small"
                                        color="accent"
                                        class="mr-2"
                                        @click="editCard({})">
                                        Add Card
                                    </v-btn>

                                    <v-btn
                                        size="small"
                                        color="accent"
                                        class="mr-2"
                                        :disabled="!hasItems({billable: true, selected: true})"
                                        :loading="page.purchasing"
                                        @click="purchase">
                                        Purchase
                                    </v-btn>

                                    <v-btn
                                        size="small"
                                        class="mr-2"
                                        color="error"
                                        :disabled="page.items[t].length == 0"
                                        :loading="page.removing"
                                        @click="removeItems">
                                        Remove Items
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </vu-sign>
                </v-window-item>
            </v-window>

            <vu-confirm ref="confirm" />

            <vu-panel v-model="page.showCardEdit" @close="editCard" :widths="['40%']">
                <CardEdit :id="editCard.id" @input="editCard" />
            </vu-panel>

            <vu-panel v-model="page.showDetails" @close="editDetails" :widths="['40%']">
                <InvoiceDetails @input="editDetails" source="subscription" />
            </vu-panel>
        </v-sheet>
    </div>
</template>

<style lang="scss">
.subscription {
    min-height: 900px;
    h2 {
        margin-bottom: 10px;
        color: rgb(var(--v-theme-text));
        font-weight: normal;
        font-size: 1.7rem;
        padding-bottom: 20px;
    }
    font-size: 1rem;
    a {
        text-decoration: none;
    }
    .pending,
    .current {
        h2 {
            font-size: 1.125rem;
            font-weight: normal;
        }
    }
    .approve {
        margin: 0 0 10px 0;
        .v-btn {
            background-color: white;
        }
    }
    .vu-table {
        width: 100%;
        padding: 0;
        .title {
            font-size: 1.25rem !important;
        }
    }
    .table-title {
        font-size: 1.125rem !important;
    }
    .v-alert {
        h2 {
            color: rgb(var(--v-theme-text));
            font-size: 1.7rem;
        }
    }
    .vu-sign {
        .v-card-title {
            font-size: 1.7rem !important;
        }
        .wire {
            margin-bottom: 16px;
            padding: 16px;
            font-size: 1rem;
            line-height: 1.4em;
            background: rgb(var(--v-theme-background-lighten-1));
            display: block;
            height: 100%;
            font-family: monospace;
            white-space: pre;
        }
        .review {
            font-size: 1rem;
        }
    }
    .total {
        margin-top: 10px;
        margin-bottom: 0px;
        padding: 0px !important;
        font-weight: bold;
        font-size: 1.25rem !important;
        text-align: right;
    }
    .v-tabs {
        border-bottom: 2px solid rgb(var(--v-theme-surface-light));
    }
    .v-tab {
        font-size: 1rem;
    }
    .v-tab:first-child {
        align-items: stretch;
        text-align: left;
    }
    .v-tab:last-child {
        align-items: stretch;
        text-align: right;
    }
    .v-tab--selected {
        border-bottom: 4px solid rgb(var(--v-theme-primary-lighten-2));
    }
    .empty {
        padding-bottom: 16px;
        display: flex;
    }
    .po {
        display: block;
        margin-bottom: 14px;
    }
    .expired {
        background-color: #ff0000a1 !important;
        color: white;
    }
}
.close {
    .headline {
        color: white;
    }
}
</style>
