<script setup>
/*
    Board.vue -- Display board area with widgets
 */
import {onUnmounted, onMounted, reactive, ref, watch} from 'vue'
import {Progress, State} from '@/paks/vu-app'
import {can, clone, delay, fixShadow, getValue, waitRender} from '@/paks/vu-app'

import Widgets from './Widgets.vue'
import WidgetEdit from './WidgetEdit.vue'
import ToolbarWidget from '@/paks/vu-widgets/ToolbarWidget.vue'
import Toolbox from './Toolbox.vue'
import BoardEdit from './BoardEdit.vue'

const props = defineProps({
    design: Boolean,
    modelValue: Object,
    options: {type: Object, default: {}},
    view: Object, // View from display for pages
})

const page = reactive({
    canDash: true,
    canEdit: null,
    currentRange: null,
    board: {widgets: []},
    key: null,
    mobile: State.app.mobile,
    range: {anchor: 'relative', period: 3600},
    ready: false,
    refresh: 60,
    resizing: null,
    saving: null,
    showBoardEdit: false,
    showWidgetEdit: false,
    view: {},
    widget: {},
})

const emit = defineEmits(['input', 'update:modelValue'])

//  Component references
const container = ref(null)
const widgets = ref(null)
const confirm = ref(null)
const widgetEdit = ref(null)
const toolbar = ref(null)

defineExpose({page})

watch(
    () => State.app.needs.board,
    async (v) => {
        State.app.noNeed('board')
        if (v == 'compact') {
            await render({initialize: true, compact: true, fetch: true})
            setNeedSave()
        } else if (v == 'editBoard') {
            editBoard(page.board)
        } else if (v == 'expand') {
            await render({initialize: true, expand: true, fetch: true})
            setNeedSave()
        } else if (v == 'update') {
            await render()
        }
    }
)

//  Separate because we need the widget reference
watch(
    () => State.app.needs.editWidget,
    (v) => {
        if (v) {
            State.app.noNeed('editWidget')
            editWidget(v)
        }
    }
)

watch(
    () => props.modelValue,
    async () => {
        page.board = clone(props.modelValue)
        page.view = clone(props.view)
        await render({initialize: true, layout: true, wait: true})
    },
    {deep: true}
)

onMounted(async () => {
    page.board = clone(props.modelValue)
    page.view = clone(props.view)
    window.addEventListener('resize', resizeWindow)
    page.ready = true
    daemon()
})

onUnmounted(() => {
    page.ready = false
    window.removeEventListener('resize', resizeWindow)
    fixShadow()
})

async function render(params = {}) {
    if (params.progress !== false) {
        Progress.start('repeat')
    }
    State.app.noNeed('board')
    if (params.initialize) {
        params.wait = true
        await waitRender()
    }
    if (!widgets.value && params.wait !== false) {
        await waitRender()
    }
    page.canEdit = can('admin') && State.config.features.dash?.edit

    /*
        Give preference to board setting, route setting, config (from product.json5 & display.timeouts)
     */
    let board = page.board
    page.refresh = getValue(
        board.refresh || page.view?.refresh || State.config?.timeouts?.dashboard || 60
    )
    board.remaining = page.refresh
    Object.assign(page.range, board.range)
    page.currentRange = clone(page.range)

    if (container.value) {
        for (let {name, value} of board.css) {
            container.value.style[name] = value
        }
    }
    if (widgets.value) {
        await widgets.value.update(params)
    }
    if (toolbar.value) {
        await toolbar.value.update(params)
    }
    if (params.progress !== false) {
        Progress.stop()
    }
}

async function resizeWindow() {
    if (!page.resizing) {
        page.resizing = setTimeout(async () => {
            if (widgets.value) {
                await render({initialize: true, fetch: true, progress: false, wait: true})
            }
            page.resizing = null
        }, 500)
    }
}

async function daemon() {
    //  First time, initialize widgets, then fetch only. Expand if using a new widget set.
    let params = {initialize: true, expand: props.options?.expand}

    while (page.ready) {
        while (page.board.remaining > 0) {
            await delay(1000)
            if (page.board.remaining > 0) {
                page.board.remaining--
            }
        }
        if (State.auth.ready && (page.board.live || params.initialize)) {
            await render(params)
        }
        page.board.remaining = page.refresh
        params = {}
    }
}

async function editBoard(board) {
    page.showBoardEdit = board ? true : false
    if (board === undefined) {
        //  Closing edit
        State.app.setNeed('board', 'reload')
    } else if (board) {
        board.range = page.range
        page.board = Object.assign({}, board)
    }
}

/*
    Control the widget edit panel. Widget is set for open, null for close.
 */
async function editWidget(widget) {
    page.showWidgetEdit = widget ? true : false
    if (widget) {
        if (!widget.id) {
            let cloudId = State.app.prefer('cloudId')
            widget = {type: 'graph', css: [], range: {}, cloudId, anchor: {}}
            if (!widget.cloudId && State.config.builder) {
                widget.cloudId = 'builder'
            }
        }
        //  Open panel
        page.widget = widget
    } else if (widget !== null) {
        //  FUTURE - should do this if the widget css changes
        page.widget = {value: null}
        // page.key = !page.key
        setNeedSave()
    }
}

async function applyWidget(widget) {
    let board = page.board
    if (widget) {
        let index = board.widgets.findIndex((v) => v.id == widget.id)
        if (index >= 0) {
            board.widgets[index] = widget
        } else {
            createWidget(board, widget)
        }
    }
    await render({initialize: true})
    setNeedSave()
}

async function removeWidget(widget) {
    let index = page.board.widgets.findIndex((w) => w.id == widget.id)
    if (index >= 0) {
        page.board.widgets.splice(index, 1)
    }
    await render({initialize: true})
    setNeedSave()
}

function canSave() {
    return can('admin') || State.auth.orgName == 'personal'
}

function setNeedSave() {
    if (canSave() && page.ready) {
        State.app.setNeed('saveDash')
    }
}

//  Called for widget resize, move
async function updateWidgets(list) {
    let board = page.board
    for (let widget of list) {
        let index = board.widgets.findIndex((v) => v.id == widget.id)
        if (index >= 0) {
            board.widgets.splice(index, 1, widget)
        } else {
            createWidget(board, widget)
        }
    }
    await render({initialize: true, fetch: true})
    State.app.setNeed('saveDash')
}

//  Private Functions

function createWidget(board, widget, show = true) {
    let dv = board.widgets.find((v) => v.id == widget.id)
    if (!dv) {
        board.widgets.push(widget)
    }
}

async function confirmCloseWidgetEdit(discard) {
    let widget = widgetEdit.value?.page?.widget
    if (discard && widget && !widget.id && (widget.css.length || widget.model || widget.metric)) {
        if (await confirm.value.ask(`Do you want to discard changes? `)) {
            return true
        }
        return false
    }
    return true
}
</script>

<template>
    <div class="board">
        <Toolbox
            v-if="props.design && !page.board.full"
            :dashboard="page.board"
            :container="container" />
        <div class="board-region">
            <ToolbarWidget
                v-if="page.ready && props.design"
                ref="toolbar"
                class="board-toolbar"
                :class="`${page.board.full ? 'full' : ''}`"
                :board="page.board"
                :state="State" />

            <div
                class="board-container"
                :key="page.key"
                :class="`${page.board.full ? 'full' : ''} ${
                    props.design ? 'design' : 'fixed'
                } ${page.board.emulate == 'Mobile' ? 'emulate-mobile' : ''} ${
                    page.board.layout == 'grid' && page.board.snap && props.design
                        ? 'grid'
                        : ''
                } ${State.app.dark ? 'dark' : 'light'} `"
                fill-height
                ref="container">
                <Widgets
                    v-if="page.ready"
                    class="board-widgets"
                    :dashboard="page.board"
                    :design="props.design"
                    :layout="page.board.layout"
                    :range="page.range"
                    @remove="removeWidget"
                    @edit="editWidget"
                    @update="updateWidgets"
                    ref="widgets" />
            </div>

            <vu-panel
                v-model="page.showWidgetEdit"
                :confirm="confirmCloseWidgetEdit"
                @close="editWidget(null)"
                :widths="['600px']"
                :keyboard="false">
                <WidgetEdit
                    ref="widgetEdit"
                    v-model="page.widget"
                    :dashboard="page.board"
                    @input="editWidget"
                    @apply="applyWidget"
                    @remove="removeWidget" />
            </vu-panel>

            <vu-panel
                v-model="page.showBoardEdit"
                @close="editBoard(null)"
                :widths="['650px']"
                :keyboard="false">
                <BoardEdit :board="page.board" :view="page.view" @input="editBoard" />
            </vu-panel>

            <div v-if="!page.ready">
                <div class="loading">loading ...</div>
            </div>
            <vu-confirm ref="confirm" />
        </div>
    </div>
</template>

<style lang="scss">
.board {
    display: flex;
    flex-direction: row;
    .board-region {
        width: 100%;

        .board-toolbar {
            background: rgb(var(--v-theme-background-lighten-1)) !important;
            border-bottom: 1px solid rgb(var(--v-theme-border-lighten-1));
            padding: 14px 32px 6px 20px;
            width: 100%;
            @media (max-width: 640px) {
                padding-left: 20px;
            }
        }
        .board-toolbar.full {
            padding-left: 48px;
        }
        .board-container {
            background: rgb(var(--v-theme-background));
            width: 100%;
            height: 100%;
            max-width: none;
            padding: 20px;
        }
        .board-container.grid.light {
            background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
                linear-gradient(180deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
            background-size: 20px 20px;
            background-repeat: repeat;
        }
        .board-container.grid.dark {
            background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
                linear-gradient(180deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
            background-size: 20px 20px;
            background-repeat: repeat;
        }
        .board-container.emulate-mobile.design:not(.full) {
            width: 430px;
            height: 932px;
            position: absolute;
            _left: 282px;
        }
        .board-container.emulate-mobile:not(.design):not(.full) {
            width: 430px;
            height: 932px;
            position: absolute;
            left: 160px;
        }
        .board-container.emulate-mobile.full {
            width: 430px;
            height: 932px;
            position: absolute;
            left: 0;
        }
        .board-container.full.fixed {
            padding: 0 !important;
        }
        .board-container.full.design {
            padding: 20px 0 0 20px !important;
        }
        .board-widgets {
            padding: 0;
            margin: 0px;
            --base-width: 100%;
            width: round(down, var(--base-width), 20px);
        }
        .dispatcher {
            width: 100%;
        }
        .v-progress-circular__content {
            font-size: 12px;
        }
    }
}
</style>
