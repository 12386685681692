<script setup>
import {onMounted, reactive, ref} from 'vue'
import {onBeforeRouteLeave} from 'vue-router'
import {Feedback, State, can, navigate} from '@/paks/vu-app'
import AccountEdit from './AccountEdit.vue'
import AccountAdd from './AccountAdd.vue'
import Dates from '@/paks/js-dates'
import {Account, User} from '@/models'

const EmailSubject = `Need Assistance with EmbedThis Ioto`
const EmailFeedback = `Hi,

I know you are probably very busy, so I’ll keep this really brief.

You recently tried our EmbedThis Ioto, would you like a follow up call or email?

We're happy to help answer any questions or do a Zoom product demo.

Let me know if this would help.

Kind regards
`

const AccountFields = [
    {name: 'edit', icon: true},
    {name: 'Gist', icon: '$support'},
    {name: 'Assume', icon: '$login'},
    {name: 'name'},
    {name: 'id'},
    {name: 'email'},
    {name: 'metrics.emails.initial', title: 'Emailed'},
    {name: 'activity'},
    {name: 'created', format: (v) => Dates.format(v, 'mmm dd yyyy')},
    {name: 'metrics.agents', title: 'Agents', format: (v) => v.join(', ')},
    {name: 'metrics.clouds', title: 'Clouds', align: 'right'},
    {name: 'metrics.products', title: 'Products', align: 'right'},
    {name: 'metrics.users', title: 'Users', align: 'right'},
    {name: 'billing.error'},
    {name: 'suspended'},
    {name: 'enabled', icon: 'check'},
]

const page = reactive({
    account: {},
    accounts: [],
    invoice: {},
    invoices: [],
    ready: false,
    receivable: 0,
    select: {actions: {Add: 0, Edit: 1, Delete: 2}, multi: true, property: 'select'},
    showAccount: false,
    showAddAccount: false,
    showInvoice: false,
})

//  Component References
const add = ref(null)
const confirm = ref(null)
const accounts = ref(null)

onMounted(async () => {
    if (!can('support')) {
        navigate('/')
        return
    }
    await prepAccounts()
    page.ready = true
})

onBeforeRouteLeave(() => confirmCloseAdd(true))

async function prepAccounts() {
    page.accounts = await Account.find({}, {index: 'gs1'})
    page.accounts.forEach((a) => (a.enabled = !a.suspended))
}

/*
async function getAccounts(args) {
    args.index = 'gs1'
    page.accounts.forEach((a) => (a.enabled = !a.suspended))
    return page.accounts
} */

async function addAccount(account) {
    page.showAddAccount = account ? true : false
    page.account = account
    if (!account) {
        accounts.value.update()
    }
}

async function confirmCloseAdd(discard) {
    let account = add.value?.account || {}
    if (discard && account.id == null && account.name) {
        if (await confirm.value.ask(`Do you want to discard changes? `)) {
            return true
        }
        return false
    }
    return true
}

async function accountClick({item, rec, column}) {
    if (column.name == 'Gist') {
        let data = await User.lookupUser({email: item.email})
        if (!data) {
            Feedback.error('Cannot find email')
        } else {
            let id = data.id
            window.open(`https://app.getgist.com/projects/ndcwj9n2/contacts/${id}`, '_blank')
        }
    } else if (column.name == 'Assume') {
        let url = `${window.location.origin}?assume=${rec.email}`
        window.open(url, '_blank')
    } else if (column.name == 'email') {
        let subject = EmailSubject.replace(/ /g, '%20')
        let body = EmailFeedback.replace(/ /gm, '%20').replace(/\n/g, '%0D%0A')
        let url = `mailto:${rec.email}?subject=${subject}&body=${body}`
        window.open(url, '_blank')
        await Account.updateMetrics({
            id: item.id,
            metrics: {emails: {initial: new Date()}},
        })
    } else if (column.name == 'id') {
        launchSenseDeep(rec.id)
    } else {
        editAccount(rec)
    }
}

function launchSenseDeep(accountId) {
    let query = {
        filters: [],
        index: 'primary',
        indexes: ['primary', 'gs1'],
        operation: 'Begins with',
        limit: 100,
        run: true,
        tab: 'Query',
        name: '',
        schema: 'Current',
        hash: `account#${accountId}`,
        type: 'Query',
    }
    let path = 'tables/browse/' + window.btoa(JSON.stringify(query))
    let prof = State.config.profile == 'prod' ? '' : 'qa.'
    let url = `https://${prof}app.sensedeep.com/${path}`
    console.log('Launch', url)
    window.open(url, '_blank')
}

async function editAccount(account) {
    page.showAccount = account ? true : false
    page.account = account || {}
    if (!account) {
        await prepAccounts()
        await accounts.value.update()
    }
}
</script>

<template>
    <div class="page accounts">
        <vu-confirm ref="confirm" />
        <div v-if="page.ready">
            <vu-table
                title="Accounts"
                class="pt-4"
                options="dynamic,filter,refilter,toolbar"
                ref="accounts"
                sort="activity:desc"
                width="100%"
                :data="page.accounts"
                :fields="AccountFields"
                :pageSize="25"
                @click="accountClick">
                <template v-slot:table-col-edit="props">
                    <v-icon icon="$edit" size="24" @click.stop="editAccount(props.item)" />
                </template>
                <template v-slot:more="props">
                    <v-btn size="small" color="accent" @click="addAccount({})">Add Account</v-btn>
                </template>
            </vu-table>
        </div>

        <vu-panel v-model="page.showAccount" @close="editAccount" :widths="['600px']">
            <AccountEdit :arg="page.account" @input="editAccount" />
        </vu-panel>

        <vu-panel
            v-model="page.showAddAccount"
            @close="addAccount"
            :widths="['900px']"
            :confirm="confirmCloseAdd">
            <AccountAdd @input="addAccount" ref="add" />
        </vu-panel>
    </div>
</template>

<style lang="scss">
.accounts {
    .v-input--switch {
        .v-input__slot {
            width: auto;
        }
    }
    .action {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border-lighten-1));
        background: none !important;
        box-shadow: none !important;
    }
    .metric {
        margin: 20px 0 20px 0;
        font-size: 1.25rem;
    }
}
</style>
