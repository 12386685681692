<script setup>
import {onMounted, reactive, ref} from 'vue'
import {State, can, money, navigate} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'
import {Account, Invoice} from '@/models'
import InvoiceEdit from './InvoiceEdit.vue'

const EmailSubject = `Can I please get some feedback on EmbedThis Builder`
const EmailFeedback = `Hi,

I know you are probably very busy, so I’ll keep this really brief.

You recently tried our EmbedThis Builder, would you like a follow up call or email?

We're happy to help answer any questions or do a Zoom product demo.

Let me know if this would help.

Kind regards
`
const InvoiceFields = [
    {name: 'edit', icon: true},
    {name: 'Assume', icon: '$login'},
    {name: 'closed', title: 'Complete', icon: 'tick'},
    {name: 'name'},
    {name: 'accountId', title: 'id'},
    {name: 'email'},
    {name: 'date', format: (v) => Dates.format(v, 'mmm dd yyyy')},
    {name: 'due', format: (v) => Dates.format(v, 'mmm dd yyyy')},
    {name: 'renew', format: (v) => Dates.format(v, 'mmm dd yyyy')},
    {name: 'total', title: 'Amount', align: 'right', format: (v) => `\$${money(v, 0)}`},
    {name: 'paid', align: 'right', format: (v) => `\$${money(v, 0)}`},
    {name: 'outstanding', align: 'right', format: (v) => `\$${money(v, 0)}`},
    {name: 'description'},
    {name: 'number', align: 'right'},
    {name: 'reference', align: 'right'},
]

const page = reactive({
    account: {},
    filter: 'open',
    invoice: {},
    invoices: [],
    ready: false,
    receivable: 0,
    select: {actions: {Add: 0, Edit: 1, Delete: 2}, multi: true, property: 'select'},
    showAccount: false,
    showInvoice: false,
})

//  Component References
const confirm = ref(null)
const invoices = ref(null)

onMounted(async () => {
    if (!can('support')) {
        navigate('/')
        return
    }
    page.ready = true
})

async function getInvoices(args) {
    /* FUTURE - should have "status" field
    let props = {}
    if (page.filter == 'open') {
        args.where = '${status} <> {closed}'
    } else if (page.filter == 'closed') {
        props.status = 'closed'
    } */
    let accounts = await Account.find({}, {index: 'gs1'})
    let list = await Invoice.find({}, {index: 'gs1'})
    let receivable = 0
    let invoices = []
    //MOB - should filter invoices at fetch above -- perhaps have closed flag
    for (let invoice of list) {
        let account = accounts.find((a) => a.id == invoice.accountId)
        if (account) {
            if (account.email == 'support@embedthis.com') continue
            invoice.accountId = account.id
            invoice.name = account.name
            invoice.email = account.email
            invoice.closed = invoice.total - invoice.paid < 50 ? true : false
            invoice.reference = invoice.accountId.slice(-6)
            invoice.renew = account.billing.renew
            invoice.outstanding = invoice.total - invoice.paid
            if (invoice.paid < invoice.total) {
                receivable += invoice.outstanding
            }

            invoices.push(invoice)
        }
    }
    page.receivable = receivable
    invoices = invoices.filter((i) => {
        let closed = i.total - i.paid < 50 ? true : false
        if (page.filter == 'all') return true
        if (page.filter == 'open' && !closed) return true
        if (page.filter == 'closed' && closed) return true
        return false
    })
    /*
    for (let invoice of invoices) {
        let account = accounts.find((a) => a.id == invoice.accountId)

        let due = invoice.due.getTime()
        if (account.billing.renew) {
            let item = invoice.items.find((i) => i?.plan?.type == 'Buyout')
            if (item && item.plan.period == 'year') {
                if (due < (account.billing.renew.getTime() - 365 * 86400 * 1000)) {
                    due = new Date(Math.max(due, account.billing.renew.getTime() - 365 * 86400 * 1000 + account.billing.terms * 86400 * 1000))
                    console.log(`REVISE DUE from ${invoice.due} to ${due} for ${account.email}`)
                }
            }
        }
    } */
    return invoices
}

async function filter(pattern) {
    page.filter = pattern
    if (invoices.value) {
        await invoices.value.update()
    }
}

function dayOfYear(date) {
    return (
        (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
            Date.UTC(date.getFullYear(), 0, 0)) /
        24 /
        60 /
        60 /
        1000
    )
}

async function invoiceClick({item, rec, column}) {
    if (column.name == 'accountId') {
        launchSenseDeep(item.accountId)
    } else if (column.name == 'Assume') {
        let url = `${window.location.origin}?assume=${item.email}`
        window.open(url, '_blank')
    } else if (column.name == 'email') {
        let subject = EmailSubject.replace(/ /g, '%20')
        let body = EmailFeedback.replace(/ /gm, '%20').replace(/\n/g, '%0D%0A')
        let url = `mailto:${rec.email}?subject=${subject}&body=${body}`
        window.open(url, '_blank')
        await Account.updateMetrics({
            id: item.accountId,
            metrics: {emails: {initial: new Date()}},
        })
    } else {
        editInvoice(item)
    }
}

function launchSenseDeep(accountId) {
    let query = {
        filters: [],
        index: 'primary',
        indexes: ['primary', 'gs1'],
        operation: 'Begins with',
        limit: 100,
        run: true,
        tab: 'Query',
        name: '',
        schema: 'Current',
        hash: `account#${accountId}`,
        type: 'Query',
    }
    let path = 'tables/browse/' + window.btoa(JSON.stringify(query))
    let prof = State.config.profile == 'prod' ? '' : 'qa.'
    let url = `https://${prof}app.sensedeep.com/${path}`
    console.log('Launch', url)
    window.open(url, '_blank')
}

async function editInvoice(invoice) {
    page.showInvoice = invoice ? true : false
    page.invoice = invoice || {}
    if (!invoice) {
        invoices.value.update()
    }
}
</script>

<template>
    <div class="page invoices">
        <vu-confirm ref="confirm" />
        <div v-if="page.ready">
            <vu-table
                title="Invoices"
                class="pt-4"
                options="dynamic,filter,refilter,toolbar"
                sort="due:asc"
                ref="invoices"
                width="100%"
                :data="getInvoices"
                :fields="InvoiceFields"
                @click="invoiceClick">
                <template v-slot:more="props">
                    <v-btn outlined class="action mr-4">
                        Receivable: ${{ money(page.receivable, 0) }}
                    </v-btn>
                    <v-btn class="action" @click="filter('all')">All</v-btn>
                    <v-btn class="action" @click="filter('open')">Open</v-btn>
                    <v-btn class="action" @click="filter('closed')">Complete</v-btn>
                </template>
            </vu-table>
        </div>

        <vu-panel v-model="page.showInvoice" @close="editInvoice" :widths="['600px']">
            <InvoiceEdit :arg="page.invoice" @input="editInvoice" />
        </vu-panel>
    </div>
</template>

<style lang="scss">
.invoices {
    .v-input--switch {
        .v-input__slot {
            width: auto;
        }
    }
    .action {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border-lighten-1));
        background: none !important;
        box-shadow: none !important;
    }
    .metric {
        margin: 20px 0 20px 0;
        font-size: 1.25rem;
    }
}
</style>
