<script setup>
import {onBeforeMount, reactive, ref, watch} from 'vue'
import {Feedback, Progress, State, can, delay, getRoute} from '@/paks/vu-app'
import {arrayBufferToBase64} from '@/paks/js-base64'
import {Manager} from '@/models'
import Json5 from 'json5'
import Zip from 'jszip'

const MaxCss = 256 * 1024
const MaxDisplay = 256 * 1024
const MaxImage = 256 * 1024
const Versions = [
    {title: 'latest', value: 'latest'},
    {title: 'beta', value: 'beta'},
    {title: '1.7.0', value: '1.7.0'},
    /*
    {title: '1.6.0', value: '1.6.0'},
    {title: '1.5.0', value: '1.5.0'},
    {title: '1.4.0', value: '1.4.0'},
    {title: '1.3.0', value: '1.3.0'},
    */
]
const ReservedDomains = [
    'appweb',
    'goahead',
    'ioto',
    'embedthis',
    'manage',
    'eval',
    'demo',
    'temp',
    'test',
    'scratch',
    'device',
    'home',
    'work',
    'my',
    'control',
    'monitor',
    'dashboard',
    'api',
    'config',
    'status',
    'update',
    'logs',
    'settings',
    'support',
    'analytics',
    'security',
    'gateway',
    'sensor',
    'events',
]
const MimeTypes = {
    avif: 'image/avif',
    js: 'application/javascript',
    css: 'text/css',
    heif: 'image/heif',
    html: 'text/html',
    htm: 'text/html',
    ico: 'image/x-icon',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    json: 'application/json',
    map: 'application/json',
    mjs: 'application/javascript',
    pdf: 'application/pdf',
    png: 'image/png',
    txt: 'text/plain',
    webp: 'image/webp',
    xml: 'application/xml',
}
const DomainRules = [
    (v) => !!v || 'Missing domain',
    (v) =>
        (v &&
            (can('support') ||
                State.auth.email.indexOf('@embedthis.com') >= 0 ||
                ReservedDomains.indexOf(v) < 0)) ||
        'Reserved domain',
    (v) => (v && v.length >= (can('support') ? 2 : 4)) || 'Domain name too short',
    (v) => /^[a-z0-9.\-]+$/.test(v) || 'Bad format for domain name',
]
const props = defineProps({id: String})
const emit = defineEmits(['input'])

const page = reactive({
    clearCss: null,
    clearDisplay: null,
    clearLogo: null,
    cloud: {},
    clouds: [],
    css: null,
    customApp: false,
    customDomain: false,
    display: null,
    domain: '',
    files: {
        app: null,
        css: null,
        display: null,
        logo: null,
    },
    id: null,
    logo: null,
    manager: {cloudId: null},
    managerDomain: State.config.managerDomain,
    managerPanel: true,
    priorDomain: null,
    removing: false,
    rules: [],
    saving: false,
    versions: Versions,
})

const route = getRoute()
const confirm = ref(null)
const form = ref(null)

defineExpose({page, removing: page.removing, saving: page.saving})

watch(
    () => page.manager.cloudId,
    () => changeCloud()
)
watch(() => page.domain, changeDomain)

onBeforeMount(async () => {
    let id = (page.id = props.id || route.params.id)
    let manager
    let clouds = State.cache.clouds.filter((c) => c.type == 'hosted' || c.type == 'dedicated')
    clouds = clouds.filter((c) => c.id != State.config.evalCloud)
    page.clouds = clouds.map((c) => {
        return {title: `${c.name} (${c.region})`, value: c.id}
    })
    if (id) {
        manager = page.manager = State.get('Manager', id)
        page.cloud = State.get('Cloud', manager.cloudId)
    } else {
        manager = Object.assign(page.manager, {
            region: State.app.region || (can('support') ? 'ap-southeast-1' : 'us-east-1'),
        })
        if (clouds.length) {
            page.cloud = clouds[0]
            manager.cloudId = page.cloud.id
        }
    }
    page.priorDomain = manager.domain
    if (manager.domain == null || manager.domain.indexOf(State.config.managerDomain) >= 0) {
        page.domain = manager.domain
            ? manager.domain.replace(`.${State.config.managerDomain}`, '')
            : null
    } else {
        page.domain = manager.domain
        page.customDomain = true
    }
    if (page.manager.logo && typeof page.manager.logo != 'string') {
        page.manager.logo = null
    }
    page.customApp = manager.app ? true : false
    manager.version = manager.version || page.versions.at(0).value || 'latest'
    if (!manager.owner) {
        manager.owner = State.auth.email
    }
})

async function preSave(validate) {
    let {files, manager} = page
    if (!manager.name) {
        await validate.fieldError(form.value, 'name', 'Missing manager name')
        return false
    }
    if (!manager.id && State.cache.managers.find((m) => m.name == manager.name)) {
        await validate.fieldError(form.value, 'name', 'An existing manager has the same name')
        return false
    }
    if (!page.domain) {
        await validate.fieldError(form.value, 'domain', 'Must define manager domain')
        return false
    }
    if (page.customDomain) {
        manager.domain = page.domain.toLowerCase()
    } else {
        manager.domain = page.domain.toLowerCase() + '.' + State.config.managerDomain
    }
    if (!manager.id && !(await Manager.checkDomain({domain: manager.domain}))) {
        await validate.fieldError(form.value, 'domain', 'Domain name already taken')
        return false
    }
    if (page.customApp) {
        if (!manager.app && !files.app?.size) {
            await validate.fieldError(form.value, 'app', 'Must upload app image')
            return false
        }
    }
    if (page.clearCss) {
        manager.css = null
        files.css = null
    }
    if (page.clearLogo) {
        manager.logo = null
        files.logo = null
    }
    if (page.clearDisplay) {
        manager.display = null
        files.display = null
    }
    if (!manager.owner) {
        manager.owner = State.auth.email
    }
    return true
}

async function save(validate) {
    let {files, manager} = page
    page.saving = true
    let domain = (
        page.customDomain ? page.domain : page.domain + '.' + State.config.managerDomain
    ).toLowerCase()
    let params = {
        id: manager.id,
        app: manager.app,
        cloudId: manager.cloudId,
        css: manager.css,
        display: manager.display,
        domain,
        logo: manager.logo,
        name: manager?.name?.trim(),
        owner: manager?.owner?.trim(),
        region: page.cloud.region,
        title: manager?.title?.trim() || null,
        version: manager.version != 'latest' ? manager.version : null,
    }
    if (page.customApp) {
        if (files.app?.name) {
            params.app = files.app.name
        }
        params.css = null
        params.logo = null
        params.display = null
    } else {
        if (files.css?.name) {
            params.css = files.css.name
        }
        if (files.display?.name) {
            params.display = files.display.name
        }
        if (files.logo?.name) {
            params.logo = `images/${files.logo.name}`
        }
        params.app = null
    }

    if (manager.id && page.priorDomain && page.priorDomain != manager.domain) {
        params.priorDomain = page.priorDomain
    }
    Progress.start('dialog', 'Updating App', {bar: true, duration: 30 * 1000})
    if (!params.id) {
        page.manager = await Manager.create(params)
    } else {
        page.manager = await Manager.update(params)
    }
    if (page.manager.version == null) {
        page.manager.version = 'latest'
    }
    validate.clear()
}

async function postSave(error, val) {
    let {files, manager} = page
    if (!error) {
        Progress.start('dialog', 'Provisioning App', {
            bar: true,
            hint: 'Please wait a few minutes',
            duration: 300 * 1000,
            cancel: cancelProgress,
        })
        manager = await provision(val)
        if (files.app) {
            await uploadApp(val)
        }
    }
    Progress.stop()
    if (!error && !manager.error) {
        await confirm.value.ask(`Please wait 5 to 30 minutes for changes to propagate. `, {
            title: 'Please Note',
            disagree: false,
        })
        emit('input')
    }
    page.saving = false
}

async function provision(val) {
    let {files, manager} = page
    Progress.message('Provisioning App', 'Please wait a few minutes')
    let prior = manager.provisioned || new Date()
    let params = {id: manager.id, assets: {}}
    if (files.css) {
        params.assets.css = await getAsset(files.css, MaxCss)
    }
    if (files.display) {
        params.assets.display = await getAsset(files.display, MaxDisplay)
    }
    if (files.logo) {
        params.assets.logo = await getAsset(files.logo, MaxImage)
    }
    manager = await Manager.provision(params)
    let deadline = Date.now() + 3 * 60 * 1000
    while (Date.now() < deadline) {
        if (manager.provisioned > prior) {
            val.clear()
            await State.cache.update()
            break
        }
        if (manager.error) {
            val.error(manager.error)
            throw new Error('Cannot provision')
        }
        await delay(2000)
        manager = await Manager.get({id: manager.id}, {refresh: true, throw: false})
    }
    if (manager.provisioned == prior) {
        throw new Error('Timeout waiting for Provisioning')
    }
    return manager
}

async function uploadApp(val) {
    let {files, manager} = page
    let zip = new Zip()
    let list = await zip.loadAsync(files.app)
    let clean = true
    for (let [filename, file] of Object.entries(list.files)) {
        if (file.dir) continue
        let mimeType = getMimeType(filename)
        let url = await Manager.getSignedUrl({
            id: manager.id,
            filename,
            mimeType,
            clean,
            command: 'put',
        })
        clean = false
        let data = await file.async('blob')

        Progress.message('Uploading', filename)
        try {
            let response = await fetch(url, {
                method: 'PUT',
                body: data,
                headers: {'Content-Type': mimeType},
            })
            console.log(`upload ${filename}, ${mimeType}`)
            if (response.status != 200) {
                console.log(filename)
                console.error(response)
                throw new Error(`Cannot upload ${filename}`)
            }
        } catch (err) {
            Progress.stop()
            throw err
        }
    }
    //  Trigger an invalidation
    await Manager.getSignedUrl({id: manager.id})
}

function getMimeType(file) {
    let ext = file.match(/\.([0-9a-zA-Z]+)+$/i)
    if (!ext) {
        return 'application/octet-stream'
    }
    return MimeTypes[ext[1]]
}

async function removeApp() {
    let {manager} = page

    if (!(await confirm.value.ask(`Do you want to delete the app "${page.manager.name}"? `))) {
        return
    }
    page.removing = true
    try {
        Progress.start('dialog', 'Removing App', {
            hint: 'Please wait a few minutes',
            bar: true,
            duration: 300 * 1000,
            cancel: cancelProgress,
        })
        //  Remove is async and non-blocking. Does not remove immediately.
        await Manager.remove({id: manager.id})

        let deadline = Date.now() + 5 * 60 * 1000
        while (Date.now() < deadline) {
            manager = await Manager.get({id: manager.id}, {throw: false, refresh: true})
            if (!manager) {
                await State.cache.update()
                break
            }
            if (manager.error || page.manager.error) {
                throw new Error(manager.error)
            }
            await delay(5000)
            await State.cache.update()
        }
        await confirm.value.ask(
            `If you want to re-create the app, please wait ten minutes to allow previous resources to be fully removed.`,
            {title: 'Please Note', disagree: false}
        )
        Feedback.info('App Deleted')
        emit('input')
    } catch (err) {
        Feedback.error('Cannot delete app')
    } finally {
        Progress.stop()
        page.removing = false
    }
}

async function cancelProgress() {
    if (
        confirm.value &&
        !(await confirm.value.ask(
            `<h2 class="mb-3">Your app may be in a partial state.</h2>
            <p>You may re-save or delete the app to complete the operation.</p>`,
            {disagree: false, title: 'App Operation Cancelled'}
        ))
    ) {
        return
    }
    page.saving = false
}

async function launchApp(prop) {
    let manager = page.manager
    if (manager[prop]) {
        let url = `https://${manager[prop]}`
        window.open(url, '_blank')
    }
}

function changeCloud() {
    let {manager} = page
    page.cloud = State.get('Cloud', manager.cloudId)
}

async function changeDomain(value) {
    if (!page.customDomain && page.domain) {
        let domain = value.toLowerCase() + '.' + State.config.managerDomain
        if (domain != page.manager.domain) {
            let ok = await Manager.checkDomain({domain})
            let validate = form.value.validate
            if (!ok) {
                await validate.fieldError(form.value, 'domain', 'Domain name is already used')
            } else {
                validate.clear()
            }
        }
    }
}

async function getAsset(file, max, format = '') {
    if (!file) {
        throw new Error('Missing upload')
    }
    if (file.size > max) {
        throw new Error(`Asset ${file.name} is bigger than max ${max / 1024 / 1024} MB`)
    }
    let reader = new FileReader()
    reader.readAsArrayBuffer(file)

    let data = await new Promise((resolve, reject) => {
        reader.onload = async () => {
            let data = reader.result
            if (format == 'json') {
                try {
                    //  Validate as Json5 and convert to JSON
                    let string = String.fromCharCode.apply(null, new Uint8Array(data))
                    data = Json5.parse(string)
                    data = JSON.stringify(data, null, 4)
                } catch (err) {
                    reject(`${file.name} contains invalid JSON`)
                }
            } else {
                data = arrayBufferToBase64(data)
            }
            resolve(data)
        }
    })
    return {name: file.name, size: file.size, data}
}
</script>

<template>
    <vu-form
        :data="page"
        :save="save"
        :pre-save="preSave"
        :post-save="postSave"
        :title="`${props.id ? 'Modify' : 'Add'} App`"
        class="app-edit"
        help="/doc/ui/apps/edit.html"
        ref="form">
        <vu-sign
            name="app-edit-sign-1"
            title="App Configuration"
            subtitle="Configure Device App"
            color="accent">
            <p>
                Select a subdomain of the
                <b>ioto.me</b>
                cloud to host your app.
                <span v-if="page.cloud.type == 'dedicated'">
                    Or use your own registered domain by selecting
                    <b>Custom Domain.</b>
                </span>
            </p>
            <p>
                You can customize the app by changing the domain name, title, uploading a
                <a href="https://www.embedthis.com/builder/doc/manager/logo/" target="_blank">
                    logo
                </a>
                or an alternate
                <a href="https://www.embedthis.com/builder/doc/manager/app/">code base.</a>
            </p>
        </vu-sign>

        <v-alert
            v-if="page.manager.error"
            icon="$error"
            class="error vcol-12"
            :value="true"
            type="error"
            dismissible>
            <div>{{ page.manager.error }}</div>
        </v-alert>

        <vu-input
            type="text"
            label="Name"
            v-model="page.manager.name"
            cols="6"
            :rules="page.rules.name" />
        <vu-input
            class="ml-2"
            type="select"
            label="Cloud"
            v-model="page.manager.cloudId"
            :disabled="page.manager.id ? true : false"
            :items="page.clouds"
            :rules="page.rules.name"
            cols="6" />

        <vu-input
            v-if="page.cloud.type == 'dedicated'"
            v-model="page.customDomain"
            class="mr-2"
            cols="6"
            label="Custom Domain"
            hide-details="auto"
            type="checkbox" />
        <vu-input
            v-model="page.domain"
            cols="6"
            label="Domain Name"
            name="domain"
            type="text"
            :suffix="page.customDomain ? '' : `.${page.managerDomain}`"
            :rules="DomainRules" />

        <vu-input
            v-if="page.manager.id"
            class=""
            type="text"
            label="ID"
            v-model="page.manager.id"
            :disabled="page.manager.id ? true : false"
            cols="12" />
        <vu-input
            v-model="page.customApp"
            label="App Code Base"
            type="radio"
            cols="12"
            name="custom"
            :items="{Standard: false, 'Custom App': true}" />

        <vu-div v-if="page.customApp">
            <vu-input
                v-model="page.files.app"
                class="mb-4"
                accept="application/zip"
                variant="underlined"
                hide-details="auto"
                label="App Image Upload"
                name="app"
                show-size
                type="file"
                :clearable="true"
                :multiple="false"
                :placeholder="`ZIP File ${page.manager.app ? `(${page.manager.app})` : ''}`" />
        </vu-div>
        <vu-div v-else>
            <vu-input
                v-model="page.manager.version"
                class="select-fix"
                label="Version"
                name="version"
                type="select"
                :items="page.versions" />
            <vu-input
                v-model="page.manager.owner"
                label="Owner Email"
                name="owner"
                placeholder="Email Address"
                type="text"
                clearable />

            <!--
            <vu-input
                v-model="page.manager.title"
                label="App Title"
                name="title"
                placeholder="Short App Title"
                type="text"
                clearable />

            <div class="vrow">
                <vu-input
                    v-model="page.css"
                    label="Upload CSS Stylesheet"
                    type="checkbox"
                    cols="3"
                    hide-details />
                <vu-input
                    v-if="!page.css && page.manager.css"
                    v-model="page.clearCss"
                    :label="`Clear CSS (${page.manager.css})`"
                    type="checkbox"
                    hide-details="auto"
                    variation="compact"
                    cols="6" />
            </div>
            <vu-input
                v-if="page.css"
                v-model="page.files.css"
                accept="text/css"
                class="file-input mb-4"
                density="compact"
                hide-details="auto"
                label="CSS Stylesheet"
                name="css"
                show-size
                type="file"
                :clearable="true"
                placeholder="CSS Stylesheet"
                :multiple="false" />

            <div class="vrow">
                <vu-input
                    v-model="page.logo"
                    label="Upload Logo"
                    type="checkbox"
                    cols="3"
                    hide-details />
                <vu-input
                    v-if="!page.logo && page.manager.logo"
                    v-model="page.clearLogo"
                    :label="`Clear Logo (${page.manager.logo?.replace('images/', '')})`"
                    type="checkbox"
                    hide-details="auto"
                    variation="compact"
                    cols="6" />
            </div>
            <vu-input
                v-if="page.logo"
                v-model="page.files.logo"
                accept="image/*"
                class="file-input mb-4"
                density="compact"
                hide-details="auto"
                label="App Logo"
                name="logo"
                show-size
                type="file"
                :clearable="true"
                placeholder="Small, square transparent PNG image"
                :multiple="false" />
            -->
            <div class="vrow">
                <vu-input
                    v-model="page.display"
                    label="Upload Display"
                    type="checkbox"
                    cols="3"
                    hide-details />
                <vu-input
                    v-if="!page.display && page.manager.display"
                    v-model="page.clearDisplay"
                    class="mt-1"
                    :label="`Clear Display (${page.manager.display})`"
                    type="checkbox"
                    hide-details="auto"
                    variation="compact"
                    cols="3" />
            </div>
            <vu-input
                v-if="page.display"
                v-model="page.files.display"
                accept="*"
                cols="12"
                class="file-input mb-4"
                density="compact"
                hide-details="auto"
                name="display"
                show-size
                type="file"
                :clearable="true"
                placeholder="Display.json5"
                :multiple="false" />
        </vu-div>
        <div class="actions">
            <v-btn color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn color="none" @click="emit('input')">Cancel</v-btn>
            <v-btn
                color="error"
                v-if="page.manager.id && can('admin')"
                @click="removeApp"
                :loading="page.removing">
                Delete
            </v-btn>
            <v-btn v-if="page.manager.domain" dark color="teal" @click="launchApp('domain')">
                Launch App
            </v-btn>
        </div>

        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
.app-edit {
    label.options {
        margin-top: 16px;
        padding-bottom: 8px;
        display: block;
        font-size: 1.125rem;
        color: rgb(var(--v-theme-text-darken-1));
    }
    .v-alert.error {
        border: none;
        margin-bottom: 16px;
        h2 {
            color: white;
        }
        a {
            color: white;
            font-weight: bold;
        }
    }
    .v-alert.keys {
        font-size: 1rem;
        margin-top: 14px;
        margin-bottom: 0;
    }
    .v-expansion-panel {
        .v-expansion-panel-title {
            color: rgb(var(--v-theme-text));
            font-weight: normal;
            font-size: 1.2rem;
        }
        .v-expansion-panel-text {
            margin-top: 8px;
        }
    }
    .nolabel {
        padding-top: 0 !important;
    }
    .v-btn {
        margin-left: 0;
    }
    .token {
        font-size: 0.8rem;
        font-weight: bold;
        cursor: pointer;
    }
    .actions {
        margin-top: 20px;
    }
    .summary {
        margin: 20px 0 30px 0;
        p {
            margin-bottom: 12px;
        }
    }
    .expando {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .select-fix {
        z-index: 208;
    }
    .v-file-input {
        .v-field--active {
            .v-field-label {
                display: none !important;
            }
        }
    }
    .claim {
        margin: 20px 0 30px 0;
        p {
            margin-bottom: 12px;
        }
    }
}
</style>
