<script setup>
import {onUnmounted, onMounted, reactive, watch} from 'vue'
import {State} from '@/paks/vu-app'
import {can, clone, fixShadow, getModels, getRoute} from '@/paks/vu-app'
import Board from './Board.vue'
import UUID from '@/paks/js-uuid'

const props = defineProps({
    refresh: Number,
})

const page = reactive({
    dashboard: null,
    design: null,
    options: {expand: false},
})

//  Component references
const {Dashboard} = getModels()

defineExpose({page})

watch(
    () => State.app.needs.board,
    async (v) => {
        if (v == 'reload') {
            await reload()
        }
    }
)

onMounted(async () => {
    if (!State.auth.ready) {
        return
    }
    await reload()
})

onUnmounted(() => {
    fixShadow()
})

async function reload() {
    let dashboard = clone(await Dashboard.getCurrent())
    dashboard.type = 'dashboard'

    page.design = getRoute().query?.design ? true : false

    let initial = State.cache.dashboard == null ? true : false
    if (initial) {
        createInitialWidgets(dashboard)
    }

    /*
        Don't show dashboard on builder if no clouds available
        MOB - should allow some builder metrics (products, support, ...)
     */
    if (
        State.config.builder &&
        (!can('support') || State.app.mock) &&
        State.cache.clouds.filter((c) => c.type != 'host' && c.id != State.config.evalCloud)
            .length == 0
    ) {
        dashboard = null
    }
    page.dashboard = dashboard
}

function createInitialWidgets(dashboard) {
    if (State.config.name == 'builder' && dashboard.widgets.length == 0) {
        if (State.app.widgetSets) {
            let set = State.app.widgetSets.find((s) => s.name == 'Default')
            if (set) {
                dashboard.widgets = set.widgets
            }
        } else {
            dashboard.widgets = []
        }
        dashboard.widgets.forEach((w) => {
            w.range = w.range || {}
            w.anchor = w.anchor || {}
            w.css = w.css || []
            w.id = UUID()
        })
        page.options.expand = true
        State.app.setNeed('saveDash')
    }
}
</script>

<template>
    <Board
        v-if="page.dashboard"
        v-model="page.dashboard"
        :design="page.design"
        :options="page.options"
        :type="'dashboard'" />
</template>
