<script setup>
import {onMounted, reactive, ref, watch} from 'vue'
import {State, can, getModels, navigate} from '@/paks/vu-app'
import BoardEdit from './BoardEdit.vue'
import ExportDash from './ExportDash.vue'
import ImportDash from './ImportDash.vue'

const BuilderFields = [
    {name: 'edit', icon: '$edit', width: '5%'},
    {name: 'designx', title: 'Design', icon: '$dashboard', width: '5%'},
    {name: 'show', icon: '$launch', width: '5%'},
    {name: 'name'},
    {name: 'layout'},
    {name: 'framed'},
    {name: 'full'},
]

const ManagerFields = [
    {name: 'edit', icon: '$edit', width: '5%'},
    {name: 'designx', title: 'Design', icon: '$dashboard', width: '5%'},
    {name: 'show', icon: '$launch', width: '5%'},
    {name: 'name'},
    {name: 'type'},
    {name: 'path'},
]

const page = reactive({
    builder: State.config.builder,
    fields: State.config.builder ? BuilderFields : ManagerFields,
    helper: null,
    showBoardEdit: false,
    showImport: false,
    showExport: false,
})

const table = ref(null)
const {Dashboard} = getModels()

watch(
    () => State.app.needs.board,
    async (v) => {
        if (table.value) {
            await table.value.update()
        }
    }
)

onMounted(() => {
    page.select = {
        actions: {Add: 0, Edit: 1, Delete: 2, Export: 1, Import: 0},
        multi: true,
        property: 'select',
    }
})
async function getData() {
    return State.cache.dashboards
}

async function clicked({action, item, items, column, rec, row}) {
    if (action == 'add') {
        if (!can('admin')) {
            return
        }
        editBoard({})
        await reload()
    } else if (action == 'import') {
        editImport({})
    } else if (action == 'export') {
        editExport(item)
    } else if (action == 'edit' || (action == 'cell' && column.name == 'edit')) {
        if (can('admin')) {
            editBoard(item)
            await reload()
        }
    } else if (action == 'cell') {
        if (column.name == 'designx') {
            await Dashboard.set(item)
            State.app.setNeed('board', 'reload')
            navigate('/dashboards/list/editor', {design: true})
        } else {
            await Dashboard.set(item)
            State.app.setNeed('board', 'reload')
            navigate('/')
        }
    }
}

async function editBoard(dashboard) {
    page.showBoardEdit = dashboard ? true : false
    if (dashboard) {
        page.dashboard = Object.assign({}, dashboard)
    } else {
        page.dashboard = State.cache.dashboard
    }
    reload()
}

async function editImport(dashboard) {
    page.dashboard = dashboard
    page.showImport = dashboard ? true : false
}

async function editExport(dashboard) {
    page.dashboard = dashboard
    page.showExport = dashboard ? true : false
}

async function reload() {
    if (table.value) {
        await table.value.update()
    }
}
</script>

<template>
    <div class="dashboards page">
        <vu-help url="/doc/ui/dashboard/dashboard-list.html" />
        <vu-table
            :data="getData"
            :fields="page.fields"
            :select="page.select"
            name="dashboards"
            nodata=" "
            options="dynamic,refilter,toolbar"
            ref="table"
            sort="name:asc"
            width="100%"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn v-if="can('admin')" color="accent" @click="editBoard({})">Add</v-btn>
            </template>
        </vu-table>

        <vu-panel
            v-model="page.showBoardEdit"
            :keyboard="false"
            :widths="['550px']"
            @close="editBoard">
            <BoardEdit :board="page.dashboard" @input="editBoard" />
        </vu-panel>
        <vu-panel
            v-model="page.showImport"
            :keyboard="false"
            :widths="['550px']"
            @close="editImport">
            <ImportDash @input="editImport" />
        </vu-panel>
        <vu-panel
            v-model="page.showExport"
            :keyboard="false"
            :widths="['550px']"
            @close="editExport">
            <ExportDash :dashboard="page.dashboard" @input="editExport" />
        </vu-panel>
    </div>
</template>
