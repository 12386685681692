/*
    Planner -- Ioto plans
 */

import blend from '@/paks/js-blend'
import {allow, toTitle} from '@/paks/js-polyfill'

const DeveloperLifespan = 2

export default class Plan {
    constructor(billing, log) {
        this.billing = billing
        this.plans = billing.plans
        this.log = log || this
    }

    /*
        Note: lambda always runs in UTC timezone
        The browser which can host this code runs in local time
     */
    initPlan(def) {
        let plans = this.plans
        if (!plans[def.type]) {
            throw new Error('Unknown plan type')
        }
        let plan
        if (def.fixed) {
            plan = def
        } else {
            plan = blend(
                {},
                def,
                allow(plans[def.type], 'advance', 'align', 'pricing'),
                def.override || {}
            )
            delete plan.pricing
            if (plan.type == 'Dedicated' || plan.type == 'Hosted') {
                plan.period = def.period || 'month'
            } else {
                plan.period = def.period || 'year'
            }
            if (plan.type == 'Volume') {
                plan.align = true
                plan.advance = true
                plan.period = 'year'
            } else if (def.align != null) {
                plan.align = def.align
            }
            plan.pending = def.pending != null ? def.pending : true
            if (plan.type == 'Hosted' || plan.type == 'Dedicated') {
                if (!plan.units) {
                    plan.units = 1
                }
            }
        }
        this.setPlanRange(plan)
        return plan
    }

    setPlanRange(plan, options = {reset: false}) {
        if (options.reset) {
            plan.start = plan.end = null
        }
        if (plan.type == 'Developer') {
            plan.start = new Date()
        } else if (plan.current && plan.current.end) {
            let now = Date.now()
            let gap = now - plan.current.end
            //  Abut plan if current expired less than 2 days ago
            if (gap < 2 * 86400 * 1000) {
                plan.start = plan.current.end
                plan.end = null
            }
            //  Ioto plan expired more than one month ago -- reset
            if (gap > 86400 * 30 * 1000 && plan.agent == 'Ioto') {
                plan.end = plan.start = null
            }
        }
        if (!plan.start) {
            plan.start = new Date()
        } else {
            plan.start = new Date(plan.start)
        }
        if (!plan.end) {
            plan.end = this.getEnd(plan)
        } else {
            plan.end = new Date(plan.end)
        }
        return plan
    }

    getUpfront(def) {
        let plan = blend({}, def, this.plans[def.type], def.override || {})
        if (def.align != null) {
            plan.align = def.align
        }
        let price = 0
        let upfront = plan.upfront
        if (Array.isArray(upfront) && upfront.length > 0) {
            if (typeof upfront[0] == 'number') {
                price = plan.upfront[0]
            } else {
                let item = plan.upfront.find((s) => s.name == plan.scope)
                if (item) {
                    price = item.price
                }
            }
            let upfrontPaid = plan.current.upfront || 0
            if (plan.current.scope) {
                let item = plan.upfront.find((s) => s.name == plan.current.scope)
                if (item) {
                    upfrontPaid = item.price
                }
            }
            price -= upfrontPaid
            if (price < 0) {
                price = 0
            }
        }
        return parseInt(price)
    }

    getPrice(account, def, options = {period: false, upfront: false, stock: false}) {
        let plan = blend({}, def, this.plans[def.type], def.override || {})
        if (def.align != null) {
            plan.align = def.align
        }
        let pricing = plan.pricing
        let price = 0
        let units = plan.units
        if (plan.type == 'Volume') {
            units = Math.max(plan.units, plan.count)
            plan.period = 'year'
        }
        if (units == 0) {
            return 0
        }
        if (plan.type == 'Hosted' || plan.type == 'Dedicated') {
            //  Minimum of 1
            units = units || 1
        } else if (plan.type == 'Subscription' || plan.type == 'Buyout') {
            units = 1
        } else if (plan.type == 'Volume') {
            if (plan.period == 'month') {
                units *= 12
            }
        }
        if (account.billing.agreement && plan.type == 'Support') {
            plan.align = false
        }
        if (Array.isArray(pricing)) {
            if (plan.sliding) {
                for (let index = 0; units > 0; index++) {
                    let thisBand = pricing[index].units
                    if (index > 0) {
                        thisBand -= pricing[index - 1].units
                    }
                    let count = pricing[index + 1] ? Math.min(units, thisBand) : units
                    price += pricing[index].price * count
                    units -= count
                }
            } else {
                if (plan.type == 'Developer' || plan.type == 'Support') {
                    price = pricing[0].price * units
                } else {
                    let item = pricing.find((p) => p.name == plan.scope)
                    if (item) {
                        price = item.price * units
                    }
                }
            }
        } else {
            price = parseInt(pricing)
        }
        if (plan.flat) {
            price += plan.flat || 0
        }
        price = this.applyDiscount(plan, price)
        if (options.period && plan.type != 'Developer') {
            price = this.perPeriod(plan, price)
        }
        if (options.upfront) {
            if (plan.type != 'Volume' || plan.units > 1) {
                price += this.getUpfront(plan)
            }
        }
        price = (Math.round(price * 10) / 10).toFixed(2) - 0
        let current = plan.current
        if (current.units && !options.stock) {
            if (plan.type == 'Volume' || plan.type == 'Buyout' || plan.type == 'Subscription') {
                //  Plan overlaps current
                if (current.end > plan.start.getTime() + 86400 * 7 * 1000) {
                    price = Math.max(0, price - current.price - current.upfront)
                }
            }
        }
        return price
    }

    applyDiscount(plan, price) {
        if (plan.period == 'month' && (plan.type == 'Support' || plan.type == 'Subscription')) {
            //  Discount is applied in reverse i.e. increase monthly price by reverse discount.
            price = price * (1 / this.billing.yearDiscount)
        } else if (plan.discount) {
            price = Math.round(price * (1 - plan.discount))
        }
        return price
    }

    perPeriod(plan, price) {
        if (plan.type == 'Developer') {
            return price
        }
        if (parseInt(plan.period) == plan.period) {
            price = (price / 365) * plan.period
        } else if (plan.period == 'year') {
            let days = (plan.end - plan.start) / (86400 * 1000)
            //  Look after leap years
            days = Math.round(days / 5) * 5
            price = (price / 365) * days
        } else if (plan.period == 'month') {
            //  Need to use year price / 12 rather than month days which vary from 28-31
            let days = Math.round((plan.end - plan.start) / (86400 * 1000))
            price = (price / 365) * days
        } else {
            let days = Math.round((plan.end - plan.start) / (86400 * 1000))
            price = (price / 365) * days
        }
        price = Math.round(price + 0.49).toFixed(0) - 0
        return price
    }

    /*
        Make reminders for:
        - A plan's renewal is

        Note:
        - Reminders only for (basic) Support, Buyout and Subscriptions.
        - No reminders for cloud, Ioto or Developer support.
        - No reminders for monthly renewals
     */
    makeReminders(account, plans, options = {}) {
        let items = []
        let now = new Date()

        for (let plan of plans) {
            if (this.needRemind(account, plan, options)) {
                let message
                if (plan.type == 'Support') {
                    message = 'Basic support subscription.'
                } else if (plan.type == 'Buyout') {
                    message = `Renew ${plan.agent} ${plan.scope} license.`
                } else if (plan.scope) {
                    message = `Renew ${plan.agent} subscription for ${plan.scope} license.`
                } else {
                    message = `Renew ${plan.agent} subscription.`
                }
                let due = this.getDue(plan)
                let days = (due - now) / (86400 * 1000)
                this.log.info(`Make reminder for ${account.email} plan ${plan.type}`, {
                    account,
                    plan,
                    options,
                    days,
                    due,
                })

                if (-1 < days && days < 1) {
                    //  Dont remind on day due - otherwise we get reminders while user is purchasing
                    this.log.info(`Don't issue reminder today: ${account.email}`, {
                        plan,
                        account,
                        days,
                    })
                    continue
                }
                let period = this.daysToWords(days)
                items.push({type: 'renewal', message, period, plan})
            }
        }
        return items
    }

    /*
        Determine if reminders are needed. Reminders are issued for: 
        2 months, 1 month, 1 week, 1 day, -1 day, -7 days, -30 days, -60 days, -90 days, -180 days
        Dry run will not set nextReminder
     */
    needRemind(account, plan, options = {}) {
        let due = this.getDue(plan)
        let now = new Date()
        let daysRemainingTillDue = Math.floor((due - now) / (86400 * 1000))

        //  Don't remind about support - it has its own natural forcing function

        if (plan.type != 'Buyout' && plan.type != 'Subscription') {
            // this.log.info(`Dont need remind for this plan type ${plan.type}: ${account.email}`)
            return false
        }
        if (plan.period == 'month') {
            // this.log.info(`Dont need remind for monthly plan: ${account.email}`)
            return false
        }
        if (plan.scope == 'eval') {
            return false
        }
        if (plan.pending && !plan.current.start && !account.billing.agreement) {
            return false
        }
        if (plan.nextReminder && plan.nextReminder > now) {
            if (!options.quiet) {
                this.log.debug(
                    `Account ${account.email} for plan ${plan.type}, ${
                        plan.agent
                    } does not need remind till ${plan.nextReminder.toLocaleString()}`,
                    {plan}
                )
            }
            return false
        }
        if (plan.lastBilled && now - plan.lastBilled < 14 * 86400 * 1000) {
            if (!options.quiet) {
                this.log.info(
                    `Skip reminder for ${account.name} ${plan.type}, ${plan.agent} due to recent purchase: ${account.email}`,
                    {plan}
                )
            }
            return false
        }
        if (!options.quiet) {
            this.log.debug(
                `Account ${account.email} plan ${plan.type}, ${plan.agent} is due in ${daysRemainingTillDue} days`,
                {
                    plan,
                    due,
                    now,
                }
            )
        }

        //  Determine if the user has crossed the next reminder threshold
        let reminders = options.reminders || [56, 28, 7, 1, -1, -7, -30, -60, -90, -180]
        let index
        for (index = 0; index < reminders.length; index++) {
            if (daysRemainingTillDue > reminders[index]) break
        }
        if (0 < index && index < reminders.length) {
            if (!options.dryRun) {
                if (daysRemainingTillDue > 0) {
                    //  Before due
                    plan.nextReminder = new Date(due.getTime() - reminders[index] * 86400 * 1000)
                    if (!options.quiet) {
                        this.log.info(
                            `Account ${account.email} ${plan.type} set next reminder ${plan.nextReminder}`,
                            {plan}
                        )
                    }
                } else {
                    //  Past due (-1, -7, -30, -365)
                    plan.nextReminder = new Date(due.getTime() - reminders[index] * 86400 * 1000)
                    if (!options.quiet) {
                        this.log.info(
                            `Account ${account.email} ${plan.type} set next reminder ${plan.nextReminder}`,
                            {plan}
                        )
                    }
                }
            }
            if (options.exact) {
                //  Used for renewalReview to match boundary exactly so we don't get repeat reminders
                if (Math.abs(daysRemainingTillDue - reminders[index]) <= 1) {
                    return true
                }
            } else {
                return true
            }
        }
        if (!options.quiet) {
            this.log.debug(
                `No need to remind ${account.email} for ${plan.type},${
                    plan.agent
                } until ${due.toLocaleString()}`,
                {index, reminders, plan}
            )
        }
        return false
    }

    /*
        Return a bill which contains items: date, end, item, description, memo, amount, quantity, plan
        Bill items that iff all the following are true:
            - Not pending
            - Have units > 0
            - Are due or prepay is true
     */
    makeBill(
        account,
        clouds,
        products,
        plans,
        invoices = [],
        options = {pending: false, zero: false}
    ) {
        let billing = account.billing
        let total = 0
        let items = []
        let priceOptions = {period: true}
        let now = new Date()

        for (let plan of plans) {
            let amount = 0

            if (plan.pending && !options.pending) {
                //  Skip plans requiring customer confirmation unless explicitly wanted (quotes and summaries)
                continue
            }
            let upfront = this.getUpfront(plan)

            if (upfront == 0 && plan.units == 0 && !options.zero) {
                continue
            }
            //  Use prepay to compute bills ahead of time
            if (this.getDue(plan) > now && !(plan.prepay && options.prepay)) {
                //  Plan not due and the user is not prepaying
                continue
            }
            if (upfront > 0) {
                let agent = plan.agent ? `${plan.agent}` : ''
                let scope = plan.scope ? ` ${plan.scope}` : ''
                items.push({
                    date: plan.start,
                    end: plan.end,
                    item: `${plan.agent} License`,
                    description: `Upfront ${agent}${scope} fee`,
                    memo: plan.memo,
                    amount: upfront,
                    quantity: 1,
                })
                amount += upfront
            }

            let term = this.getTerm(plan)
            let price = this.getPrice(account, plan, priceOptions)

            if (billing.tax && !billing.showTax) {
                price += this.getTax(price, account)
            }

            if (plan.type == 'Support') {
                items.push({
                    date: plan.start,
                    end: plan.end,
                    item: `Basic Support`,
                    description: 'Basic support subscription',
                    memo: plan.memo,
                    amount: price,
                    quantity: 1,
                    plan,
                })
                amount += price
            } else if (plan.type == 'Developer') {
                let noun = plan.units == 1 ? 'hour' : 'hours'
                items.push({
                    date: plan.start,
                    end: plan.end,
                    item: `Developer Support`,
                    description: `Developer Support for ${plan.units} ${noun}`,
                    memo: plan.memo,
                    amount: price,
                    quantity: plan.units,
                    plan,
                })
                amount += price
            } else if (plan.type == 'Cloud' || plan.type == 'Dedicated' || plan.type == 'Hosted') {
                let cloud = clouds.find((c) => c.planId == plan.id)
                if (cloud) {
                    items.push({
                        date: plan.start,
                        end: plan.end,
                        item: `Ioto Device Cloud`,
                        description: `Monthly device cloud subscription ${cloud.name} in ${cloud.region}`,
                        memo: plan.memo,
                        amount: price,
                        quantity: 1,
                        plan,
                    })
                    amount += price
                }
            } else if (plan.type == 'Volume') {
                let product = products.find((p) => p.planId == plan.id)
                if (product) {
                    let noun = plan.units == 1 ? 'device' : 'devices'
                    items.push({
                        date: plan.start,
                        end: plan.end,
                        item: `${plan.agent} Subscription`,
                        description: `${toTitle(term)} volume subscription for ${
                            plan.units
                        } ${noun} by ${product.name}`,
                        memo: plan.memo,
                        amount: price,
                        quantity: 1,
                        plan,
                    })
                    amount += price
                }
            } else if (plan.type == 'Buyout') {
                let product = products.find((p) => p.planId == plan.id)
                if (product) {
                    items.push({
                        date: plan.start,
                        end: plan.end,
                        item: `${plan.agent} Maintenance`,
                        description: `${toTitle(term)} ${plan.agent} ${
                            plan.scope
                        } maintenance for ${product.name}`,
                        memo: plan.memo,
                        amount: price,
                        quantity: 1,
                        plan,
                    })
                    amount += price
                }
            } else if (plan.type == 'Subscription') {
                let product = products.find((p) => p.planId == plan.id)
                if (product) {
                    items.push({
                        date: plan.start,
                        end: plan.end,
                        item: `${plan.agent} Subscription`,
                        description: `${toTitle(term)} ${plan.agent} ${
                            plan.scope
                        } subscription for ${product.name}`,
                        memo: plan.memo,
                        amount: price,
                        quantity: 1,
                        plan,
                    })
                    amount += price
                }
            }
            if (amount) {
                plan.billed = true
            }
            total += amount
        }

        /*
            Outstanding amounts for partially paid invoices. Only levy if there are other items in the bill.
            i.e. don't issue a bill where this is the only item.
         */
        if (invoices.length && total > 0) {
            let outstanding = invoices
                .filter((i) => i.paid > 0)
                .reduce((t, i) => t + i.total - i.paid, 0)
            if (outstanding > 0) {
                items.push({
                    item: 'Balance',
                    description: 'Outstanding due from partially paid invoices',
                    amount: outstanding,
                    quantity: 1,
                })
                total += outstanding
            }
        }

        /*
            Adjustments
        */
        if (account.adjustments) {
            for (let adj of account.adjustments) {
                items.push({
                    item: adj.item,
                    description: adj.description,
                    amount: adj.amount,
                    quantity: 1,
                })
                total += adj.amount
            }
        }

        /*
            Apply credit
        */
        let credit = billing.credit
        let remaining
        if (credit > 0) {
            let apply = 0
            if (credit > total) {
                apply = total
                total = 0
                remaining = billing.credit - apply
            } else {
                apply = credit
                total -= apply
                remaining = 0
            }
            items.push({
                item: 'Credit',
                description: 'Account credit',
                total: -apply,
                quantity: 1,
            })
        }

        /*
            Tax and Total
            let country = (account.billing.country || '').toLowerCase()
            let taxRate = Config.billing.tax[country] || 0
            let tax = (Math.round(bill.total * taxRate * 10) / 10).toFixed(2) - 0
        */
        let tax = 0,
            grand = 0
        if (billing.tax && billing.showTax) {
            // let country = (account.billing.country || '').toLowerCase()
            // let taxRate = Config.billing.tax[country] || 0
            let taxRate = billing.tax
            tax = (Math.round(total * taxRate * 10) / 10).toFixed(2) - 0
        }
        if (items.length) {
            total = total.toFixed(2) - 0
            if (tax && billing.showTax) {
                items.push({item: 'Tax', description: '', amount: tax})
            }
            items.push({item: '', description: 'Total', total})
            grand += total + tax
        }
        return {amount: total, credit, items, tax, total: grand}
    }

    getTax(amount, account) {
        let billing = account.billing
        let tax = 0,
            grand = 0
        if (billing.tax) {
            // let country = (account.billing.country || '').toLowerCase()
            // let taxRate = Config.billing.tax[country] || 0
            let taxRate = billing.tax
            tax = (Math.round(amount * taxRate * 10) / 10).toFixed(2) - 0
        }
        return tax
    }

    afterBill(account, plans, bill) {
        let {billing} = account
        let now = new Date()

        account.adjustments = []
        if (bill.credit) {
            billing.credit -= bill.credit
        }

        for (let plan of plans) {
            if (plan.billed) {
                let upfront = this.getUpfront(plan)
                let price = this.getPrice(account, plan, {period: true})
                let current = plan.current

                if (upfront) {
                    plan.current.upfront += upfront
                }
                if (plan.type == 'Developer') {
                    plan.purchases = plan.purchases || []
                    plan.purchases.push({
                        units: plan.units,
                        expired: 0,
                        date: now,
                    })
                    plan.units += plan.current.units || 0
                }
                //  Add to current if current is still active
                let start = current.end > Date.now() ? current.start : plan.start
                plan.current = {
                    count: plan.count,
                    end: plan.end,
                    license: plan.license,
                    period: plan.period,
                    price: price + upfront,
                    purchases: plan.purchases,
                    scope: plan.scope,
                    start: start,
                    upfront,
                    units: plan.units,
                }
                //  Fixup for agreement accounts that start with yearly support (unaligned) and switch to monthly
                if (plan.type == 'Support' && plan.period == 'month') {
                    plan.align = true
                }
                plan.lastBilled = new Date()
                plan.prepay = false

                //  Agreement accounts automatically have next years plan set to pending
                if (plan.type == 'Buyout' || account.billing.agreement) {
                    plan.pending = true
                } else {
                    plan.pending = false
                }
                if (plan.type == 'Dedicated' || plan.type == 'Hosted') {
                    //  Reset the count as they are based on a count of connecting devices per month.
                    plan.count = 0
                } else if (plan.type == 'Developer') {
                    //  Support remains active until units are set to zero.
                    plan.units = 0
                } else if (plan.type == 'Volume') {
                    //  Volume licenses
                    plan.units = Math.max(plan.count, plan.units)
                }
                this.setPlanRange(plan, {reset: true})
            }
        }
        if (billing.agreement) {
            let now = Date.now()
            let renew = new Date(2050, 1, 1)
            for (let plan of plans) {
                if (plan.type == 'Buyout' && plan.current) {
                    renew = Math.min(plan.current?.end || now, renew)
                }
            }
            if (renew != new Date(billing.renew)) {
                billing.renew = new Date(renew)
            }
        }
        billing.order = null
    }

    expireDeveloperHours(plan, account) {
        let now = new Date()
        try {
            if (plan.purchases) {
                let active = plan.purchases.filter((p) => !p.expired)
                for (let purchase of active) {
                    let date = new Date(purchase.date)
                    let age = (now - date) / (86400 * 1000)
                    if (!purchase.expired) {
                        if (age >= this.plans.Developer.lifespan) {
                            purchase.expired = purchase.units
                            this.log.info(
                                `Account ${account.email} ${plan.type} expiring ${purchase.units} developer hours`,
                                {plan}
                            )
                        } else {
                            this.log.info(
                                `Account ${account.email} ${plan.type} will expire ${
                                    purchase.units - purchase.expired
                                } developer hours on ${new Date(
                                    date.getTime() + (this.plans.Developer.lifespan * 86400 * 1000)
                                )}`,
                                {plan}
                            )
                        }
                    }
                }
                let hours = active.reduce((t, p) => t + p.units, 0)
                this.log.info(
                    `@@ Account ${account.email} has ${hours} unexpired hours vs ${plan.current.units} current units`,
                    {active, plan, account}
                )
                if (hours < plan.current.units) {
                    this.log.info(
                        `Account ${account.email} reducing developer hours from ${plan.current.units} to ${hours}`,
                        {plan, active}
                    )
                    plan.current.units = hours
                }
            } else {
                if (plan.current.units) {
                    this.log.info(
                        `@@ Account ${account.email} NO PURCHAES BUT units ${plan.current.units}`,
                        {account, plan}
                    )
                }
            }
        } catch (err) {
            this.log.error('EXCEPTION', err)
        }
    }

    getEnd(plan) {
        let end
        //  Fixup for agreement accounts that start with yearly support (unaligned) and switch to monthly
        if (plan.type == 'Support' && plan.period == 'month') {
            plan.align = true
        }
        if (plan.align) {
            let start = this.alignDate(plan.start)
            //  Get aligned start of next month
            if (plan.period == 'month') {
                end = new Date(Date.UTC(start.getFullYear(), start.getMonth() + 1))
            } else if (plan.period == 'year') {
                if (plan.type == 'Buyout') {
                    end = new Date(Date.UTC(start.getFullYear() + 1, start.getMonth()))
                } else if (plan.type == 'Developer') {
                    //  2 years
                    end = new Date(Date.UTC(start.getFullYear() + DeveloperLifespan, 0))
                } else {
                    end = new Date(Date.UTC(start.getFullYear() + 1, 0))
                }
            } else {
                end = new Date(Date.UTC(start.getFullYear() + 1, 0))
            }
        } else {
            let start = plan.start
            if (plan.type == 'Developer') {
                end = new Date(
                    start.getFullYear() + DeveloperLifespan,
                    start.getMonth(),
                    start.getDate()
                )
            } else if (plan.period == 'month') {
                end = new Date(
                    start.getFullYear(),
                    start.getMonth() + 1,
                    start.getDate(),
                    start.getHours(),
                    start.getMinutes()
                )
            } else if (parseInt(plan.period) == plan.period) {
                let period = parseInt(plan.period)
                if (period >= 365) {
                    let years = Math.round(period / 365)
                    end = new Date(
                        start.getFullYear() + years,
                        start.getMonth(),
                        start.getDate(),
                        start.getHours(),
                        start.getMinutes()
                    )
                } else if (period > 28) {
                    let months = Math.round(period / 28)
                    end = new Date(
                        start.getFullYear(),
                        start.getMonth() + months,
                        start.getDate(),
                        start.getHours(),
                        start.getMinutes()
                    )
                }
            } else {
                //  year
                end = new Date(
                    start.getFullYear() + 1,
                    start.getMonth(),
                    start.getDate(),
                    start.getHours(),
                    start.getMinutes()
                )
            }
        }
        return end
    }

    getDisplayEnd(end) {
        return new Date(end.getFullYear(), end.getMonth(), end.getDate() - 1)
    }

    getDue(plan) {
        return plan.advance ? plan.start : plan.end
    }

    /*
        Align a date to the start of next month. 
        Used to calculate a reasonable next period (getEnd() and in CloudEdit/ProductEdit)
        If less than 4 days before the end of the month, align with next month.
    */
    alignDate(when) {
        when = new Date(when.getTime() + 4 * 86400 * 1000)
        return new Date(Date.UTC(when.getFullYear(), when.getMonth()))
    }

    /*
        Get when the account next has an item that is due for renewal
    */
    getPlanNextDue(plans) {
        let now = Date.now()
        let due = new Date(now + 5 * 365 * 86400 * 1000)
        for (let plan of plans) {
            due = Math.min(due, this.getDue(plan))
        }
        return new Date(due)
    }

    getTerm(plan) {
        if (plan.period == 'month') {
            return 'Monthly'
        } else if (plan.period == 'year') {
            return 'One year'
        } else if (parseInt(plan.period) == plan.period) {
            let period = parseInt(plan.period)
            let years = Math.round(period / 365)
            let months = Math.round(period / 28)
            if (years > 1) {
                return `${years} years`
            } else if (years > 0) {
                return 'One year'
            } else if (months > 1) {
                return `${months} months`
            } else if (months > 0) {
                return 'One Month'
            } else {
                return 'One Year'
            }
        }
    }

    dayOfYear(date) {
        return (
            (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
                Date.UTC(date.getFullYear(), 0, 0)) /
            86400 /
            1000
        )
    }

    getPeriod(days, max, suffix) {
        let units = Math.round(days / max)
        return `${units} ${suffix}${units == 1 ? '' : 's'}`
    }

    daysToWords(days) {
        let period
        if (days >= 28) {
            period = this.getPeriod(days, 28, 'month')
        } else if (days >= 7) {
            period = this.getPeriod(days, 7, 'week')
        } else if (days >= 1) {
            period = this.getPeriod(days, 1, 'day')
        } else {
            period = this.getPeriod(-days, 1, 'day')
        }
        return period
    }

    error(message, context = {}) {
        console.error('error', message, context)
    }

    info(message, context = {}) {
        console.log('info', message, context)
    }

    trace(message, context = {}) {
        console.log('trace', message, context)
    }
}
