<script setup>
/*
    <table
        action="action-clicked"
        aligns="left right - - center"
        callbacks="{format, sort}"
        click="row-clicked-fn"
        data="array|function"
        drag="move, remove"
        fields="name, name, ..."
        fields="[{name, title, width, style, css, align, ...}]
        header="false"
        id="4573"
        mobile="auto, true, false"
        name="table-name"
        options="dynamic, filter, refilter, toolbar"
            # Dynamic - show toolbar if > 10 items
            # Filter - show filter box
            # Toolbar - show toolbar
            # Refilter data locally after get
        pageSize="25"
        persist="false"
        pivot="field-name"
        ref="table"
        select="{
            mobile: true        # Allow select on mobile
            multi: true         # Allow select all
            property: 'name'    # Selection property name, defaults to 'select'
            actions: {
                add: 0,
                edit: 1,
                delete: 2,
                ...
            }
        }
        sort="name:dir:fixed" or "true" (fixed means always sort by this name/dir)
        title="optional-title"
        width="1000px"
        widths="5% - - 10%"
    </table>

    Events:
        click(rec, column)
        action(action, selected)

    Slots:
        toolbar         For top level toolbar. Default has Title and filter input.

    Examples
        <vu-table name="network-ip-list" v-if="networks" :data="networks" sort="ip:asc" drag="move"
            :fields="fields" :extra="extra" :titles="{ip: 'IP', ipv6Ip: 'IPv6 IP', dns: 'DNS'}" @click="clicked">
            <template v-slot:table-col-edit="props">
                <v-icon icon="$wrench" size="16" />
            </template>
        </vu-table>

        fields = [
            {name: 'ip', title: 'IP', align: 'left', width: '5%' },
            {name: 'mask', width: '50px', css: 'myclass', style: 'text-transform: uppercase;' },
            {name: 'ipv6Ip', title: 'IPv6 IP' },
            {name: 'ipv6Mask', title: 'IPv6 Mask' },
            {name: 'dhcpId', enable: false },
            {name: 'dns' },
            {name: 'dnsSearch', enable: false },
        ]
        extra = [
            {name: 'edit', icon: '$edit', width: '10%', align: 'center' },
            {name: 'delete', icon: '$delete', width: '10%', align: 'center' },
        ]
        table.update()
        table.refresh()
 */
import {
    getCurrentInstance,
    nextTick,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    reactive,
    watch,
} from 'vue'
import {Progress, State, Storage, clone, delay, waitRender} from '@/paks/vu-app'
import {allow, toMap} from '@/paks/js-polyfill'
import Dates from '@/paks/js-dates'

const MIN_ITEMS_FOR_FILTER = 20
const DISPLAY_DEFAULT = 200
const DISPLAY_MOBILE = 15
const PageSizes = [10, 15, 25, 50, 100, 500, Infinity]

const {proxy: self} = getCurrentInstance()

const emit = defineEmits(['action', 'click', 'drop', 'filter', 'remove', 'update:modelValue'])

defineExpose({refresh, update, highlightRow})

const props = defineProps({
    aligns: [Array, String],
    callbacks: {type: Object, default: () => ({})},
    changeColumns: {type: Boolean, default: true},
    current: Number,
    data: [Array, Function],
    drag: [Array, String],
    extra: [Array, String],
    filterLabel: {type: String, default: 'Filter ...'},
    fields: [Array, String],
    header: {type: Boolean, default: true},
    id: String,
    mobile: {type: String, default: 'auto'},
    name: String,
    nodata: {type: String, default: 'no data'},
    options: [Object, String],
    pageSize: {type: Number}, // Initial page size
    persist: {type: Boolean, default: true},
    pivot: String,
    reactive: {type: Boolean, default: true},
    reload: {type: Boolean, default: true},
    select: Object,
    sort: String,
    subtitle: String,
    title: String,
    tabIndexBase: {type: Number, default: 0},
    titles: Object,
    width: {type: String, default: '100%'},
    widths: [Array, String],
})

const page = reactive({
    actions: null,
    canReload: false,
    columns: [],
    controls: {
        dynamic: null, //  Dynamically determine if a filter box should be displayed
        filter: null, //  Should we show a filter box
        refilter: null, //  Refilter data locally after getData
        toolbar: null, //  Show a toolbar
    },
    count: 0,
    data: [], // Input data rows
    display: [], // Rendered rows
    dragable: null,
    dragOptions: {},
    filter: null,
    loading: false,
    limit: 0, // Number of visible rows in display[]
    loading: false,
    mobile: props.mobile,
    needPagination: false,
    multi: false, // Show all columns select
    number: 1, // Page number, origin 1
    ready: null,
    resetLayout: false,
    rmenu: {show: false},
    parentId: null,
    pageSize: props.pageSize, // Initial page size
    select: clone(props.select),
    selectActions: null,
    seqno: null,
    showLoading: false,
    showPageSize: false,
    tableId: null,
    tableName: null,
    tableStyle: null,
    visibleColumns: [],
})

let lastPos = null
let currentSort = {name: null, dir: 1}
let resizeColumn = null
let rendering = false
let parent = null

watch(props, async () => {
    await update()
})

onBeforeMount(() => {
    page.seqno = State.app.getNextTable()
})

onBeforeUnmount(() => {
    removeEvents()
})

onMounted(async () => {
    await update()
})

function prepTable() {
    page.mobile = State.app.mobile && (props.mobile == true || props.mobile == 'auto')
    page.limit = Storage.getValue(`/table/${props.name}/limit`) || null
    page.limit = page.limit || page.pageSize || DISPLAY_DEFAULT
    if (page.mobile) {
        page.limit = Math.min(page.limit, DISPLAY_MOBILE)
    }
    page.tableName = props.name || 'default'
    page.tableId = props.id ? props.id : `table-wrapper-${page.seqno}`
    page.parentId = props.id ? props.id : `table-${page.seqno}`
    page.canReload = props.reload !== false && typeof props.data == 'function'

    let drag = props.drag != null ? props.drag.toString().split(/[ ,]+/g) : ['move']
    page.dragable = drag != ''
    page.dragOptions = {
        enable: page.dragable,
        padding: props.select ? 1 : 0,
        removeOnSpill: drag.indexOf('remove') >= 0,
        moveable: drag.indexOf('move') >= 0,
    }
    parseOptions()
}

async function update(options = {}) {
    if (options.filter) {
        page.filter = options.filter
    }
    if (options.rebuild) {
        page.ready = false
    }
    if (options.wait !== false) {
        while (rendering) {
            await delay(10)
        }
    }
    delete page.next
    delete page.prev
    prepTable()
    await renderTable()
}

function addEvents() {
    removeEvents()
    let table = document.getElementById(`${page.tableId}`)
    if (table) {
        if (props.changeColumns) {
            let ths = table?.children[0]?.children[0]?.children
            if (ths) {
                for (let th of ths) {
                    if (props.sort) {
                        th.addEventListener('click', sortClick)
                    }
                    let resize = th.children[th.children.length - 1]
                    if (resize) {
                        resize.addEventListener('mousedown', startColumnResize)
                    }
                }
            }
            emit('drop', async (args) => {
                page.columns.sort(orderColumns)
                saveColumns()
                await renderTable()
            })
            emit('remove', async (args) => {
                let {item} = args
                item.enable = false
                page.columns.sort(orderColumns)
                saveColumns()
                await renderTable()
            })
        }
        //  DISABLED MOB
        if (page.data.length > page.limit && false) {
            /*
                Render more rows when scrolling to the end
            */
            window.addEventListener('wheel', async (event) => {
                let rows = table.children[1].children
                let lastRow = rows[rows.length - 1]
                let visible = elementVisible(lastRow)
                if (visible && !page.loading) {
                    if (typeof props.data == 'function') {
                        page.showLoading = true
                    }
                    page.loading = true
                    setTimeout(async () => {
                        page.limit += page.pageSize || DISPLAY_DEFAULT
                        await renderTable()
                        page.loading = false
                    }, 0)
                }
            })
        }
    }
}

function elementVisible(el) {
    let rect = el.getBoundingClientRect()
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

function removeEvents() {
    let table = document.getElementById(`${page.tableId}`)
    if (table) {
        // let thead = table.children[0]
        let ths = table?.children[0]?.children[0]?.children
        if (ths) {
            for (let th of ths) {
                let resize = th.children[1]
                if (resize) {
                    resize.removeEventListener('mousedown', startColumnResize)
                }
            }
        }
        table.removeEventListener('mousemove', columnResize)
        document.removeEventListener('mouseup', endColumnResize)
    }
}

async function renderTable({fetch, prior} = {}) {
    if (rendering) {
        return
    }
    rendering = true
    try {
        if (fetch !== false) {
            await getData(prior)
        }
        prepFields()
        prepToolbar()
        prepTableStyle()
        renderHeader()
        renderBody()
        await waitRender()
        reposition()
        addEvents()
    } catch (err) {
        console.log('Error rendering table', err)
    } finally {
        rendering = false
    }
}

async function reload() {
    delete page.next
    delete page.prev
    page.number = 1
    page.loading = true
    await renderTable()
    page.loading = false
}

async function getData(prior) {
    if (props.data == null) {
        return false
    }
    let data
    /*
        Order matters here. Get the data first. A getData function must do offset/limit and filter processing.
        If it returns more data than the limit, it will be assumed that this processing was not applied and it will be done here.
    */
    if (typeof props.data == 'function') {
        let args = allow(page, ['limit', 'offset', 'next', 'prev'])
        if (page.filter) {
            args.filter = page.filter
        }
        // args.hidden = true
        args.reload = page.loading
        Progress.start('short')

        /*
            The callback can return data with .next and .prev set to pagination tokens
        */
        try {
            data = await props.data.call(parent, args)
        } catch (err) {
            console.error(`Cannot fetch data`, {err})
            data = []
        }
        Progress.stop()
        if (data.next || data.prev) {
            page.next = data.next
            page.prev = data.prev
        } else {
            page.next = data[data.length - 1]
            page.prev = data[0]
        }
        if (page.number == 1) {
            delete page.prev
        } else if (data.length < page.limit) {
            delete page.next
        }
        if (data && props.select) {
            for (let item of data) {
                if (props.select && item) {
                    item[props.select.property] = false
                }
            }
        }
        if (data.length > page.limit && page.controls.refilter) {
            data = filterData(data)
        }
        data = sortData(data)

    } else if (Array.isArray(props.data)) {
        data = filterData(props.data)

        let start = (page.number - 1) * page.limit
        let nextPage = start + page.limit
        delete page.next
        delete page.prev

        if (page.number > 1) {
            page.prev = data[start]
            page.next = data[nextPage]
        } else if (data.length > nextPage) {
            page.next = data[nextPage]
        }
        data = sortData(data)
        data = data.slice(start, nextPage)
    } else {
        data = []
    }
    page.needPagination =
        page.pageSize && page.limit && (page.number > 1 || data.length >= page.limit) ? true : false

    page.count = data.count || data.length

    if (prior) {
        data = blendSelectedItems(data, prior)
    }
    if (props.pivot) {
        data = pivotData(data)
    }
    if (!data) {
        return false
    }
    page.data = data
    setActions()
    return data.length ? true : false
}

function getFieldsFromData() {
    let map = {}
    for (let item of page.data) {
        for (let field of Object.keys(item)) {
            if (!map[field]) {
                map[field] = {name: field, enable: true}
            }
        }
    }
    let fields = []
    for (let field of Object.values(map)) {
        if (field.name != 'id') {
            fields.push(field)
        }
    }
    return fields
}

function filterData(items) {
    if (page.filter) {
        let pattern = page.filter.toLowerCase()
        return items.filter((item) => {
            for (let column of page.visibleColumns) {
                let value = getFieldValue(item, column)
                value = formatData(item, {column, name: column.name}, value)
                if ((value || '').toString().toLowerCase().indexOf(pattern) >= 0) {
                    return true
                }
            }
            return false
        })
    }
    return items
}

async function filterClearClick(e) {
    page.filter = null
    await renderTable()
}

function setCurrentSort() {
    if (!currentSort.name && props.sort) {
        for (let [index, column] of Object.entries(page.visibleColumns)) {
            if (column.sort) {
                currentSort.name = column.name
                currentSort.dir = column.sort
            }
        }
        /*
            Only active now if column:dir. If just set to "true", then sorting will wait till a column is defined.
         */
        let [name, dir, fixed] = props.sort.toString().split(':')
        if (dir) {
            if (!currentSort.name || fixed) {
                currentSort.name = name
                currentSort.dir = dir == 'desc' ? -1 : 1
            }
        }
    }
}

function sortData(data) {
    if (props.sort) {
        //  Clone to prevent sort updating props.data
        data = data.slice(0)
        if (props.callbacks.sort) {
            data = props.callbacks.sort(parent, data)
        } else {
            setCurrentSort()
            if (data && currentSort.dir) {
                if (currentSort.name) {
                    data = sortByColumn(data, currentSort.name, currentSort.dir)
                } else {
                    if (currentSort.dir < 0) {
                        data = data.reverse()
                    }
                }
            }
        }
    }
    return data
}

function blendSelectedItems(data, prior) {
    if (props.select && data.length && data[0]?.id) {
        let prop = props.select.property
        let map = toMap(prior, 'id')
        for (let item of data) {
            if (map[item.id]) {
                item[prop] = map[item.id][prop]
            }
        }
    }
    return data
}

function sortIcon(column) {
    if (props.sort && !column.slot) {
        if (currentSort.name == column.name) {
            return currentSort.dir < 0 ? '$up' : '$down'
        }
    }
    return '$up'
}

function sortClass(column) {
    if (column.slot) {
        return 'no-sort'
    }
    if (props.sort && currentSort.name == column.name) {
        return 'show'
    }
    return 'hide-sort'
}

function pivotData(items) {
    if (items.length == 0) {
        return items
    }
    let result = []
    for (let fieldName of Object.keys(items[0])) {
        let item = {field: createTitle(fieldName)}
        for (let row of items) {
            let key = row[props.pivot] || 'value'
            item[key] = row[fieldName]
        }
        result.push(item)
    }
    return result.sort((a, b) => {
        if (a.field < b.field) {
            return -1
        } else if (a.field > b.field) {
            return 1
        } else {
            return 0
        }
    })
}

function orderColumns(a, b) {
    if (!a.enable) {
        return 1
    }
    if (!b.enable) {
        return -1
    }
    if (a.index < b.index) {
        return -1
    } else if (a.index > b.index) {
        return 1
    } else {
        return 0
    }
}

function prepTableStyle() {
    page.tableStyle = props.width ? `width: ${props.width};` : ''
}

function renderHeader() {
    for (let [index, column] of Object.entries(page.visibleColumns)) {
        if (!column.title) {
            column.title = createTitle(column.name)
        }
        column.style = toArray(column.style)
        column.css = toArray(column.css)
        let css = `table-col-${column.name}`
        if (!column.css.find((c) => c == css)) {
            column.css.push(css)
        }
        if (column.width) {
            column.style = column.style.filter((s) => !s.match(/width:|max-width:/))
            column.style.push(`width: ${column.width}`)
            column.style.push(`max-width: ${column.width}`)
        }
        if (column.style.find((c) => c.indexOf('text-align') >= 0) == null) {
            if (column.align) {
                column.style.push(`text-align: ${column.align}`)
            } else if (column.slot) {
                column.style.push(`text-align: center`)
            }
        }
        column.index = +index
    }
}

function renderBody() {
    let {data} = page
    let rows = []

    let limit = Math.min(page.limit, data.length)
    for (let row = 0; row < limit; row++) {
        let rec = data[row]
        let line = []
        line.rec = rec
        rows.push(line)

        let colNum = 0
        for (let column of page.visibleColumns) {
            line.push(makeField(rec, row, column, colNum++, rows))
        }
        if (props?.select?.property && rec[props.select.property]) {
            line[row][props.select.property] = true
        }
    }
    let table = document.getElementById(`${page.tableId}`)
    if (table) {
        lastPos = table.scrollTop
    }
    page.display = rows
    if (props.data) {
        page.ready = true
    }
}

function makeField(rec, rowNum, column, colNum, rows) {
    if (rowNum > 0) {
        let prior = rows[rowNum - 1]
        //  Spanned is set to rowspan for the first spanned row
        if (prior && prior[colNum] && prior[colNum].spanned > 1) {
            return {name: column.name, column, row: rowNum, spanned: prior[colNum].spanned - 1}
        }
    }
    let value = getFieldValue(rec, column)

    let list = []
    for (let item of column.css) {
        if (typeof item == 'function') {
            list.push(item.call(parent, value, rec, column.name))
        } else {
            list.push(item)
        }
    }
    let css = list.join(' ')
    if (props.pivot) {
        css += ' pivot'
    }

    list = []
    for (let item of column.style) {
        if (typeof item == 'function') {
            //  FUTURE better if these args were {}
            list.push(item.call(parent, value, rec, column.name))
        } else {
            list.push(item)
        }
    }
    let style = list.length ? list.join('; ') + ';' : ''
    if (column.align) {
        style += `text-align: ${column.align}; `
    }
    if (props.current == rowNum) {
        css += ' current'
    }
    if (props?.select?.property && rec[props.select.property]) {
        css += ' selected'
    }
    let icon, slot
    if (column.slot) {
        slot = column.slot == true ? `table-col-${column.name}` : column.slot
    }
    let field = {name: column.name, column, row: rowNum, css}

    value = formatData(rec, field, value)

    if (column.icon) {
        icon = column.icon
        if (typeof icon == 'function') {
            icon = column.icon.call(parent, value, rec, column.name)
        } else if (icon === 'tick') {
            icon = value ? '$complete' : '$close'
        } else if (icon === 'check') {
            icon = value ? '$checkboxOn' : '$checkboxOff'
        } else if (typeof icon == 'object') {
            icon = icon[value] || icon['default']
            if (typeof icon == 'object') {
                style += `color: ${icon.color};`
                icon = icon.name
            }
        } else if (icon === true) {
            icon = `\$${column.name}`
        } else if (value === false || value === null) {
            icon = null
        }
        if (!column.align) {
            style += `text-align: center; `
        }
    }
    field.icon = icon
    field.style = style

    if (value === undefined && !icon) {
        slot = 'table-col-undef'
        field.css += ' undef'
    }
    field.slot = slot
    field.value = value
    field.id = `${props.name}-${rowNum * page.visibleColumns.length + colNum}`

    return field
}

function formatData(rec, field, value) {
    let column = field.column
    try {
        if (props.callbacks.format) {
            field.value = value
            value = props.callbacks.format.call(parent, rec, field, value)
        } else if (column.format) {
            value = column.format.call(parent, value, rec, column.name)
        } else if (value instanceof Date) {
            if (isNaN(value.getTime())) {
                value = new Date(0)
            }
            value = Dates.format(value, State.config.theme.formats.time)
        }
    } catch (err) {
        //  ignore and use unformatted value
    }
    if (column.suffix) {
        value = `${value} ${column.suffix}`
    }
    return value
}

function startColumnResize(e) {
    let th = e.target.parentElement
    let table = document.getElementById(`${page.tableId}`)
    let thead = table.children[0]
    let tbody = table.children[1]
    if (!tbody) {
        return
    }
    let ths = thead.children[0].children
    let index
    for (index = 0; index < ths.length - 1; index++) {
        if (ths[index] == th) {
            break
        }
    }
    if (index >= ths.length || (props.select && index == 0)) {
        return
    }
    let td = tbody.children[0].children[index]
    if (th && td) {
        let vindex = props.select ? index - 1 : index
        if (vindex < 0) {
            vindex = 0
        }
        resizeColumn = {
            th,
            td,
            index,
            vindex,
            originalWidth: th.offsetWidth,
            x: e.pageX,
        }
    }
    e.stopPropagation()
    table.addEventListener('mousemove', columnResize)
    document.addEventListener('mouseup', endColumnResize)
}

function columnResize(e) {
    let resize = resizeColumn
    if (resize && page.visibleColumns) {
        let diff = e.pageX - resize.x
        let width = resize.originalWidth + diff

        resize.th.style.width = `${width}px`
        resize.th.style.minWidth = `${width}px`
        resize.th.style.maxWidth = `${width}px`

        if (resize.vindex >= page.visibleColumns.length) {
            resize.vindex = page.visibleColumns.length - 1
        }
        page.visibleColumns[resize.vindex].width = `${width}px`
        e.stopPropagation()
    }
}

async function endColumnResize(e) {
    e.stopPropagation()
    if (resizeColumn) {
        saveColumns()
        await delay(0)
        resizeColumn = null
    }
}

function getProp(rec, path) {
    if (!rec) {
        return ''
    }
    let value = rec[path]
    if (!value) {
        let found
        for (let key of path.split('.')) {
            if (rec) {
                rec = rec[key]
                found = true
            }
        }
        value = found ? rec : ''
    }
    return value
}

function getFieldValue(rec, column) {
    let value = getProp(rec, column.name)
    return value
}

async function cellClick(event, field, row, column) {
    let item = page.display[row].rec
    //  DEPRECATE rec: item
    emit('click', {action: 'cell', item, field, column, row, rec: item})
}

async function actionClick(action) {
    let items = []
    let prop = props.select?.property
    if (prop) {
        for (let item of page.data) {
            if (item[prop] == true) {
                items.push(item)
            }
        }
    }
    action = action.toLowerCase()
    emit('click', {action, items, item: items[0]})
    await waitRender()
}

async function filterChanged() {
    await reload()
    emit('filter', page.filter)
}

/*
    Parse options and select
 */
function parseOptions() {
    let options = props.options
    if (!options) {
        options = props.select ? 'dynamic, toolbar' : 'dynamic'
    }
    if (typeof options == 'string') {
        for (let option of options.split(/, */g).map((c) => c.trim())) {
            page.controls[option] = true
        }
    } else if (options) {
        page.controls = clone(options)
    }
    if (page.mobile) {
        page.controls.filter = false
        // page.controls.dynamic = false
        page.select = false
        // page.controls.toolbar = false
    } else if (props.select) {
        page.select = clone(props.select)
        if (!page.select.property) {
            page.select.property = 'select'
        }
    } else {
        page.select = null
    }
}

function prepToolbar() {
    let controls = page.controls
    if (controls.dynamic && !props.pivot) {
        let length = page.data.length
        //  Determine if we should show a filter box
        controls.filter =
            controls.filter || page.filter || length > MIN_ITEMS_FOR_FILTER || page.count > length
    }
    if ((controls.filter || props.title) && controls.toolbar !== false) {
        controls.toolbar = true
    }
}

function prepFields() {
    let fields
    if (!props.fields) {
        fields = getFieldsFromData()
    } else if (Array.isArray(props.fields)) {
        if (props.fields.length == 0) {
            fields = getFieldsFromData()
        } else {
            // User supplies a fields array of objects AND is responsible for persisting column configuration
            fields = props.fields
        }
    } else {
        // String with field names
        fields = []
        for (let item of toArray(props.fields)) {
            let [name, title] = item.split(':')
            let slot = false
            if (name[0] == '@') {
                name = name.slice(1)
                slot = true
            }
            if (!fields.find((field) => field.name == name)) {
                title = title || item.title
                fields.push({name, title, enable: true, slot})
            }
        }
    }
    let extra = props.extra
    if (extra) {
        if (typeof extra == 'string' || Array.isArray(extra)) {
            if (extra == 'crud') {
                extra = 'edit,delete'
            }
            extra = Array.isArray(extra) ? extra : extra.split(/[ ,]+/g)
            for (let field of extra) {
                if (typeof field == 'object') {
                    fields = fields.concat(extra)
                    break
                }
                if (fields.find((f) => f.name == field)) {
                    continue
                }
                if (field == 'delete') {
                    fields.push({name: field, icon: '$delete', width: '10%', align: 'center'})
                } else if (field == 'edit') {
                    fields.push({name: field, icon: '$edit', width: '10%', align: 'center'})
                } else {
                    fields.push({name: field})
                }
            }
        } else {
            fields = fields.concat(extra)
        }
    }
    for (let field of fields) {
        if (field.enable == null) {
            field.enable = true
        }
    }
    fields = fields.filter(
        (f) => (f.mobile && page.mobile) || (f.desktop && !page.mobile) || (!f.mobile && !f.desktop)
    )

    let widths = props.widths
    if (widths) {
        if (Array.isArray(widths)) {
            widths = widths.join(',')
        }
        widths = widths.trim()
        for (let [index, width] of Object.entries(widths.split(/[ ,]+/g))) {
            if (width != null && width != '-' && width != '*') {
                fields[index].width = width
            }
        }
    }
    let titles = props.titles
    if (titles) {
        for (let [key, title] of Object.entries(titles)) {
            let field = fields.find((f) => f.name == key)
            if (field) {
                field.title = title
            }
        }
    }
    let aligns = props.aligns
    if (aligns) {
        if (Array.isArray(aligns)) {
            aligns = aligns.join(',')
        }
        aligns = aligns.trim()
        for (let [index, align] of Object.entries(aligns.split(/[ ,]+/g))) {
            if (align != null && align != '-' && align != '*') {
                fields[index].align = align
            }
        }
    }
    updateColumns(fields)
}

//  This messes up icons in edit column
function applySavedColumns(columns) {
    if (props.persist && props.name) {
        let prior = Storage.getValue(`/table/${props.name}/columns`) || []
        if (
            prior
                .map((f) => f.name)
                .sort()
                .toString() ==
            page.columns
                .map((f) => f.name)
                .sort()
                .toString()
        ) {
            // No change in field names, blend persisted field definition over field configuration
            // issue here if field definitions for icons change or other attributes
            for (let [index, field] of Object.entries(page.columns)) {
                let pfield = prior.find((e) => e.name == field.name)
                if (pfield) {
                    page.columns[index] = Object.assign(field, pfield)
                }
            }
        }
    }
}

function updateColumns(fields) {
    let cols = []
    for (let [key, field] of Object.entries(fields)) {
        let column = page.visibleColumns.find((c) => c.name == field.name)
        if (column) {
            column = Object.assign(column, field)
        } else {
            column = Object.assign({style: []}, field)
        }
        cols.push(column)
        if (column.sort && !currentSort) {
            currentSort = {name: column.name, dir: column.sort}
        }
        if (column.icon) {
            column.slot = true
        }
    }
    // applySavedColumns(cols)
    cols.sort(orderColumns)
    page.columns = cols
    page.visibleColumns = cols.filter((c) => c.enable)
}

async function sortClick(e) {
    if (resizeColumn) {
        return
    }
    let th = e.target
    while (th && th.tagName != 'TH') {
        th = th.parentElement
    }
    if (!th || !th.children) {
        return
    }
    let table = document.getElementById(`${page.tableId}`)
    let thead = table.children[0]
    let ths = thead.children[0].children
    if (!ths) {
        return
    }
    let index
    for (index = 0; index < ths.length - 1; index++) {
        if (ths[index] == th) {
            break
        }
    }
    if (page.select && index == 0) {
        // Don't sort select column
    } else {
        if (page.select) {
            index--
        }
        if (index < page.visibleColumns.length) {
            for (let column of page.visibleColumns) {
                column.sort = null
            }
            let column = page.visibleColumns[index]
            currentSort.name = column.name
            currentSort.dir = -currentSort.dir
            page.data = sortByColumn(page.data, currentSort.name, currentSort.dir)
            column.sort = currentSort.dir
            saveColumns()
        }
        await update()
    }
}

function sortByColumn(rows, field, dir) {
    if (!field) return
    let numeric = null
    return rows.sort((a, b) => {
        a = getProp(a, field)
        b = getProp(b, field)
        if (a instanceof Date || b instanceof Date) {
            if (!(a instanceof Date)) {
                a = new Date(a)
            }
            if (!(b instanceof Date)) {
                b = new Date(b)
            }
            a = a.getTime() || 0
            b = b.getTime() || 0
        }
        if (a == b) {
            return 0
        } else if (a == null || a < b) {
            return -dir
        } else {
            return dir
        }
    })
}

function saveColumns() {
    if (props.persist && props.name) {
        Storage.setValue(`/table/${props.name}/columns`, page.columns)
    } else {
        emit('update:modelValue', page.columns)
    }
}

function toArray(item) {
    if (item == null) {
        return []
    }
    if (Array.isArray(item)) {
        return item
    } else if (typeof item == 'string') {
        return item.split(',').map((item) => item.toString().trim())
    } else {
        return [item]
    }
}

function createTitle(name) {
    name = name.replace(/\*$/, '')
    name = name.replace(/\.([a-z]|[A-Z])/g, (full, sub) => ' ' + sub.toUpperCase())
    return name.replace(/^[a-z]|[A-Z]/g, (v, i) =>
        i === 0 ? v.toUpperCase() : ' ' + v.toUpperCase()
    )
}

function reposition() {
    if (lastPos) {
        table.scrollTop = lastPos
    }
}

async function reset() {
    Storage.setValue(`/table/${props.name}/columns`, [])
    Storage.setValue(`/table/${props.name}/dimensions`, {})
    Storage.setValue(`/table/${props.name}/limit`, null)
    page.ready = false
    page.visibleColumns = []
    page.columns = []
    currentSort = {name: null, dir: 1}
    page.limit = page.pageSize || DISPLAY_DEFAULT
}

async function resetButton() {
    await reset()
    await renderTable()
    page.resetLayout = false
}

function setActions() {
    let data = page.data
    if (!page.select) {
        return
    }
    let prop = page.select.property
    let selected = 0
    for (let item of data) {
        if (item[prop]) {
            selected++
        }
    }
    page.multi = page.select.multi

    /*
        Count is the number of items the action can perform
    */
    let list = []
    if (page.select.actions) {
        for (let [action, count] of Object.entries(page.select.actions)) {
            let disabled = false
            if (count === undefined) {
                continue
            } else if (count == 0) {
                if (selected > 0) {
                    disabled = true
                }
            } else if (count == 1) {
                if (selected != 1) {
                    disabled = true
                }
            } else if (count == 2) {
                page.multi = true
                if (selected == 0) {
                    disabled = true
                }
            } else if (count == 99) {
                page.multi = true
            }
            list.push({label: action, id: action, disabled})
        }
        if (list.length == 0) {
            list = null
        }
        page.actions = list
        page.selectActions = selected ? true : false
    }
}

function headerRightClick() {
    page.resetLayout = true
}

function headerDoubleClick(event, column) {
    let table = document.getElementById(`${page.tableId}`)
    let thead = table.children[0]
    let tbody = table.children[1]
    if (!tbody) {
        return
    }
    let th = thead.children[0].children[column.index + 1]
    if (th == null) {
        return
    }
    let width = th.offsetWidth

    let rows = tbody.children
    for (let r = 0; r < rows.length; r++) {
        let row = rows[r]
        if (column.index + 1 < row.children.length) {
            let td = row.children[column.index + 1]
            width = Math.max(td.scrollWidth + 6, width)
        }
    }
    th.style.width = `${width}px`
    th.style.minWidth = `${width}px`
    th.style.maxWidth = `${width}px`
    page.visibleColumns[column.index].width = `${width}px`
}

async function bodyRightClick(event, field, row) {
    event.preventDefault()
    let rv = page.rmenu
    rv.field = field
    rv.row = row
    rv.item = page.data[row]
    rv.name = field.name
    rv.show = false
    rv.x = event.clientX
    rv.y = event.clientY

    nextTick(async () => {
        page.rmenu.show = true
    })
}

async function selectRowClick(row, options = {}) {
    let prop = page.select.property
    let data = filterData(page.data)
    if (row == null) {
        if (data.every((e) => e[prop] == true) || data.every((e) => e[prop] == false)) {
            data.forEach((i) => (i[prop] = !i[prop]))
        } else {
            data.forEach((i) => (i[prop] = true))
        }
    } else if (data && row < data.length) {
        data[row][prop] = !data[row][prop]
    }
    await actionClick('select')
    setActions()
    await renderTable({fetch: options.fetch || false})
}

async function highlightRow(key, value) {
    let prop = page.select?.property
    let data = filterData(page.data)
    if (data && prop) {
        for (let item of data) {
            item[prop] = false
        }
        let row = key != null ? data.findIndex((i) => i[key] == value) : value
        if (row >= 0 && row < data.length) {
            data[row][prop] = !data[row][prop]
        }
    }
    // await actionClick('select')
    await renderTable({fetch: false})
}

function selectionIcon(row) {
    let data = page.data
    let prop = page.select.property
    if (row == null) {
        if (data.every((e) => e[prop] == true)) {
            return '$checkboxOn'
        } else if (data.every((e) => e[prop] != true)) {
            return '$checkboxOff'
        } else {
            return '$checkboxIndeterminate'
        }
    }
    if (data[row]) {
        return data[row][prop] ? '$checkboxOn' : '$checkboxOff'
    }
    return '$checkboxOff'
}

/*
async function dataChanged(data, prior) {
    //  Commented out for WidgetSyle add, and data == prior
    if (props.reactive) {
        while (rendering) {
            await delay(10)
        }
        renderTable({prior})
    }
}

function changeSort(v) {
    if (v) {
        delete currentSort.name
        for (let [index, column] of Object.entries(page.visibleColumns)) {
            delete column.name
        }
        setCurrentSort()
        renderTable()
    }
}
*/

function clearSelected() {
    if (page.select) {
        for (let item of page.data) {
            item[page.select.property] = false
        }
    }
}

async function refresh() {
    clearSelected()
    await renderTable()
}

async function savePageSize() {
    Storage.setValue(`/table/${props.name}/limit`, page.limit)
    page.showPageSize = false
    await reload()
}

function nop() {}

async function pageTo(dir) {
    if (dir > 0) {
        if (page.data.length >= page.limit) {
            page.number += dir
        }
    } else {
        page.number += dir
    }
    if (page.number <= 0) {
        page.number = 1
        return
    }
    if (dir < 0) {
        delete page.next
    } else {
        delete page.prev
    }
    await renderTable()
}
</script>

<template>
    <div :class="`table vu-table ${page.tableName}`" :id="page.parentId" v-if="page.ready">
        <slot name="toolbar">
            <div class="toolbar" v-if="page.controls.toolbar">
                <div class="table-title" v-if="title">{{ title }}</div>
                <span class="filter" v-if="page.controls.filter" align-self="end">
                    <v-text-field
                        :label="filterLabel"
                        prepend-icon="$magnify"
                        density="compact"
                        size="25"
                        spellcheck="false"
                        clearable
                        single-line
                        hide-details
                        variant="underlined"
                        v-model.trim="page.filter"
                        @input="filterChanged"
                        @click:clear="filterClearClick"
                        @keydown.enter.prevent="nop"
                        class="search mt-0 pt-0"></v-text-field>
                </span>
                <v-btn v-if="page.resetLayout" dark color="teal" class="mr-2" @click="resetButton">
                    Reset Layout
                </v-btn>
                <v-btn
                    v-if="page.canReload"
                    :loading="page.loading"
                    class="action mr-4"
                    variant="outlined"
                    @click="reload">
                    <v-icon icon="$reload" class="small" />
                </v-btn>
                <span
                    class="table-actions py-0 ma-0 pr-2"
                    v-if="
                        (page.selectActions || page.select) && page.actions && page.actions.length
                    ">
                    <vu-pick label="Actions" :items="page.actions" @click="actionClick" />
                </span>
                <span class="more pr-2">
                    <slot name="more"></slot>
                </span>
                <span class="pagination" v-if="page.needPagination && !page.mobile">
                    <v-icon
                        icon="$leftArrow"
                        @click="pageTo(-1)"
                        :disabled="page.prev ? false : true" />
                    <span class="page">{{ page.number }}</span>
                    <v-icon
                        icon="$rightArrow"
                        @click="pageTo(+1)"
                        :disabled="page.next ? false : true" />
                    <v-icon
                        icon="$cog"
                        class="ml-5 mr-1"
                        size="24"
                        @click.stop="page.showPageSize = true" />
                </span>
            </div>
        </slot>

        <div class="table-subtitle" v-if="subtitle">
            <slot name="subtitle">{{ subtitle }}</slot>
        </div>

        <v-menu
            v-model="page.rmenu.show"
            absolute
            offset-y
            :position-x="page.rmenu.x"
            :position-y="page.rmenu.y"
            :close-on-content-click="false">
            <slot name="context-menu" v-bind:menu="page.rmenu"></slot>
        </v-menu>

        <slot name="progress"></slot>

        <div class="scroller">
            <table
                v-if="page.ready"
                class="table-data"
                :id="page.tableId"
                :style="page.tableStyle"
                :class="{'table-pivot': pivot}">
                <thead v-if="header">
                    <tr
                        v-drag="page.visibleColumns"
                        order="index"
                        :drag-options="page.dragOptions"
                        :class="{dragable: page.dragable}"
                        @contextmenu.prevent="headerRightClick">
                        <th class="select" v-if="page.select">
                            <v-icon
                                :icon="selectionIcon()"
                                @click="selectRowClick()"
                                v-if="page.multi" />
                        </th>
                        <th
                            v-for="column in page.visibleColumns"
                            :key="column.index"
                            :style="column.style.join(';') + ';'"
                            :class="column.css.join(';')"
                            @dblclick.prevent="headerDoubleClick($event, column)">
                            <span>{{ column.title }}</span>
                            <div class="resize"></div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(line, row) in page.display" :key="row">
                        <td class="select" v-if="page.select">
                            <v-icon
                                :icon="selectionIcon(row)"
                                size="16"
                                @click="selectRowClick(row)" />
                        </td>
                        <td
                            v-for="(field, index) in line.filter((f) => !f.spanned || f.rowspan)"
                            class="table-cell"
                            :class="field.css"
                            :key="index"
                            :style="field.style"
                            :rowspan="field.rowspan"
                            :skip="field.spanned"
                            @contextmenu.prevent="bodyRightClick($event, field, row)"
                            @click="cellClick($event, field, row, page.visibleColumns[index])">
                            <slot
                                v-if="field.slot"
                                :name="field.slot"
                                v-bind:rec="field.rec"
                                v-bind:field="field"
                                v-bind:item="page.data[row] || {}"
                                v-bind:row="row">
                                <v-icon :icon="field.icon" size="16" v-if="field.icon" />
                            </slot>

                            <div v-else>
                                <slot
                                    name="cell"
                                    v-bind:rec="field.rec"
                                    v-bind:field="field"
                                    v-bind:item="page.data[row] || {}"
                                    v-bind:row="row">
                                    {{ field.value }}
                                </slot>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="page.display.length == 0">
                        <td
                            :colspan="page.visibleColumns.length + (page.select ? 1 : 0)"
                            class="empty">
                            <slot name="nodata">{{ props.nodata }}</slot>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination text-center" v-if="page.needPagination && page.mobile">
                <v-icon
                    icon="$leftArrow"
                    @click="pageTo(-1)"
                    :disabled="page.prev ? false : true" />
                <span class="page">{{ page.number }}</span>
                <v-icon
                    icon="$rightArrow"
                    @click="pageTo(+1)"
                    :disabled="page.next ? false : true" />
                <v-icon
                    icon="$cog"
                    class="cog ml-5 mr-1"
                    size="24"
                    @click.stop="page.showPageSize = true" />
            </div>
        </div>

        <div class="loading text-center" v-if="page.loading && page.showLoading">
            <v-progress-circular :size="50" color="primary" indeterminate />
        </div>

        <v-dialog
            v-model="page.showPageSize"
            persistent
            width="400"
            content-class="table-page-size">
            <v-card>
                <v-card-title class="headline" primary-title>Page Size</v-card-title>
                <v-card-text>
                    <v-combobox
                        variant="underlined"
                        v-model="page.limit"
                        :items="PageSizes"
                        label="Page Size"
                        dense
                        hide-details />
                </v-card-text>
                <v-card-actions class="pt-5 mt-5">
                    <v-btn variant="elevated" size="small" color="accent" @click="savePageSize">
                        Save
                    </v-btn>
                    <v-btn variant="elevated" size="small" @click="page.showPageSize = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style lang="scss">
.vu-table {
    .search {
        i {
            color: rgb(var(--v-theme-accent)) !important;
        }
        label {
            color: rgba(0, 0, 0, 0.3) !important;
        }
        .v-field__outline::before {
            border: none !important;
        }
        .v-field__clearable {
            padding-top: 4px !important;
            .v-icon {
                color: rgb(var(--v-theme-secondary-lighten-1)) !important;
            }
        }
    }
    .search > .v-input__control > .v-input__slot:before {
        border: none !important;
    }
    td {
        cursor: pointer;
    }
}
.table-page-size {
    .v-card {
        .headline {
            padding: 16px;
            background: rgb(var(--v-theme-primary));
            color: rgba(50, 50, 50, 0.75);
            color: rgb(var(--v-theme-on-primary));
            margin-bottom: 20px;
        }
        .v-card-text {
            padding-left: 24px;
        }
        .v-card-actions {
            padding: 20px;
        }
    }
}
</style>

<style lang="scss" scoped>
.vu-table.full {
    width: 100%;
    table {
        width: 100%;
    }
}

.table {
    .scroller {
        scroll-behavior: smooth;
        overflow-x: scroll;
        ::-webkit-scrollbar {
            display: none;
        }
    }

    table {
        scroll-behavior: smooth;
        overflow-x: scroll;
        ::-webkit-scrollbar {
            display: none;
        }
    }

    .toolbar {
        display: flex;
        margin: 0;
        padding: 10px 0 14px 0;
        .table-title {
            white-space: nowrap;
            display: block;
            font-size: 1.8rem;
            line-height: 1.2;
            color: rgb(var(--v-theme-text));
            padding-right: 20px;
            @media (max-width: 640px) {
                margin-inline-start: 0;
            }
        }
        .filter {
            border: 1px solid rgb(var(--v-theme-border));
            min-width: 300px;
            padding: 0;
            top: 7px;
            margin-right: 10px !important;
            @media (max-width: 640px) {
                min-width: 100px;
            }
            .search {
                padding-left: 10px;
            }
        }

        .v-btn {
            margin-right: 10px;
        }
    }
    .pagination {
        padding-top: 8px;
        margin-left: auto;
        .v-icon {
            font-size: 1rem;
        }
        .v-icon--disabled {
            color: rgba(0, 0, 0, 0.18) !important;
        }
        .page {
            padding: 0 8px;
            font-size: 0.75rem;
            width: 10px;
            overflow: hidden;
            white-space: nowrap;
            vertical-align: middle;
        }
        @media (max-width: 640px) {
            .page {
                font-size: 0.9rem;
            }
            .cog {
                height: 20px;
                width: 20px;
            }
        }
    }
    .table-subtitle {
        display: block;
        font-size: 1rem;
        line-height: 1;
        margin: 0;
        padding: 0 0 14px 0;
        color: rgb(var(--v-theme-text-lighten-1));
    }
    .table-data {
        table-layout: auto;
        scrollbar-width: none;
        overflow-x: scroll;
        box-shadow: none;
        color: rgb(var(--v-theme-text));
        border-collapse: collapse;
        margin-bottom: 10px;
        thead {
            th {
                background-color: rgba(var(--v-theme-background), 0.5);
                position: relative;
                .resize {
                    height: 100%;
                    top: 0;
                    right: 0;
                    width: 10px;
                    position: absolute;
                    cursor: col-resize;
                    background-color: none;
                    user-select: none;
                    &:hover {
                        border-right: 2px solid blue;
                    }
                }
                .sort {
                    display: inline;
                    cursor: pointer;
                    overflow: hidden;
                    i {
                        padding-left: 4px;
                        font-size: 1.25rem;
                    }
                }
                .hide-sort {
                    opacity: 0;
                }
                .no-sort {
                    display: none;
                }
            }
        }
        tbody {
            &.clickable {
                td {
                    cursor: pointer;
                }
            }
        }
        th,
        td {
            text-align: left;
            overflow: hidden;
            text-overflow: clip;
            border: 1px solid rgb(var(--v-theme-border));
            white-space: nowrap !important;
            &.pivot {
                padding: 6px;
            }
            &.table-resize {
                background-color: rgba(0, 0, 0, 0) !important;
                border: none;
                width: 80px;
            }
            .v-icon {
                color: inherit;
            }
        }
        td {
            padding: 7px;
            white-space: nowrap;
            background: rgb(var(--v-theme-none));
        }
        th {
            padding: 7px 7px 7px 7px;
            border-bottom: none;
            border-right: 1px solid rgb(var(--v-theme-border));
            white-space: nowrap;
        }
        tr:nth-child(even) td:not(.end):not(.resize) {
            background: rgb(var(--v-theme-none-lighten-1));
        }
        tr.dragable th:not(.select) {
            cursor: move;
        }
        .select {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            width: 50px;
            button {
                font-size: 0.8rem;
            }
        }
    }
    .table-pivot {
        .table-resize {
            padding: 7px !important;
            width: 10px !important;
        }
    }
    .table-resize-padding {
        min-width: 80px !important;
    }
    .column-checkbox {
        div {
            margin-bottom: 0 !important;
        }
    }
    .v-input__slot {
        margin-bottom: 0 !important;
    }
    .empty {
        padding: 10px !important;
        font-style: italic;
        color: rgba(0, 0, 0, 0.3);
        text-align: center;
    }
    .loading {
        position: fixed;
        top: 50%;
        left: 50%;
    }
}

.gu-mirror {
    background: #5b82b7;
    border: 1px solid black;
    color: white;
    font-family: Roboto;
    padding-top: 10px;
    box-shadow: 4px 4px 4px #a0a0a0;
}
</style>
