<script setup>
import {ListWidget} from '@/paks/vu-widgets'
import {onMounted, reactive, ref, watch} from 'vue'
import {State, can, clone} from '@/paks/vu-app'

const Blog = [
    {
        date: '2022/10/26',
        color: 'accent',
        icon: '$clipboard',
        title: 'Ioto Release',
        subtitle: 'New Embedded web server',
        link: 'https://www.embedthis.com/blog',
    },
    {
        date: '2022/10/26',
        color: 'primary',
        icon: '$tap',
        title: 'Ioto Release 2.0',
        subtitle: 'New Embedded web server',
        link: 'https://www.embedthis.com/blog',
    },
]
const Learn = [
    {
        date: '2022/10/26',
        color: 'primary',
        icon: '$tap',
        title: 'Choosing your next embedded web server',
        subtitle: 'Select Ioto as your next embedded web server. Blazing fast, rock solid.',
        link: 'https://www.embedthis.com/blog/ioto/ioto-as-an-embedded-web-server.html',
    },
    {
        date: '2022/10/26',
        color: 'accent',
        icon: '$clipboard',
        title: 'Using the Ioto Agent',
        subtitle:
            'Building and configurating Ioto as an embedded web server or cloud-connected agent.',
        link: 'https://www.embedthis.com/ioto/doc/user/background/',
    },
]
const News = [
    {
        date: '2022/10/26',
        color: 'accent',
        icon: '$clipboard',
        title: 'Ioto Device Agent',
        subtitle: 'Ioto device agent, cloud-ready agent is our most advanced device agent.',
        link: 'https://www.embedthis.com/ioto/',
    },
    {
        date: '2022/10/26',
        color: 'primary',
        icon: '$tap',
        title: 'EmbedThis Builder Portal',
        subtitle: 'Use the EmbedThis Device Builder for your next project.',
        link: 'https://www.embedthis.com/builder/doc/',
    },
]

const page = reactive({
    visible: true,
})

const greeting = ref(null)
const news = ref(News)
const learn = ref(Learn)

const emit = defineEmits(['click'])
defineExpose({page})

onMounted(async () => { 
    page.visible = greeting.value.page.display
})

function showDashboard() {
    greeting.value.dismiss()
    emit('click')
}
</script>

<template>
    <div class="home-greeting">
        <vu-sign
            color="accent"
            class="welcome"
            name="home-greeting"
            title="Welcome to EmbedThis"
            :show="page.greeting"
            ref="greeting">
            <v-row>
                <v-col cols="6">
                    <vu-card title="Builder for Connected Devices" color="accent" class="welcome">
                        <p>
                            <b>EmbedThis Builder</b>
                            is a developer studio for creating and managing Internet connected
                            devices.
                        </p>

                        <div v-if="page.agreement">
                            <p>
                                The Builder is the new way for you to manage your embedded projects
                                including your EmbedThis Ioto, Appweb and GoAhead licenses.
                            </p>
                            <p>
                                You can manage maintenance renewals, download new versions of the
                                software and interact with support by raising issues and tracking
                                them to resolution.
                            </p>
                        </div>
                        <div v-else>
                            <p>
                                The Builder is the one-stop-shop to help you define your connected
                                devices, subscribe and download device agent software, create device
                                apps and configure, manage and monitor cloud-based management.
                            </p>
                        </div>
                        <p>To get started, read any of our customized guides:</p>
                        <ul>
                            <li>
                                <a
                                    href="https://www.embedthis.com/builder/doc/start/for-product-managers/"
                                    target="_blank">
                                    Builder for Product Managers
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.embedthis.com/builder/doc/start/for-embedded-engineers/"
                                    target="_blank">
                                    Builder for Embedded Engineers
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.embedthis.com/builder/doc/start/for-cloud-engineers/"
                                    target="_blank">
                                    Builder for Cloud Engineers
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.embedthis.com/builder/doc/start/for-purchasing/"
                                    target="_blank">
                                    Builder for Purchasing Managers
                                </a>
                            </li>
                        </ul>
                    </vu-card>
                </v-col>
                <v-col cols="6">
                    <vu-card title="The Most Advanced Device Agent" color="accent" class="welcome">
                        <h1>
                            EmbedThis Ioto
                            <span class="trade">&reg;</span>
                        </h1>
                        <p>EmbedThis Ioto is our newest and most advanced device agent.</p>
                        <p>
                            Ioto is a cloud-ready embedded device agent that is also perfect to use
                            as an advanced, stand-alone embedded web server for local device
                            management.
                        </p>

                        <p class="clear">
                            Ioto is fully configurable and includes a secure embedded web server,
                            embedded database, HTTP client, MQTT client and extensive cloud
                            integration.
                        </p>

                        <p>
                            Read more at:
                            <a href="https://www.embedthis.com/agent/doc/" target="_blank">
                                Ioto Agent Documentation.
                            </a>
                        </p>
                    </vu-card>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col cols="6">
                    <ListWidget header="EmbedThis News" :value="news" />
                </v-col>
                <v-col cols="6">
                    <ListWidget header="Learn about ..." :value="learn" />
                </v-col>
            </v-row>
            <v-row class="ml-0 mt-5">
                <v-btn color="accent" @click="showDashboard">Show Dashboard</v-btn>
            </v-row>
        </vu-sign>
    </div>
</template>

<style lang="scss">
.home-greeting {
    .vu-sign {
        margin: 32px 16px 16px 48px;
    }
    i.close {
        margin-top: 0px !important;
    }
    .welcome {
        padding-bottom: 32px;
        .v-card {
            padding-bottom: 12px;
        }
        .v-col {
            display: flex;
            flex-direction: column;
            .welcome {
                flex-grow: 1;
                min-height: 300px;
                h1 {
                    padding: 8px 0 12px 0;
                    font-size: 1.2rem;
                }
                .astro {
                    img {
                        margin: 0 24px 22px 0;
                        display: inline;
                        float: left;
                        width: 40%;
                        opacity: 0.85;
                    }
                }
                .trade {
                    font-size: 1rem;
                    vertical-align: top;
                }
                .clear {
                    clear: both;
                    margin-top: 10px;
                }
            }
        }
        ul {
            margin-left: 16px;
        }
        a {
            color: rgb(var(--v-theme-accent-darken-1));
        }
    }
    a {
        text-decoration: none;
        cursor: pointer;
    }
    .list-widget {
        margin-bottom: 0 !important;
    }
}
</style>
