const Downloads = {
    Appweb: {
        filter: ['Appweb'],
        title: 'Appweb Device Agent',
        description: `Appweb is an embedded web server suitable for hosting complex device applications.`,
        formats: [
            {
                name: 'Source',
                images: [
                    'appweb-9.0.4-src.tgz',
                    'appweb-9.0.3-src.tgz',
                    'appweb-9.0.2-src.tgz',
                    'appweb-9.0.1-src.tgz',
                    'appweb-9.0.0-src.tgz',
                    'appweb-8.3.2-src.tgz',
                    'appweb-8.3.1-src.tgz',
                    'appweb-8.3.0-src.tgz',
                    'appweb-8.2.3-src.tgz',
                    'appweb-8.2.2-src.tgz',
                    'appweb-8.2.0-src.tgz',
                    'appweb-8.1.4-src.tgz',
                    'appweb-8.1.3-src.tgz',
                    'appweb-8.1.2-src.tgz',
                    'appweb-8.1.1-src.tgz',
                    'appweb-8.1.0-src.tgz',
                    'appweb-8.0.2-src.tgz',
                    'appweb-7.2.3-src.tgz',
                    'appweb-7.2.2-src.tgz',
                    'appweb-7.2.1-src.tgz',
                    'appweb-7.2.0-src.tgz',
                    'appweb-7.1.1-src.tgz',
                    'appweb-7.1.0-src.tgz',
                    'appweb-7.0.3-src.tgz',
                    'appweb-7.0.2-src.tgz',
                    'appweb-7.0.1-src.tgz',
                    'appweb-7.0.0-src.tgz',
                    'appweb-6.2.3-src.tgz',
                    'appweb-6.2.2-src.tgz',
                    'appweb-6.2.1-src.tgz',
                    'appweb-6.2.0-src.tgz',
                    'appweb-6.1.1-src.tgz',
                    'appweb-6.1.0-src.tgz',
                    'appweb-6.0.3-src.tgz',
                    'appweb-6.0.2-src.tgz',
                    'appweb-6.0.1-src.tgz',
                    'appweb-6.0.0-src.tgz',
                    'appweb-5.6.2-src.tgz',
                    'appweb-5.6.1-src.tgz',
                    'appweb-5.6.0-src.tgz',
                    'appweb-5.5.1-src.tgz',
                    'appweb-5.5.0-src.tgz',
                    'appweb-5.4.7-src.tgz',
                    'appweb-5.4.6-src.tgz',
                    'appweb-5.4.5-src.tgz',
                    'appweb-5.4.4-src.tgz',
                    'appweb-5.4.2-src.tgz',
                    'appweb-5.4.1-src.tgz',
                    'appweb-5.4.0-src.tgz',
                    'appweb-5.3.0-src.tgz',
                    'appweb-5.2.0-src.tgz',
                    'appweb-5.1.0-src.tgz',
                    'appweb-5.0.0-src.tgz',
                    'appweb-4.7.3-src.tgz',
                    'appweb-4.7.2-src.tgz',
                    'appweb-4.7.1-src.tgz',
                    'appweb-4.7.0-src.tgz',
                    'appweb-4.6.7-src.tgz',
                    'appweb-4.6.6-src.tgz',
                    'appweb-4.6.5-src.tgz',
                    'appweb-4.6.3-src.tgz',
                    'appweb-4.6.2-src.tgz',
                    'appweb-4.6.1-src.tgz',
                    'appweb-4.6.0-src.tgz',
                    'appweb-4.5.6-src.tgz',
                    'appweb-4.5.5-src.tgz',
                    'appweb-4.5.4-src.tgz',
                    'appweb-4.5.3-src.tgz',
                    'appweb-4.5.2-src.tgz',
                    'appweb-4.5.1-src.tgz',
                    'appweb-4.5.0-src.tgz',
                    'appweb-4.4.4-0-src.tgz',
                    'appweb-4.4.3-0-src.tgz',
                    'appweb-4.4.2-0-src.tgz',
                    'appweb-4.4.1-0-src.tgz',
                    'appweb-4.4.0-0-src.tgz',
                    'appweb-4.3.6-0-src.tgz',
                    'appweb-4.3.5-0-src.tgz',
                    'appweb-4.3.4-0-src.tgz',
                    'appweb-4.3.3-0-src.tgz',
                    'appweb-4.3.2-1-src.tgz',
                    'appweb-4.3.1-0-src.tgz',
                    'appweb-4.3.0-1-src.tgz',
                    'appweb-4.2.0-0-src.tgz',
                    'appweb-4.1.1-0-src.tgz',
                    'appweb-4.1.0-0-src.tgz',
                    'appweb-4.0.0-0-src.tgz',
                    'appweb-3.4.2-0-src.tgz',
                    'appweb-3.4.1-0-src.tgz',
                    'appweb-3.4.0-0-src.tgz',
                    'appweb-3.3.4-0-src.tgz',
                    'appweb-3.3.3-0-src.tgz',
                    'appweb-3.3.2-0-src.tgz',
                    'appweb-3.3.1-0-src.tgz',
                    'appweb-3.3.0-2-src.tgz',
                    'appweb-3.2.3-3-src.tgz',
                    'appweb-3.2.2-1-src.tgz',
                    'appweb-3.2.1-1-src.tgz',
                    'appweb-3.2.0-7-src.tgz',
                    'appweb-3.1.2-1-src.tgz',
                    'appweb-3.1.1-1-src.tgz',
                    'appweb-3.1.0-a-src.tgz',
                    'appweb-3.0.1-2-src.tgz',
                    'appweb-3.0.0-5-src.tgz',
                    'appweb-2.4.4-0-src.tgz',
                    'appweb-2.4.3-0-src.tgz',
                    'appweb-2.4.2-2-src.tgz',
                    'appweb-2.4.1-0-src.tgz',
                    'appweb-2.4.0-0-src.tgz',
                    'appweb-2.3.4-2-src.tgz',
                    'appweb-2.3.3-3-src.tgz',
                    'appweb-2.3.2-1-src.tgz',
                    'appweb-2.3.1-9-src.tgz',
                    'appweb-2.2.2-5-src.tgz',
                    'appweb-2.2.1-1-src.tgz',
                    'appweb-2.2.0-3-src.tgz',
                ],
            },
            {
                name: 'Eval Source',
                images: ['appweb-eval-src.tgz'],
                eval: true,
            },
        ],
    },
    Builder: {
        filter: ['Builder'],
        title: 'Builder Device Studio',
        description: `The Builder is studio for creating embedded device products.`,
        formats: [
            {
                name: 'Source',
                images: [
                    'builder-1.6.0-src.tgz',
                    'builder-1.5.0-src.tgz',
                    'builder-1.4.0-src.tgz',
                    'builder-1.3.0-src.tgz',
                    'builder-1.2.1-src.tgz',
                    'builder-1.2.0-src.tgz',
                    'builder-1.1.0-src.tgz',
                    'builder-1.0.0-src.tgz',
                ],
            },
        ],
    },
    GoAhead: {
        filter: ['GoAhead'],
        title: 'GoAhead Device Agent',
        description: `GoAhead is a compact embedded web server suitable for hosting simpler device applications.`,
        formats: [
            {
                name: 'Source',
                images: [
                    'goahead-6.0.4-src.tgz',
                    'goahead-6.0.3-src.tgz',
                    'goahead-6.0.2-src.tgz',
                    'goahead-6.0.1-src.tgz',
                    'goahead-6.0.0-src.tgz',
                    'goahead-5.2.0-src.tgz',
                    'goahead-5.1.8-src.tgz',
                    'goahead-5.1.7-src.tgz',
                    'goahead-5.1.6-src.tgz',
                    'goahead-5.1.5-src.tgz',
                    'goahead-5.1.4-src.tgz',
                    'goahead-5.1.3-src.tgz',
                    'goahead-5.1.2-src.tgz',
                    'goahead-5.1.1-src.tgz',
                    'goahead-5.1.0-src.tgz',
                    'goahead-5.0.1-src.tgz',
                    'goahead-5.0.0-src.tgz',
                    'goahead-4.1.3-src.tgz',
                    'goahead-4.1.1-src.tgz',
                    'goahead-4.1.0-src.tgz',
                    'goahead-4.0.2-src.tgz',
                    'goahead-4.0.1-src.tgz',
                    'goahead-4.0.0-src.tgz',
                    'goahead-3.6.5-src.tgz',
                    'goahead-3.6.4-src.tgz',
                    'goahead-3.6.3-src.tgz',
                    'goahead-3.6.2-src.tgz',
                    'goahead-3.6.1-src.tgz',
                    'goahead-3.6.0-src.tgz',
                    'goahead-3.5.0-src.tgz',
                    'goahead-3.4.9-src.tgz',
                    'goahead-3.4.7-src.tgz',
                    'goahead-3.4.6-src.tgz',
                    'goahead-3.4.5-src.tgz',
                    'goahead-3.4.4-src.tgz',
                    'goahead-3.4.3-src.tgz',
                    'goahead-3.4.2-src.tgz',
                    'goahead-3.4.12-src.tgz',
                    'goahead-3.4.11-src.tgz',
                    'goahead-3.4.10-src.tgz',
                    'goahead-3.4.1-src.tgz',
                    'goahead-3.4.0-src.tgz',
                    'goahead-3.3.6-src.tgz',
                    'goahead-3.3.5-src.tgz',
                    'goahead-3.3.4-src.tgz',
                    'goahead-3.3.3-src.tgz',
                    'goahead-3.3.2-src.tgz',
                    'goahead-3.3.1-src.tgz',
                    'goahead-3.3.0-src.tgz',
                    'goahead-3.2.2-src.tgz',
                    'goahead-3.2.1-src.tgz',
                    'goahead-3.2.1-0-src.tgz',
                    'goahead-3.2.0-0-src.tgz',
                    'goahead-3.1.4-0-src.tgz',
                    'goahead-3.1.3-0-src.tgz',
                    'goahead-3.1.2-0-src.tgz',
                    'goahead-3.1.1-0-src.tgz',
                    'goahead-3.1.0-2-src.tgz',
                    'goahead-3.1.0-1-src.tgz',
                    'goahead-3.1.0-0-src.tgz',
                    'goahead-3.0.0-0-src.tgz',
                    'goahead-2.2.2-src.tgz',
                    'goahead-2.1.8-src.tgz',
                    'goahead-2.1.5-src.tgz',
                ],
            },
            {
                name: 'Eval Source',
                images: ['goahead-eval-src.tgz'],
                eval: true,
            },
        ],
    },
    Ioto: {
        filter: ['Ioto'],
        title: 'Ioto Device Agent',
        description: `The Ioto Agent is for local and cloud-based device management. Ideal as a local embedded web server or as a device agent for cloud-based management.`,
        formats: [
            {
                name: 'Source',
                images: [
                    'ioto-2.5.0-src.tgz',
                    'ioto-2.4.1-src.tgz',
                    'ioto-2.4.0-src.tgz',
                    'ioto-2.3.0-src.tgz',
                    'ioto-2.2.0-src.tgz',
                    'ioto-2.1.2-src.tgz',
                    'ioto-2.1.1-src.tgz',
                    'ioto-2.1.0-src.tgz',
                    'ioto-2.0.0-src.tgz',
                    'ioto-1.5.0-src.tgz',
                    'ioto-1.4.0-src.tgz',
                    'ioto-1.3.0-src.tgz',
                    'ioto-1.2.0-src.tgz',
                    'ioto-1.1.7-src.tgz',
                    'ioto-1.1.6-src.tgz',
                    'ioto-1.1.5-src.tgz',
                    'ioto-1.1.4-src.tgz',
                    'ioto-1.1.3-src.tgz',
                    'ioto-1.1.2-src.tgz',
                    'ioto-1.1.1-src.tgz',
                    'ioto-1.1.0-src.tgz',
                    'ioto-1.0.1-src.tgz',
                    'ioto-1.0.0-src.tgz',
                ],
            },
            {
                name: 'Management Apps',
                images: [
                    // 'ioto-apps-2.5.0-src.tgz',
                    'ioto-apps-2.4.0-src.tgz'
                ],
            },
            {
                name: 'Eval Source',
                images: ['ioto-eval-src.tgz'],
                eval: true,
            },
            {
                name: 'Eval Management Apps',
                images: [
                    'ioto-apps-eval-src.tgz'
                ],
                eval: true,
            },
        ],
    },
    Manager: {
        filter: ['Manager'],
        title: 'Device Manager',
        description: `The device manager is a customizable device manager for Ioto-based products`,
        formats: [
            {
                name: 'Source',
                images: [
                    'manager-1.5.0-src.tgz',
                    'manager-1.3.0-src.tgz',
                    'manager-1.2.2-src.tgz',
                    'manager-1.2.1-src.tgz',
                    'manager-1.2.0-src.tgz',
                    'manager-1.1.0-src.tgz',
                    'manager-1.0.0-src.tgz',
                ],
            },
        ],
    },
}

export default Downloads
